import React, { useContext, useState } from 'react';
import upload_icon from "../../../../assets/images/upload_icon.png";
import { Link, Outlet, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { SavedFileContext } from '../../../../context/SaveedFileContext';


const MultipleFileUpload = ({ uploadTxt }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [checkFileName, setCheckFileName] = useState(false);
    const [selectedOption, setSelectedOption] = useState('6');
    const [uploadBtn, setUploadBtn] = useState(false);
    const [csvData, setCsvData] = useState("");
    const [files, setFiles] = useState("");
    const [loading, setLoading] = useState(false);
    const { currentMapping, setCurrentMapping } = useContext(SavedFileContext)

    const navigate = useNavigate()


    const handleFileUpload = async (event) => {
        const fileInput = event.target;
        const allowedExtensions = /(\.csv)$/i;
        const files = fileInput.files; // Use files property to get the selected files


        if (files.length === 0) {
            return; // No file selected
        }
        setFiles(fileInput.files)

        // Validate each file
        // for (let i = 0; i < files.length; i++) {
        //     const fileName = files[i].name;

        //     if (!allowedExtensions.exec(fileName)) {
        //         setErrorMessage('Please upload only CSV files.');
        //         fileInput.value = '';
        //         // Clear the error message after 3 seconds
        //         setTimeout(() => {
        //             setErrorMessage('');
        //         }, 3000);
        //         return;
        //     }
        //     else {


        //     }
        // }
        setUploadBtn(true)
        // setCheckFileName(true);
    };

    const handleOptionSelect = (event) => {
        const selectedOption = event.target.value;
        setSelectedOption(selectedOption);
    };
    const handleUploadClick = async () => {
        if (selectedOption && currentMapping != "") {
            try {
                setLoading(true)
                // const j = new FormData;
                // let s = ["2", "d", "a"]
                // j.append("dd", s);
                // console.log("ds", typeof (j.get("dd")));

                // const blob = new Blob([csvData], { type: 'text/csv' });
                let allFiles = [...files]
                let teseFiles = [...files]
                console.log("bro", allFiles);

                const formData = new FormData();
                allFiles.map((file) => {
                    formData.append("files", file);

                })
                formData.append("direction", selectedOption);
                console.log("fd", formData);
                console.log("let's see", (formData.get("files")));
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                };
                const response = await axios.post("https://test.techrecipes.com/upload-csv", formData, config)
                console.log("So the response is", response.data);
                // Papa.parse(response.data, {
                //     header: true,
                //     dynamicTyping: true,
                //     complete: (result) => {
                //         console.log(result.data);
                //         setCsvData(result.data)
                //     },
                // });
                setCsvData(response.data)
                // setCheckFileName(true)
                handleStructure(response.data);


            }
            catch (e) {
                console.log("Error Occured", e);
                setLoading(false)

            }
        }
        else {
            console.log("hehehe");
            toast.error("Please select direction and mapping")
        }

    }
    const handleStructure = async (csvFile) => {
        try {
            const blob = new Blob([csvFile], { type: 'text/csv' });
            // const formData = new FormData();
            // formData.append("selected_column", "Ad name");
            // formData.append("file", blob);
            // const config = {
            //     headers: {
            //         'Content-Type': 'multipart/form-data'
            //     },
            // };
            // const response = await axios.post("https://api1.techrecipes.com/process-csv", formData, config)

            let data = new FormData();
            const expectedHeaders = [
                "Inventory Type",
                "Data Targeting",
                "Size/Length",
                "Platform Impressions",
                "Impressions",
                "Impression",
                "Ad name",
                "Ad Name",
                "Net Spend",
                "Week",
                // "Brand",
                "Campaign Objective",

                "Platform",
                "Measurable",
                "Clicks",
            ]

            data.append('selected_column', expectedHeaders.find((data) => (csvFile.includes(data))));

            data.append('file', blob);
            // data.append('selected_column', "Ad Name");

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://api1.techrecipes.com/process-csv',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: data
            };
            axios.request(config)
                .then((response) => {
                    console.log("Our data", JSON.stringify(response.data));
                    console.log("Saad bhai");
                    // var csvData = new Blob([response.data.csv_content], { type: 'text/csv;charset=utf-8;' });
                    // var csvURL = null;
                    // if (navigator.msSaveBlob) {
                    //     csvURL = navigator.msSaveBlob(csvData, 'download.csv');
                    // }
                    // else {
                    //     csvURL = window.URL.createObjectURL(csvData);
                    // }

                    // var tempLink = document.createElement('a');
                    // tempLink.href = csvURL;
                    // tempLink.setAttribute('download', "Aggregiate Sheet" + '.csv');
                    // tempLink.click();
                    setLoading(false)

                    navigate('/dashboard/uploadFile/extramultiplefile', { state: { csvData: response.data.csv_content } });

                })
                .catch((error) => {
                    setLoading(false)

                    console.log(error);
                });

            // console.log("So the response is ai", response.data);
            // Papa.parse(response.data, {
            //     header: true,
            //     dynamicTyping: true,
            //     complete: (result) => {
            //         console.log(result.data);
            //         setCsvData(result.data)
            //     },
            // });

        }
        catch (e) {
            setLoading(false)

            console.log("Error Occured", e);
        }
    }
    return (
        <div>
            <div className='innerTabs-box'>
                <Link to='/dashboard/uploadFile/singlefileupload'>
                    <button>Single File Upload</button>
                </Link>
                <Link to='/dashboard/uploadFile/multiplefileupload'>
                    <button>Multiple File Upload</button>
                </Link>
                <Link to='/dashboard/uploadFile/zipfileupload'>
                    <button>Zip File</button>
                </Link>
            </div>
            {!checkFileName ? (
                <div className='subtab1-box'>
                    <div className="w-full md:w-1/2 left flex flex-col gap-6 items-center">
                        <label
                            htmlFor="ch"
                            className="Campton-light cursor-pointer  px-6 md:px-8 py-1 md:py-2 rounded-lg text-white"
                        >
                            <div
                                className="uplaod cursor-pointer w-11/12 py-6 md:py-12 md:py-24 text-center flex justify-center flex-col gap-2 items-center border"
                            >
                                <img src={upload_icon} alt="" />
                                <p className="Campton text-xl md:text-2xl text-black ">
                                    {uploadTxt}
                                </p>
                            </div>
                            {/* Use type="file" and multiple attribute */}
                            <input type="file" accept=".csv, .xlsx" id="ch" onChange={handleFileUpload} className='text-black' multiple />
                            {errorMessage && <div className="toast pb-2 text-black">{errorMessage}</div>}
                        </label>
                    </div>
                    <div className="inline-block relative w-full mt-3 ">
                        <select
                            className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                            onChange={handleOptionSelect}
                        >
                            <option>Select direction</option>
                            {/* <option value="1">Direction 1</option>
                            <option value="2">Direction 2</option>
                            <option value="3">Direction 3</option>
                            <option value="4">Direction 4</option> */}
                            <option value="5">top to bottom join</option>
                            <option value="6" selected>left to right join</option>
                            <option value="7">Right to left join</option>
                            <option value="8">bottom to top join</option>
                        </select>
                    </div>
                    {selectedOption && (
                        <p className="Campton text-lg text-black font-semibold mt-2">
                            Selected Option: Files will be joined {selectedOption == "5" ? "top to bottom"
                            :

                            selectedOption == "6" ? "left to right"
                            :

                            selectedOption == "7" ? "Right to left"
                            :

                            selectedOption == "8" ? "bottom to top"
                            :
                            "No option Selected"
                            }
                        </p>
                    )}
                    <select style={{ width: "100%", padding: "5px 10px", borderRadius: "4px" }}
                        onChange={(e) => setCurrentMapping(e.target.value)} value={currentMapping}
                    >
                        <option value={""}>Select Mapping</option>
                        <option value={"meta"}>Meta</option>
                        <option value={"tiktok"}>Tiktok</option>
                        <option value={"ttd"}>TTD</option>
                        <option value={"fixed"}>Structured Data</option>
                    </select>
                    {loading? 
                <p>Uploading and Processing Files...</p>
                    
            :
            ""
            }
                    
                    {uploadBtn && (
                        // <Link to="/dashboard/uploadFile/aipoweredinput">
                        <button className='save mt-5 text-white py-[5px] px-5 rounded text-lg' disabled={loading} onClick={handleUploadClick}>Upload</button>
                        // </Link>
                    )}
                </div>
            ) : (
                <div className='checkstructured-btn-container gap-2 w-full'>
                    <Link to="/dashboard/uploadFile/structure" state={{ csvData: csvData }}>
                        <button className="save mt-5 text-white py-[5px] px-[35px] rounded text-lg">
                            Structured
                        </button>
                    </Link>
                    <Link to="/dashboard/uploadFile/nonsturctured" state={{ csvData: csvData }}>
                        <button className="save mt-5 text-white py-[5px] px-[35px] rounded text-lg" >
                            UnStructured
                        </button>
                    </Link>
                </div>
            )}
            <Outlet />
        </div>
    );
};

export default MultipleFileUpload;

