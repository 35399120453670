import { Icon } from '@iconify/react';
import React, { useContext, useEffect, useState } from 'react'
// import logo from "../../../assets/images/root_logo.svg";
import logo from "../../../assets/images/The Root.webp";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Modal } from "flowbite-react";
import { signOut } from "firebase/auth";
import { auth } from '../../../config';
import { SavedFileContext } from '../../../context/SaveedFileContext';



const SideBar = () => {
    const [activeTab, setActiveTab] = useState("home");
    const [show, setshow] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [currentSelectedTab, setCurrentSelectedTab] = useState("")
    const {gptHeaders, setGptHeaders} = useContext(SavedFileContext)
    const navigate = useNavigate();
    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };
    const location = useLocation();
    useEffect(() => {
        console.log("dsd", location.pathname);
    }, [])
    return (
        <div
            className={`sidebar absolute md:static  -translate-x-[700px] md:translate-x-0 transition-transform duration-500 z-10 bg-white  left-0 top-0  w-full  md:w-[13%] pt-9 border-r border-black-300 h-screen  flex flex-col justify-between pb-3 ${show ? "sidebarshow" : ""
                }`}
        >
            <div className="sidebar_bottom flex flex-col justify-between">
                <div className="text-5xl  flex justify-between md:justify-center items-center px-5 pb-10 Rubik font-semibold text-center">
                    <div className="logo w-[182px] h-[28px]  ">
                        <img src={logo} alt="" className="h-full w-full" style={{objectFit: "contain"}} />
                    </div>
                    <Icon
                        icon="bitcoin-icons:cross-filled"
                        className=" cursor-pointer md:hidden"
                        color="#143A62"
                        width="35"
                        height="35"
                        onClick={() => {
                            setshow(false);
                        }}
                    />
                </div>
                <ul className="flex flex-col gap-4 pe-2 ">

                    {/* <Link to='/dashboard/home'> */}
                    {/* <div
                        className={`flex items-center tab primary  gap-2 ps-9 py-4 b rounded-e-xl cursor-pointer duration-500 hover:text-white ${activeTab == "home" ? "activetab" : ""
                            }`}
                        onClick={() => {
                            if (location.pathname.includes("uploadFile") || location.pathname.includes("analysis") || location.pathname.includes("datavisualization")) {
                                setCurrentSelectedTab({ url: "/dashboard/home", name: "home" })

                                setOpenModal(true)

                            }
                            else {
                                handleTabChange("home");
                                setCurrentSelectedTab({ url: "/dashboard/home", name: "home" })
                                navigate("/dashboard/home")
                                setshow(false);

                            }



                        }}
                    >
                        <Icon icon="mdi:home" width="24" height="25" />
                        <li className=" text-xl  Campton  ">Home</li>
                    </div> */}
                    {/* </Link> */}

                    {/* <Link to='/dashboard/data'> */}
                    <div
                        className={`flex items-center tab gap-2 ps-9 py-4 b rounded-e-xl cursor-pointer duration-500 hover:text-white ${activeTab == "datatables" ? "activetab" : ""
                            }`}
                        onClick={() => {
                            if (location.pathname.includes("uploadFile") || location.pathname.includes("analysis")) {
                                setCurrentSelectedTab(prev => ({ url: "/dashboard/data", name: "datatables" }))

                                setOpenModal(true)

                            }
                            else {
                                handleTabChange("datatables");
                                setCurrentSelectedTab({ url: "/dashboard/data", name: "datatables" })
                                navigate("/dashboard/data")

                                setshow(false);
                            }
                        }}
                    >
                        <Icon
                            icon="lets-icons:setting-fill"
                            opacity="0.4"
                            width="24"
                            height="24"
                        />
                        <li className=" opacity-[0.4]  text-xl Campton ">Repository</li>
                    </div>
                    {/* </Link> */}

                    {/* <Link to='/dashboard/uploadFile/singlefileupload'> */}
                    <div
                        className={`flex items-center tab gap-2 ps-9 py-4 b rounded-e-xl cursor-pointer duration-500 hover:text-white ${activeTab == "fileupload" ? "activetab" : ""
                            }`}
                        onClick={() => {
                            if (location.pathname.includes("uploadFile") || location.pathname.includes("analysis")) {
                                setCurrentSelectedTab({ url: "/dashboard/uploadFile/singlefileupload", name: "fileupload" })

                                setOpenModal(true)

                            }
                            else {
                                handleTabChange("fileupload");
                                setCurrentSelectedTab({ url: "/dashboard/uploadFile/singlefileupload", name: "fileupload" })
                                navigate("/dashboard/uploadFile/singlefileupload")

                                setshow(false);
                            }
                        }}
                    >
                        {/* <Icon
                            icon="lets-icons:setting-fill"
                        /> */}
                        <Icon
                            opacity="0.4"
                            width="24"
                            height="24"
                            icon="solar:document-linear" />
                        <li className=" opacity-[0.4]  text-xl Campton ">File Upload</li>
                    </div>
                    {/* </Link> */}

                    {/* <div
                        className={`flex items-center tab gap-2 ps-9 py-4 b rounded-e-xl cursor-pointer duration-500 hover:text-white ${activeTab == "setting" ? "activetab" : ""
                            }`}
                        onClick={() => {
                            handleTabChange("setting");
                            setshow(false);
                        }}
                    >
                        <Icon
                            icon="lets-icons:setting-fill"
                            opacity="0.4"
                            width="24"
                            height="24"
                        />
                        <li className=" opacity-[0.4]  text-xl Campton ">Settings</li>
                    </div> */}
                </ul>
            </div>
            <div className="w-full px-3  ">
                <button className="logout btn_hover w-full font-semibold  py-4 text-center bg-custom_primary text-white rounded-xl"
                onClick={async()=>{await signOut(auth); setGptHeaders(false)}}>
                    Logout
                </button>
            </div>
            <Modal
                show={openModal}
                onClose={() => setOpenModal(false)}
                className="dashboard-modal bg-opacity-60"
                size="2xl"

            >

                <Modal.Body className="large ssss  ">
                    <div className="ddddd">
                        <div className=" px-5 md:px-0 flex justify-center items-center md:items-stretch lg:items-center  flex-col md:flex-row 2xl:py-[20px] " style={{ flexDirection: "column", gap: "20px" }}>
                            <h1>This will reset all the steps. Do you want to proceed?</h1>
                            <style>
                                {`
                    
                    .modalBtns{
                        color: white;
                         padding: 3px 20px;
                        border-radius: 5px;
                    }
                    .modalBtns:hover{
                        opacity: 0.8;
                    }
                    `}


                            </style>
                            <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                                <button className='modalBtns' style={{ backgroundColor: "#00a170" }} onClick={() => {
                                    console.log("dd", currentSelectedTab);
                                    handleTabChange(currentSelectedTab.name);

                                    navigate(currentSelectedTab.url)
                                    setshow(false);
                                    setOpenModal(false);
                                    setGptHeaders(false)
                                }}>Yes</button>
                                <button className='modalBtns' style={{ backgroundColor: "crimson" }} onClick={() => {
                                    setOpenModal(false);

                                }}>No</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default SideBar