import React, { useEffect, useState } from 'react';
import upload_icon from "../../../../../assets/images/extrafiles.png";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const ExtraMultipleFile = ({ uploadTxt }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [checkFileName, setCheckFileName] = useState(false);
    const [uploadBtn, setUploadBtn] = useState(false);
    const [selectedFileNames, setSelectedFileNames] = useState([]);
    const [selectedOption, setSelectedOption] = useState('6');
    const [csvData, setCsvData] = useState("")
    const [files, setFiles] = useState("")
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const location = useLocation()

    useEffect(() => {
        console.log("Extra Multiple File", location);
        if (location?.state?.csvData) {

            console.log("location", location.state);
            setCsvData(location.state.csvData);
        }
    }, [])

    const handleFileUpload = async (event) => {
        console.log("dd", event.target.files);

        const fileInput = event.target;
        const allowedExtensions = /(\.csv)$/i;
        const files = fileInput.files;

        if (files.length === 0) {
            return;
        }
        setFiles(fileInput.files)
        const newFileNames = [];

        for (let i = 0; i < files.length; i++) {
            const fileName = files[i].name;

            if (!allowedExtensions.exec(fileName)) {
                setErrorMessage('Please upload only CSV files.');
                fileInput.value = '';
                setTimeout(() => {
                    setErrorMessage('');
                }, 3000);
                return;
            }

            newFileNames.push(fileName);
        }
        console.log("dd", event.target.files);


        if (files) {
            setUploadBtn(true);
        }

        setCheckFileName(true);
        setSelectedFileNames(newFileNames);
    };

    const handleOptionSelect = (event) => {
        const selectedOption = event.target.value;
        setSelectedOption(selectedOption);
    };

    const handleUploadClick = async () => {
        if (selectedOption) {
            try {
                setLoading(true);
                // const j = new FormData;
                // let s = ["2", "d", "a"]
                // j.append("dd", s);
                // console.log("ds", typeof (j.get("dd")));

                const blob = new Blob([csvData], { type: 'text/csv' });
                let allFiles = [...files, blob]
                let teseFiles = [...files]
                console.log("bro", allFiles);

                const formData = new FormData();
                allFiles.map((file) => {
                    formData.append("files", file);

                })
                formData.append("direction", selectedOption);
                console.log("fd", formData);
                console.log("let's see", typeof (formData.get("files")));
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                };
                const response = await axios.post("https://test.techrecipes.com/upload-csv", formData, config)
                console.log("So the response is", response.data);
                // Papa.parse(response.data, {
                //     header: true,
                //     dynamicTyping: true,
                //     complete: (result) => {
                //         console.log(result.data);
                //         setCsvData(result.data)
                //     },
                // });
                setCsvData(response.data)
                handleStructure(response.data)
                // navigate("/dashboard/uploadFile/aipoweredinput", { state: { csvData: response.data } })

            }
            catch (e) {
                console.log("Error Occured", e);
                setLoading(false);

            }
        }
        else {
            console.log("hehehe");
            alert("Please select direction")
        }

    }

    const handleStructure = (csvFile) => {

        // Navigate only if there is text in the field
        console.log("Csv File", csvFile);
        const blob = new Blob([csvFile], { type: 'text/csv' });
        // const formData = new FormData();
        // formData.append("selected_column", "Ad name");
        // formData.append("file", blob);
        // const config = {
        //     headers: {
        //         'Content-Type': 'multipart/form-data'
        //     },
        // };
        // const response = await axios.post("https://api1.techrecipes.com/process-csv", formData, config)

        let data = new FormData();
        data.append('file', blob);
        // const expectedHeaders = [
        //     "Campaign Objective",
        //     "Inventory Type",
        //     "Data Targeting",
        //     "Size/Length",
        //     "Platform Impressions",
        //     "Ad name",
        //     "Net Spend",
        //     "Week",
        //     "Clicks",
        //     "Brand",
        //     "Platform",
        //     "Measurable",
        // ]

        const expectedHeaders = [
            "Inventory Type",
            "Data Targeting",
            "Size/Length",
            "Platform Impressions",
            "Impressions",
            "Impression",
            "Ad name",
            "Ad Name",
            "Net Spend",
            "Week",
            // "Brand",
            "Campaign Objective",

            "Platform",
            "Measurable",
            "Clicks",
        ]
       
        // data.append('selected_column', location.state.csvData.split(",")[0]);

        data.append('selected_column', expectedHeaders.find((data) => (csvFile.includes(data))));
        // data.append('selected_column', "Ad Name");

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://api1.techrecipes.com/process-csv',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                console.log("Our data Ai", JSON.stringify(response.data));
                setLoading(false);
                
                navigate('/dashboard/uploadFile/uploadtable', { state: { csvData: response.data.csv_content } });
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);

            });
    }

    return (
        <div className='subtab1-box'>
            <div className="w-full md:w-1/2 left flex flex-col gap-6 items-center">
                <label
                    htmlFor="ch"
                    className="Campton-light cursor-pointer  px-6 md:px-8 py-1 md:py-2 rounded-lg text-white"
                >
                    <div
                        className="uplaod cursor-pointer w-11/12 py-6 md:py-12 md:py-24 text-center flex justify-center flex-col gap-2 items-center border"
                    >
                        <img src={upload_icon} alt="" style={{width: "100px"}} />

                        <p className="Campton text-xl md:text-2xl text-black ">
                            {uploadTxt}
                        </p>
                    </div>
                    <input type="file" accept=".csv, .xlsx" id="ch" onChange={handleFileUpload} className='text-black' multiple />
                    {errorMessage && <div className="toast pb-2 text-black">{errorMessage}</div>}
                </label>
                <div className='sss'>
                    {checkFileName && (
                        <div className="selected-files">
                            <p className="Campton text-lg text-black font-semibold">Selected Files:</p>
                            <ul>
                                {selectedFileNames.map((fileName, index) => (
                                    <li key={index}>{fileName}</li>
                                ))}
                            </ul>
                        </div>
                    )}

                    <div className="inline-block relative w-full mt-3 ">
                        <select
                            className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                            onChange={handleOptionSelect}
                        >
                            <option>Select direction</option>
                            {/* <option value="Directi0n 1: Stacks DataFrames vertically and resets the indexes">Direction 1</option> */}
                            {/* <option value="2">Direction 2</option>
                            <option value="3">Direction 3</option>
                            <option value="4">Direction 4</option> */}
                            <option value="5">top to bottom join</option>
                            <option value="6" selected>left to right join</option>
                            <option value="7">Right to left join</option>
                            <option value="8">bottom to top join</option>
                        </select>
                    </div>
                    {selectedOption && (
                        <p className="Campton text-lg text-black font-semibold mt-2">
                            Selected Option: Extra Files will join as {selectedOption == "5" ? "top to bottom"
                            :

                            selectedOption == "6" ? "left to right"
                            :

                            selectedOption == "7" ? "Right to left"
                            :

                            selectedOption == "8" ? "bottom to top"
                            :
                            "No option Selected"
                            }
                        </p>
                    )}
                    {uploadBtn && (
                        // <Link to="/dashboard/uploadFile/aipoweredinput" state={{ csvData: csvData }} >
                        <button className='save mt-5 text-white py-[5px] px-5 rounded text-lg' onClick={handleUploadClick} disabled={loading}>{loading ? "Uploading..." : "Upload"}</button>
                        // </Link>
                    )}

                </div >
            </div>
            <Link className='skipUpload' to="/dashboard/uploadFile/uploadtable" state={{ csvData: csvData }}>
                Skip Process
            </Link>
        </div>
    );
};

export default ExtraMultipleFile;
