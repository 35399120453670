import React, { useEffect, useState } from 'react'
import './NonSturctured.scss'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Papa from "papaparse"
const NonSturctured = () => {

    const location = useLocation()
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)

    const handleStructure = async () => {
        try {
            setLoading(true);
            console.log("well", location.state.csvData.split(",")[0]);

            const blob = new Blob([location.state.csvData], { type: 'text/csv' });
            // const formData = new FormData();
            // formData.append("selected_column", "Ad name");
            // formData.append("file", blob);
            // const config = {
            //     headers: {
            //         'Content-Type': 'multipart/form-data'
            //     },
            // };
            // const response = await axios.post("https://api1.techrecipes.com/process-csv", formData, config)

            let data = new FormData();
            data.append('file', blob);
            const expectedHeaders = [
                "Inventory Type",
                "Data Targeting",
                "Size/Length",
                "Platform Impressions",
                "Impressions",
                "Impression",
                "Ad name",
                "Ad Name",
                "Net Spend",
                "Week",
                // "Brand",
                "Campaign Objective",

                "Platform",
                "Measurable",
                "Clicks",
            ]
            // data.append('selected_column', location.state.csvData.split(",")[0]);

            data.append('selected_column', expectedHeaders.find((data) => (location.state.csvData.includes(data))));

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://api1.techrecipes.com/process-csv',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: data
            };
            axios.request(config)
                .then((response) => {
                    console.log("Our data", JSON.stringify(response.data));
                    console.log("Saad bhai");
                    // var csvData = new Blob([response.data.csv_content], { type: 'text/csv;charset=utf-8;' });
                    // var csvURL = null;
                    // if (navigator.msSaveBlob) {
                    //     csvURL = navigator.msSaveBlob(csvData, 'download.csv');
                    // }
                    // else {
                    //     csvURL = window.URL.createObjectURL(csvData);
                    // }

                    // var tempLink = document.createElement('a');
                    // tempLink.href = csvURL;
                    // tempLink.setAttribute('download', "Aggregiate Sheet" + '.csv');
                    // tempLink.click();
                    setLoading(false);
                    navigate('/dashboard/uploadFile/extramultiplefile', { state: { csvData: response.data.csv_content } });

                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                });

            // console.log("So the response is ai", response.data);
            // Papa.parse(response.data, {
            //     header: true,
            //     dynamicTyping: true,
            //     complete: (result) => {
            //         console.log(result.data);
            //         setCsvData(result.data)
            //     },
            // });


        }
        catch (e) {
            console.log("Error Occured", e);
            setLoading(false);
        }
    }
    // useEffect(() => {
    //     Papa.parse(location.state.csvData, {
    //         header: true,
    //         dynamicTyping: true,
    //         complete: (result) => {
    //             console.log("new data", result.data);
    //             // setCsvData(result.data)
    //             // setTableColumn(Object.keys(result.data[0]).map((csvColumnName) => (
    //             //     { title: csvColumnName, dataIndex: csvColumnName }
    //             // )))
    //         },
    //     });
    // }, [])
    return (
        <div className='checkstructured-btn-container gap-2 w-full'>
            {/* <Link to="/dashboard/uploadFile/unstructuredaipoweredinput" state={{ csvData: location.state.csvData }}>
                <button className="save mt-5 text-white py-[5px] px-[35px] rounded text-lg" >
                    Click here to Structure the File
                </button>
            </Link> */}
            {loading ?
                <button className="save mt-5 text-white py-[5px] px-[35px] rounded text-lg" >
                    Loading...
                </button>
                :
                <button className="save mt-5 text-white py-[5px] px-[35px] rounded text-lg" onClick={handleStructure} >
                    Click here to Structure the File
                </button>}
        </div>
    )
}

export default NonSturctured