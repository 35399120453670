/* eslint-disable no-unused-vars */
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import { getAuth } from "firebase/auth";
import {getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDLf-vbTiuqY4pSbtGe8oB1Xi2Y27BacqU",
    authDomain: "theroot-5eb7b.firebaseapp.com",
    projectId: "theroot-5eb7b",
    storageBucket: "theroot-5eb7b.appspot.com",
    messagingSenderId: "773149226817",
    appId: "1:773149226817:web:2028a8f2b7556ee5fcd3c0"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestoredb = getFirestore(app)
const auth = getAuth(app);
// const storage = getStorage(app);





export { firestoredb, auth }