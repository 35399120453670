import React, { useContext, useEffect, useState } from 'react'
import upload_icon from "../../../.././assets/images/upload_icon.png";
import { Link, Outlet, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Papa from 'papaparse';
import { SavedFileContext } from '../../../../context/SaveedFileContext';
import { toast } from 'react-toastify';

const SingleFileUpload = ({ uploadTxt }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [uploadBtn, setuploadBtn] = useState(false);
    const [checkFileName, setcheckFileName] = useState(false);
    const [csvData, setCsvData] = useState([]);
    const [uploadedFile, setUploadedFile] = useState("");
    const [loading, setLoading] = useState(false)
    const [uploadLoading, setUploadLoading] = useState(false)
    const [enableMappingDropDown, setEnableMappingDropDown] = useState(false)
    const navigate = useNavigate();
    const { currentMapping, setCurrentMapping, gptHeaders, setGptHeaders, gptHeadersOld, setGptHeadersOld } = useContext(SavedFileContext)
    const handleFileUpload = async (event) => {
        console.log("okay so", event.target.files[0]);
        const fileInput = event.target;
        const allowedExtensions = /(\.csv)$/i;
        const fileName = fileInput.value;
        setUploadedFile(event.target.files[0]);
        // if (!allowedExtensions.exec(fileName)) {
        if (false) {
            setErrorMessage('Please upload a CSV file.');
            fileInput.value = '';
            // Clear the error message after 3 seconds
            setTimeout(() => {
                setErrorMessage('');
            }, 3000);
        }
        else {
            try {
                setUploadLoading(true)
                setEnableMappingDropDown(false);
                setuploadBtn(false)
                const formData = new FormData();
                formData.append("files", event.target.files[0]);
                formData.append("direction", "5");
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                };
                const response = await axios.post("https://test.techrecipes.com/upload-csv", formData, config)
                // console.log("So the response is", response.data);
                // Papa.parse(response.data, {
                //     header: true,
                //     dynamicTyping: true,
                //     complete: (result) => {
                //         console.log(result.data);
                //         setCsvData(result.data)
                //     },
                // });
                setCsvData(response.data)
                setEnableMappingDropDown(true)
                console.log("Helpppppppp", response.data);
                setUploadLoading(false);
                if (currentMapping) {

                    setuploadBtn(true);
                }


            }

            catch (e) {
                console.log("Error Occured", e);
                toast.error("Unable to upload this file. Please Select Another!")
                setUploadLoading(false);

            }
        }
        if (!fileName) {
            setEnableMappingDropDown(false);
            setuploadBtn(false);
            setUploadLoading(false);

        }
    };

    const handleStructure = async (csvResponse) => {
        try {

            console.log("well", csvResponse.split(",")[0]);

            const blob = new Blob([csvResponse], { type: 'text/csv' });
            // const formData = new FormData();
            // formData.append("selected_column", "Ad name");
            // formData.append("file", blob);
            // const config = {
            //     headers: {
            //         'Content-Type': 'multipart/form-data'
            //     },
            // };
            // const response = await axios.post("https://api1.techrecipes.com/process-csv", formData, config)

            let data = new FormData();
            data.append('file', blob);
            // const expectedHeaders = [
            //     "Campaign Objective",
            //     "Brand",
            //     "Inventory Type",
            //     "Data Targeting",
            //     "Size/Length",
            //     "Platform Impressions",
            //     "Net Spend",
            //     "Week",
            //     "Platform",
            //     "Clicks",
            //     "Measurable",
            // ]

            const expectedHeaders = [
                "Inventory Type",
                "Data Targeting",
                "Size/Length",
                "Platform Impressions",
                "Impressions",
                "Impression",
                "Ad name",
                "Ad Name",
                "Net Spend",
                "Week",
                // "Brand",
                "Campaign Objective",

                "Platform",
                "Measurable",
                "Clicks",
            ]

            // data.append('selected_column', location.state.csvData.split(",")[0]);

            data.append('selected_column', expectedHeaders.find((data) => (csvResponse.includes(data))));

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://api1.techrecipes.com/process-csv',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: data
            };
            axios.request(config)
                .then((response) => {
                    // console.log("Our data", JSON.stringify(response.data));
                    console.log("Saad bhai");
                    // var csvData = new Blob([response.data.csv_content], { type: 'text/csv;charset=utf-8;' });
                    // var csvURL = null;
                    // if (navigator.msSaveBlob) {
                    //     csvURL = navigator.msSaveBlob(csvData, 'download.csv');
                    // }
                    // else {
                    //     csvURL = window.URL.createObjectURL(csvData);
                    // }

                    // var tempLink = document.createElement('a');
                    // tempLink.href = csvURL;
                    // tempLink.setAttribute('download', "Aggregiate Sheet" + '.csv');
                    // tempLink.click();
                    setLoading(false);

                    navigate('/dashboard/uploadFile/uploadtable', { state: { csvData: response.data.csv_content } });
                    // navigate('/dashboard/analysis/aggregate', { state: { csvData: response.data.csv_content } });

                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);

                });

            // console.log("So the response is ai", response.data);
            // Papa.parse(response.data, {
            //     header: true,
            //     dynamicTyping: true,
            //     complete: (result) => {
            //         console.log(result.data);
            //         setCsvData(result.data)
            //     },
            // });

        }
        catch (e) {
            console.log("Error Occured", e);
            toast.error("File couldn't be structured!")

            setLoading(false)
        }
    }

    const handleGptApi = async () => {

        if (uploadedFile && currentMapping == "other") {
            try {
                setLoading(true);
                const formData = new FormData();
                formData.append("file", uploadedFile);
                // formData.append("direction", "5");
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                };
                console.log("hammad");
                const response = await axios.post("https://test3.techrecipes.com/upload-GPTprocess-csv", formData, config)
                // console.log("So the response is", response.data);
                // Papa.parse(response.data, {
                //     header: true,
                //     dynamicTyping: true,
                //     complete: (result) => {
                //         console.log(result.data);
                //         setCsvData(result.data)
                //     },
                // });
                // setCsvData(response.data)
                console.log("Response from GPT Api", response.data);
                let gptData = response.data;
                if (gptData.file_id) {
                    try {
                        const headers = await axios.get(`https://test3.techrecipes.com/get-csv-headers/${gptData.file_id}`)
                        setGptHeaders(headers.data.headers);
                        const headersOld = await axios.post(`https://test3.techrecipes.com/upload-GPTprocess_headers-csv/`, formData, config)
                        setGptHeadersOld(headersOld.data.headers);
                        const response = await axios.get(`https://test3.techrecipes.com/GPTdownload-csv/${gptData.file_id}`)
                        handleStructure(response.data)

                    }
                    catch (e) {
                        console.log("Error getting GPT File", e);
                        toast.error("Error getting GPT File");
                        setLoading(false);

                    }
                }
                else {
                    alert("Unable to get GPT Response")

                    setLoading(false);

                }
            }
            catch (e) {
                console.log("Error", e);
                setLoading(false)
            }
        }
        else {
            alert("Please upload file first")
        }
    }

    useEffect(() => {
        const blob = new Blob([csvData], { type: 'text/csv' });
        console.log("hehe", blob);
    }, [csvData])

    const handleStructureNonGpt = async () => {
        try {
            setLoading(true);
            setUploadLoading(true);
            console.log("well", csvData.split(",")[0]);

            const blob = new Blob([csvData], { type: 'text/csv' });
            // const formData = new FormData();
            // formData.append("selected_column", "Ad name");
            // formData.append("file", blob);
            // const config = {
            //     headers: {
            //         'Content-Type': 'multipart/form-data'
            //     },
            // };
            // const response = await axios.post("https://api1.techrecipes.com/process-csv", formData, config)

            let data = new FormData();
            data.append('file', blob);
            const expectedHeaders = [
                "Inventory Type",
                "Data Targeting",
                "Size/Length",
                "Platform Impressions",
                "Impressions",
                "Impression",
                "Ad name",
                "Ad Name",
                "Net Spend",
                "Week",
                // "Brand",
                "Campaign Objective",

                "Platform",
                "Measurable",
                "Clicks",
            ]
            // data.append('selected_column', location.state.csvData.split(",")[0]);

            data.append('selected_column', expectedHeaders.find((data) => (csvData.includes(data))));

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://api1.techrecipes.com/process-csv',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: data
            };
            axios.request(config)
                .then((response) => {
                    console.log("Our data", JSON.stringify(response.data));
                    console.log("Saad bhai");
                    // var csvData = new Blob([response.data.csv_content], { type: 'text/csv;charset=utf-8;' });
                    // var csvURL = null;
                    // if (navigator.msSaveBlob) {
                    //     csvURL = navigator.msSaveBlob(csvData, 'download.csv');
                    // }
                    // else {
                    //     csvURL = window.URL.createObjectURL(csvData);
                    // }

                    // var tempLink = document.createElement('a');
                    // tempLink.href = csvURL;
                    // tempLink.setAttribute('download', "Aggregiate Sheet" + '.csv');
                    // tempLink.click();
                    setLoading(false);
                    setUploadLoading(false);

                    navigate('/dashboard/uploadFile/extramultiplefile', { state: { csvData: response.data.csv_content } });

                })
                .catch((error) => {
                    setLoading(false);

                    setUploadLoading(false);
                    console.log(error);
                });

            // console.log("So the response is ai", response.data);
            // Papa.parse(response.data, {
            //     header: true,
            //     dynamicTyping: true,
            //     complete: (result) => {
            //         console.log(result.data);
            //         setCsvData(result.data)
            //     },
            // });


        }
        catch (e) {
            setLoading(false);
            setUploadLoading(false);

            console.log("Error Occured", e);
        }
    }

    return (
        <div>
            <div className='innerTabs-box'>
                <Link to='/dashboard/uploadFile/singlefileupload'>
                    <button>Single File Upload</button>
                </Link>
                <Link to='/dashboard/uploadFile/multiplefileupload'>
                    <button>Multiple File Upload</button>
                </Link>
                <Link to='/dashboard/uploadFile/zipfileupload'>
                    <button>Zip File</button>
                </Link>
            </div>
            {!checkFileName ? (
                <div className='subtab1-box'>
                    <div className="w-full md:w-1/2 left flex flex-col gap-6 items-center">
                        <label
                            htmlFor="ch"
                            className="Campton-light cursor-pointer  px-6 md:px-8 py-1 md:py-2 rounded-lg text-white"
                        >
                            <div
                                className="uplaod cursor-pointer w-11/12 py-6 md:py-12 md:py-24 text-center flex justify-center flex-col gap-2 items-center border"
                            >
                                <img src={upload_icon} alt="" />

                                <p className="Campton text-xl md:text-2xl text-black ">
                                    {uploadTxt}
                                </p>
                            </div>
                            <input type="file" accept=".csv, .xlsx" id="ch" onChange={handleFileUpload} className='text-black' />
                            {errorMessage && <div className="toast pb-2 text-black">{errorMessage}</div>}
                            {/* {uploadBtn && <button className='save mt-5 text-white py-[5px] px-5 rounded text-lg' onClick={() => setcheckFileName(true)}>Upload</button>} */}
                        </label>
                        {uploadLoading ? <p>Uploading and Processing File...</p> : ""}

                        {enableMappingDropDown ?
                            <select style={{ width: "100%", padding: "5px 10px", borderRadius: "4px" }}
                                onChange={(e) => {
                                    setCurrentMapping(() => (e.target.value))
                                    if (csvData.length > 0 && e.target.value) {
                                        setuploadBtn(true);
                                    }
                                }} value={currentMapping}
                            >
                                <option value={""}>Select Mapping</option>
                                <option value={"meta"}>Meta</option>
                                <option value={"tiktok"}>Tiktok</option>
                                <option value={"ttd"}>TTD</option>
                                <option value={"other"}>Other</option>
                                <option value={"fixed"}>Structured Data</option>
                            </select>
                            :
                            ""
                        }
                        {currentMapping == "other" ?
                            uploadBtn && !loading ?
                                <button className="save mt-5 text-white py-[5px] px-[35px] rounded text-lg" onClick={handleGptApi} >
                                    Upload Other File
                                </button>
                                :
                                <button className="save mt-5 text-white py-[5px] px-[35px] rounded text-lg">
                                    Uploading...
                                </button>

                            :
                            uploadBtn &&
                            // <Link to="/dashboard/uploadFile/nonsturctured" state={{ csvData: csvData }}>
                            <button className="save mt-5 text-white py-[5px] px-[35px] rounded text-lg" disabled={loading} onClick={handleStructureNonGpt} >
                                Upload
                            </button>
                            // </Link>

                        }
                    </div>
                </div>
            ) : (
                <div className='checkstructured-btn-container gap-2'>
                    <Link to="/dashboard/uploadFile/structure" state={{ csvData: csvData }}>
                        <button className="save mt-5 text-white py-[5px] px-[35px] rounded text-lg">
                            Structured
                        </button>
                    </Link>
                    <Link to="/dashboard/uploadFile/nonsturctured" state={{ csvData: csvData }}>
                        <button className="save mt-5 text-white py-[5px] px-[35px] rounded text-lg" >
                            UnStructured
                        </button>
                    </Link>
                </div>
            )
            }
            <Outlet />

        </div>
    )
}

export default SingleFileUpload