import { useEffect, useState } from "react";

import { onAuthStateChanged } from "firebase/auth";

import { useNavigate } from "react-router-dom";
import { auth } from "../../config";

const Protected = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setLoading(false);
      if (!user) {
        navigate("/login");
      }
    });

    return () => unsubscribe();
  }, []);
  if (loading) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Loading...
      </div>
    );
  }
  return auth.currentUser ? <>{children}</> : null;
};

export default Protected;
