import React from 'react'
import { Table } from 'antd';
import '../../../../DataTables/DataTables.css'
import { Link } from 'react-router-dom';
// import 'antd/dist/antd.css';


const Fixed = () => {
    const dataSource = [
        {
            key: '1',
            Measurable: 'Mike',
            SUM_of_Opportunity_Index: 32,
            Net_Spend: '10 Downing Street',

            Impressions: 'Mike',
            Clicks: 32,
            SUM_of_CTR: '10 Downing Street',

            SUM_of_eCPM: 'Mike',
            SUM_of_CPC: 32,
            SUM_of_OTR: '10 Downing Street',

            SUM_of_Viewability: 'Mike',
            SUM_of_Fraud: 32,
            SUM_of_Frequency: '10 Downing Street',

            SUM_of_Avg_OT_CTR: 'Mike',
            SUM_of_OTF: 32,
            SUM_of_NF_vCPM: '10 Downing Street',

            SUM_of_eCPMH: 'Mike',
            SUM_of_OTR_vCPM: 32,
            SUM_of_qCPM: '10 Downing Street',

            SUM_of_VCR: 'Mike',
            SUM_of_vCPCV: 32,
            SUM_of_OT_vCPCV: '10 Downing Street',

            SUM_of_hCPCV: 'Mike',
            SUM_of_qCPH: 32,
            SUM_of_ROAS: '10 Downing Street',

            SUM_of_CPL: 'Mike',
            SUM_of_qCPL: 32,
            SUM_of_CPS: 32,

            SUM_of_qCPS: '10 Downing Street',

        },
        {
            key: '2',
            Measurable: 'Mike',
            SUM_of_Opportunity_Index: 32,
            Net_Spend: '10 Downing Street',

            Impressions: 'Mike',
            Clicks: 32,
            SUM_of_CTR: '10 Downing Street',

            SUM_of_eCPM: 'Mike',
            SUM_of_CPC: 32,
            SUM_of_OTR: '10 Downing Street',

            SUM_of_Viewability: 'Mike',
            SUM_of_Fraud: 32,
            SUM_of_Frequency: '10 Downing Street',

            SUM_of_Avg_OT_CTR: 'Mike',
            SUM_of_OTF: 32,
            SUM_of_NF_vCPM: '10 Downing Street',

            SUM_of_eCPMH: 'Mike',
            SUM_of_OTR_vCPM: 32,
            SUM_of_qCPM: '10 Downing Street',

            SUM_of_VCR: 'Mike',
            SUM_of_vCPCV: 32,
            SUM_of_OT_vCPCV: '10 Downing Street',

            SUM_of_hCPCV: 'Mike',
            SUM_of_qCPH: 32,
            SUM_of_ROAS: '10 Downing Street',

            SUM_of_CPL: 'Mike',
            SUM_of_qCPL: 32,
            SUM_of_CPS: 32,

            SUM_of_qCPS: '10 Downing Street',

        },
        {
            key: '1',
            Measurable: 'Mike',
            SUM_of_Opportunity_Index: 32,
            Net_Spend: '10 Downing Street',

            Impressions: 'Mike',
            Clicks: 32,
            SUM_of_CTR: '10 Downing Street',

            SUM_of_eCPM: 'Mike',
            SUM_of_CPC: 32,
            SUM_of_OTR: '10 Downing Street',

            SUM_of_Viewability: 'Mike',
            SUM_of_Fraud: 32,
            SUM_of_Frequency: '10 Downing Street',

            SUM_of_Avg_OT_CTR: 'Mike',
            SUM_of_OTF: 32,
            SUM_of_NF_vCPM: '10 Downing Street',

            SUM_of_eCPMH: 'Mike',
            SUM_of_OTR_vCPM: 32,
            SUM_of_qCPM: '10 Downing Street',

            SUM_of_VCR: 'Mike',
            SUM_of_vCPCV: 32,
            SUM_of_OT_vCPCV: '10 Downing Street',

            SUM_of_hCPCV: 'Mike',
            SUM_of_qCPH: 32,
            SUM_of_ROAS: '10 Downing Street',

            SUM_of_CPL: 'Mike',
            SUM_of_qCPL: 32,
            SUM_of_CPS: 32,

            SUM_of_qCPS: '10 Downing Street',

        },
        {
            key: '2',
            Measurable: 'Mike',
            SUM_of_Opportunity_Index: 32,
            Net_Spend: '10 Downing Street',

            Impressions: 'Mike',
            Clicks: 32,
            SUM_of_CTR: '10 Downing Street',

            SUM_of_eCPM: 'Mike',
            SUM_of_CPC: 32,
            SUM_of_OTR: '10 Downing Street',

            SUM_of_Viewability: 'Mike',
            SUM_of_Fraud: 32,
            SUM_of_Frequency: '10 Downing Street',

            SUM_of_Avg_OT_CTR: 'Mike',
            SUM_of_OTF: 32,
            SUM_of_NF_vCPM: '10 Downing Street',

            SUM_of_eCPMH: 'Mike',
            SUM_of_OTR_vCPM: 32,
            SUM_of_qCPM: '10 Downing Street',

            SUM_of_VCR: 'Mike',
            SUM_of_vCPCV: 32,
            SUM_of_OT_vCPCV: '10 Downing Street',

            SUM_of_hCPCV: 'Mike',
            SUM_of_qCPH: 32,
            SUM_of_ROAS: '10 Downing Street',

            SUM_of_CPL: 'Mike',
            SUM_of_qCPL: 32,
            SUM_of_CPS: 32,

            SUM_of_qCPS: '10 Downing Street',

        }
    ];

    const columns = [
        {
            title: 'Measurable',
            dataIndex: 'Measurable',
            key: 'Measurable',
        },
        {
            title: 'SUM of Opportunity Index',
            dataIndex: 'SUM_of_Opportunity_Index',
            key: 'SUM of Opportunity Index',
        },
        {
            title: 'Net Spend',
            dataIndex: 'Net_Spend',
            key: 'Net Spend',
        },
        {
            title: 'Impressions',
            dataIndex: 'Impressions',
            key: 'Impressions',
        },
        {
            title: 'Clicks',
            dataIndex: 'Clicks',
            key: 'Clicks',
        },
        {
            title: 'SUM of CTR',
            dataIndex: 'SUM_of_CTR',
            key: 'SUM of CTR',
        },
        {
            title: 'SUM of eCPM',
            dataIndex: 'SUM_of_eCPM',
            key: 'SUM of eCPM',
        },
        {
            title: 'SUM of CPC',
            dataIndex: 'SUM_of_CPC',
            key: 'SUM of CPC',
        },
        {
            title: 'SUM of OTR',
            dataIndex: 'SUM_of_OTR',
            key: 'SUM of OTR',
        },
        {
            title: 'SUM of Viewability',
            dataIndex: 'SUM_of_Viewability',
            key: 'SUM of Viewability',
        },
        {
            title: 'SUM of Fraud',
            dataIndex: 'SUM_of_Fraud',
            key: 'SUM of Fraud',
        },
        {
            title: 'SUM of Frequency',
            dataIndex: 'SUM_of_Frequency',
            key: 'SUM of Frequency',
        },
        {
            title: 'SUM of Avg. OT CTR',
            dataIndex: 'SUM_of_Avg_OT_CTR',
            key: 'SUM of Avg. OT CTR',
        },
        {
            title: 'SUM of OTF',
            dataIndex: 'SUM_of_OTF',
            key: 'SUM of OTF',
        },
        {
            title: 'SUM of NF vCPM',
            dataIndex: 'SUM_of_NF_vCPM',
            key: 'SUM of NF vCPM',
        },
        {
            title: 'SUM of eCPMH',
            dataIndex: 'SUM_of_eCPMH',
            key: 'SUM of eCPMH',
        },
        {
            title: 'SUM of OTR vCPM',
            dataIndex: 'SUM_of_OTR_vCPM',
            key: 'SUM of OTR vCPM',
        },
        {
            title: 'SUM of qCPM',
            dataIndex: 'SUM_of_qCPM',
            key: 'SUM of qCPM',
        },
        {
            title: 'SUM of VCR',
            dataIndex: 'SUM_of_VCR',
            key: 'SUM of VCR',
        },
        {
            title: 'SUM of vCPCV',
            dataIndex: 'SUM_of_vCPCV',
            key: 'SUM of vCPCV',
        },
        {
            title: 'SUM of OT vCPCV',
            dataIndex: 'SUM_of_OT_vCPCV',
            key: 'SSUM of OT vCPCV',
        },
        {
            title: 'SUM of hCPCV',
            dataIndex: 'SUM_of_hCPCV',
            key: 'SUM of hCPCV',
        },
        {
            title: 'SUM of qCPH',
            dataIndex: 'SUM_of_qCPH',
            key: 'SUM of qCPH',
        },
        {
            title: 'SUM of ROAS',
            dataIndex: 'SUM_of_ROAS',
            key: 'SUM of ROAS',
        },
        {
            title: 'SUM of CPL',
            dataIndex: 'SUM_of_CPL',
            key: 'SUM of CPL',
        },
        {
            title: 'SUM of qCPL',
            dataIndex: 'SUM_of_qCPL',
            key: 'SUM of qCPL',
        },
        {
            title: 'SUM of CPS',
            dataIndex: 'SUM_of_CPS',
            key: 'SUM of CPS',
        },
        {
            title: 'SUM of qCPS',
            dataIndex: 'SUM_of_qCPS',
            key: 'SUM of qCPS',
        },
    ];

    return (
        <div className='dataResults-container'>
            <div className='dataResults-align'>
                <h1><span>Fixed:</span> Table Content</h1>
                <Link to="/dashboard/analysis/auditsheet">
                    <button className='btn_hover font-semibold py-2 text-center bg-custom_primary text-white rounded-xl'>Audit Sheet</button>
                </Link>
            </div>
            <Table dataSource={dataSource} columns={columns} />
        </div>
    )
}

export default Fixed;