import React from 'react'
import { Table } from 'antd';
import './DataTables.css'
// import 'antd/dist/antd.css';
// const linksData = [{name: "Step 01 Checklist (INTERNAL)", link: "https://thetechrecipescom-my.sharepoint.com/:x:/r/personal/bchap_techrecipes_com1/Documents/01%20-Tech%20Recipes/2024/01_Internal/01_Fundamentals/01_Process/17_Products/01_The%20Root/STEP_01_Checklist_(INTERNAL).xlsx?d=w9c33b59f1d004cdeb1dd333ee2ff79c7&csf=1&web=1&e=eO2foF"}, {name: "Glossary", link: ""}, {name: "Calculations", link: ""}, {name: "Sample Files", link: ""}]
const linksData = [
    {name: "Step 01 Checklist (INTERNAL)", link: "https://thetechrecipescom-my.sharepoint.com/:x:/r/personal/bchap_techrecipes_com1/Documents/01%20-Tech%20Recipes/2024/01_Internal/01_Fundamentals/01_Process/17_Products/01_The%20Root/STEP_01_Checklist_(INTERNAL).xlsx?d=w9c33b59f1d004cdeb1dd333ee2ff79c7&csf=1&web=1&e=eO2foF"},
    {name: "Step 01A Platform User Guide (INTERNAL)", link: "https://thetechrecipescom-my.sharepoint.com/:w:/r/personal/bchap_techrecipes_com1/Documents/01%20-Tech%20Recipes/2024/01_Internal/01_Fundamentals/01_Process/17_Products/01_The%20Root/STEP_01A_Platform_User_Guide%20(INTERNAL).docx?d=w719c050a2d0e48419539c0391325c074&csf=1&web=1&e=I7mTjH"},
    {name: "Step 01B Technical Documentation (INTERNAL)", link: "https://thetechrecipescom-my.sharepoint.com/:w:/r/personal/bchap_techrecipes_com1/Documents/01%20-Tech%20Recipes/2024/01_Internal/01_Fundamentals/01_Process/17_Products/01_The%20Root/STEP_01B_Technical_Documentation%20(INTERNAL).docx?d=w561317bdb8ee40f69cb2effceb2d6a84&csf=1&web=1&e=KL3aBu"},
    {name: "Step 02 Data Request Email Template (EXTERNAL)", link: "https://thetechrecipescom-my.sharepoint.com/:w:/r/personal/bchap_techrecipes_com1/Documents/01%20-Tech%20Recipes/2024/01_Internal/01_Fundamentals/01_Process/17_Products/01_The%20Root/STEP_02_Data_Request_Email_Template%20(EXTERNAL).docx?d=we912cb8a81aa4715843a4ae8c155494e&csf=1&web=1&e=kWEw6y"},
    {name: "Step 03 Data Requests Template (EXTERNAL)", link: "https://thetechrecipescom-my.sharepoint.com/:x:/r/personal/bchap_techrecipes_com1/Documents/01%20-Tech%20Recipes/2024/01_Internal/01_Fundamentals/01_Process/17_Products/01_The%20Root/STEP_03_Data_Requests_Template%20(EXTERNAL).xlsx?d=wa9f58aa2878145d3b17fcd954a0d798b&csf=1&web=1&e=hcSPDF"},
    {name: "Step 04 Agency Data Request Email Template (EXTERNAL)", link: "https://thetechrecipescom-my.sharepoint.com/:w:/r/personal/bchap_techrecipes_com1/Documents/01%20-Tech%20Recipes/2024/01_Internal/01_Fundamentals/01_Process/17_Products/01_The%20Root/STEP_04_Agency_Data_Request_Email_Template%20(EXTERNAL).docx?d=w0f3afbd5355846a0abc4a5800f68d4f2&csf=1&web=1&e=2z16ao"},
    {name: "Step 05 Business Outcome Macro (INTERNAL)", link: "https://thetechrecipescom-my.sharepoint.com/:x:/r/personal/bchap_techrecipes_com1/Documents/01%20-Tech%20Recipes/2024/01_Internal/01_Fundamentals/01_Process/17_Products/01_The%20Root/Step_05_Business%20Outcome_Macro.xlsm?d=w72e0f13fb4c94ccbbf6a2b66e2efee36&csf=1&web=1&e=ksk0D"},
    {name: "Step 06 Analysis Template Consolidated (EXTERNAL)", link: "https://thetechrecipescom-my.sharepoint.com/:p:/r/personal/bchap_techrecipes_com1/Documents/01%20-Tech%20Recipes/2024/01_Internal/01_Fundamentals/01_Process/17_Products/01_The%20Root/STEP_05_Analysis_Template_Consolidated%20(EXTERNAL).pptx?d=w321172791b534919a93c4dd260dd16f9&csf=1&web=1&e=64xTQx"},
    {name: "STEP 06A Analysis Template Comprehensive (EXTERNAL)", link: "https://thetechrecipescom-my.sharepoint.com/:p:/r/personal/bchap_techrecipes_com1/Documents/01%20-Tech%20Recipes/2024/01_Internal/01_Fundamentals/01_Process/17_Products/01_The%20Root/STEP_06_Analysis_Template%20Comprehensive%20(EXTERNAL).pptx?d=w5cd255863bb848f6bfcb329efd29df51&csf=1&web=1&e=V4WVxM"}
]


const DataTables = () => {
    
    return (
        <div className='repoContainer'>

            <div className="guideContainer">
<h1>Introduction</h1>
<p>Welcome to this comprehensive guide for using The Root. Please reference the sections below to find detailed instructions and helpful tips for navigating and utilizing all the features of the platform.</p>

<div className='links'>
    <ul>
        {linksData.map((data)=>(<li><a href={data.link} target='_blank'>{data.name}</a></li>))}
    </ul>
</div>
            </div>

            
            
        </div>
    )
}

export default DataTables;