import React, { useState, useEffect } from "react";
import "./navbar.scss";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import Rootlogo from "../../assets/images/root-logo.svg";
const Heronavbar = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const [showMoreOptions, setShowMoreOptions] = useState(false);

  const toggleNavbar = () => {
    setShowNavbar(!showNavbar);
  };
  const handleMoreOptionsClick = () => {
    setShowMoreOptions(!showMoreOptions);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 600) {
        setShowNavbar(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleIconClick = () => {
    if (window.innerWidth < 600) {
      toggleNavbar();
    }
  };
  return (
    <>
      <div className="navbar-section">
        <div className="navbar-main">
          <div className="navbar-left">
            <img src={Rootlogo} alt="...." />
            <div className="navbar-right-toggle">
              <button onClick={toggleNavbar}>
                {/* <Icon
                  icon="game-icons:hamburger-menu"
                  width={30}
                  onClick={handleIconClick}
                /> */}
                {showNavbar ? (
                  <Icon
                    icon="entypo:cross"
                    width="30"
                    height="30"
                    style={{ color: "black" }}
                    onClick={handleIconClick}
                  />
                ) : (
                  <Icon
                    icon="game-icons:hamburger-menu"
                    width={30}
                    onClick={handleIconClick}
                  />
                )}
              </button>
            </div>
          </div>

          <div className={`navbar-middle ${showNavbar ? "show" : "not"}`}>
            <ul>
              <li>Data Analysis</li>
              <li>Campaign Insights</li>
              <li>Marketing Solutions</li>
              <li onClick={handleMoreOptionsClick}>
                <div className="options">
                  More Options
                  {showMoreOptions ? (
                    <Icon
                      icon="iconamoon:arrow-down-2-thin"
                      width="38"
                      height="38"
                      style={{
                        transform: "rotate(180deg)",
                        strokeWidth: "2px",
                        stroke: "#5B5B5B",
                      }}
                      className="dropdown-icon"
                    />
                  ) : (
                    <Icon
                      icon="iconamoon:arrow-down-2-thin"
                      width="38"
                      height="38"
                      style={{
                        strokeWidth: "2px",
                        stroke: "#5B5B5B",
                      }}
                      className="dropdown-icon"
                    />
                  )}
                </div>

                {/* {showMoreOptions && (
                  <div className="div">
                    <span>tes1</span>
                    <span>tes2</span>
                    <span>tes3</span>
                  </div>
                )} */}
              </li>
            </ul>
          </div>
          <div className={`navbar-right ${showNavbar ? "show" : "not"}`}>
            <button>Sign up</button>
          </div>
        </div>
      </div>

      {/* <div className="navbar-section">
        <nav className="navbar navbar-expand-lg navbar-main">
          <div className="container-fluid navbar-primary">
            <a className="navbar-brand" href="#">
              {" "}
              <img src={Rootlogo} alt="..." />{" "}
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarScroll"
              aria-controls="navbarScroll"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <Icon
                icon="mdi:hamburger-menu"
                color="black"
                width="25"
                height="25"
              />
         
            </button>
            <div className=" navbar-collapse" id="navbarScroll">
              <ul className="navbar-nav mx-auto  navbar-nav-scroll nav-ul">
                <li className="nav-item">
                  <a className="nav-link active" aria-current="page" href="#">
                    Data Analysis
                  </a>
                </li>

                <li className="nav-item">
                  <a className="nav-link" href="#">
                    Campaign Insights
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    Marketing Solutions
                  </a>
                </li>
                <li className="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    More Options
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <a class="dropdown-item" href="#">
                        Action
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Another action
                      </a>
                    </li>

                    <li>
                      <a class="dropdown-item" href="#">
                        Something else here
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
              <form className="d-flex form-search" role="search">
                <Link to={"/signup"}>
                  {" "}
                  <button className="sign-up" type="submit">
                    sign up
                  </button>
                </Link>
              </form>
            </div>
          </div>
        </nav>
      </div> */}
    </>
  );
};

export default Heronavbar;
