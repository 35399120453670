import React from "react";
import "./hero.scss";
import Navbar from "../../dashboard/components/navbar/Navbar";
import Heronavbar from "../navbar/heronavbar";
import Lefthero from "../../assets/images/left hero section.png";
import Righthero from "../../assets/images/Rignt hero section.png";
import Lefta from "../../assets/images/keyboard.webp";
import Leftb from "../../assets/images/laptop.webp";
import Leftc from "../../assets/images/firstlast.webp";
import Righta from "../../assets/images/firstlast.webp";
import Rightb from "../../assets/images/rightmiddle.webp";
import Rightc from "../../assets/images/rightlast.webp";
import Blur from "../../assets/images/blurtop.png";
import Blur2 from "../../assets/images/blurbottom.png";
const Hero = () => {
  return (
    <>
      <div className="heroSection-main">
        {/* <Navbar /> */}
        <Heronavbar />
        <div className="herosec-primary">
          <div className="herosection-content">
            <div className="leftside">
              <div className="leftContent">
                <h5>
                  Unleash the Power of <span>Data Analysis</span>
                </h5>
                <p>
                  Welcome the The Root, your go-to solution for in-depth
                  marketing campaign data analysis and visualization. Unearth
                  insights and cultivate success with our adaptable pipelines,
                  intelligent learning, and intelligent visualizaion.
                </p>
                <div className="button-parent">
                  {" "}
                  <button className="learn-more">Learn More</button>
                  <button className="signup">sign up</button>
                </div>
              </div>
            </div>
            <div className="rightside">
              {/* <img src={Blur} alt=".." className="blur" /> */}
              <div className="rightcontent">
                <div className="blur-section">
                  {/* <img src={Blur} alt=".." className="blur" />{" "} */}
                </div>
                <div className="blur-section2">
                  {/* <img src={Blur2} alt=".." className="blur" />{" "} */}
                </div>
                <div className="righleft-content">
                  <img src={Lefta} alt="" />

                  <img src={Leftb} alt="" />
                  <img src={Leftc} alt="" />
                  <img src={Lefta} alt="" />
                </div>
                <div className="rightright-content">
                  <img src={Righta} alt="" />
                  <img src={Rightb} alt="" />
                  <img src={Rightc} alt="" />
                  <img src={Rightb} alt="" />
                </div>
                {/* <img src={Herosection2img} alt="img" /> */}
              </div>
            </div>
          </div>
        </div>

        <img
          //   src="/src/assets/images/left hero section.png"
          src={Lefthero}
          alt="herobtmimg"
          className="herotopimg"
        />
        <img
          src={Righthero}
          //   src="/src/assets/images/Rignt hero section.png"
          alt="herobtmimg"
          className="herobottomimg"
        />
      </div>
    </>
  );
};

export default Hero;
