import React, { useState } from "react";

import Navbar from "./components/navbar/Navbar";
import SideBar from "./components/SideBar/SideBar";
import { Outlet } from "react-router-dom";

import { Icon } from "@iconify/react";
import DashboardMain from "./components/dashbaord_main/DashboardMain";
import Setting from "./components/setting/Setting";
import Result from "./components/result/Result";
import logo from "../assets/images/root_logo.svg";
import DataTables from './components/DataTables/DataTables'
import UploadFile from "./components/UploadFile/UploadFile";

function Dashboard() {
  const [activeTab, setActiveTab] = useState("home");
  const [show, setshow] = useState(false);
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="flex h-screen ">
      <SideBar />
      <div className="right_content  md:p-0 w-full md:w-[87%] ">
        <Navbar show={show} setshow={setshow} />
        <Outlet />
      </div>
      
    </div>
  );
  
}

export default Dashboard;