import React, { useState } from "react";
import profile from "../../../assets/images/No_slogan_2.png";
import { Icon } from "@iconify/react";
import { useLocation, useNavigate } from "react-router-dom";
function Navbar({ show, setshow }) {
  const [showdropdown, setshowdropdown] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div>

      <div className="navbar flex justify-between items-center px-5 md:px-10 py-7 border-b">
        {
          location.pathname != "/dashboard/uploadFile/singlefileupload" && location.pathname != "/dashboard/uploadFile/multiplefileupload" && location.pathname != "/dashboard/uploadFile/zipfileupload" &&
          <button onClick={() => {
            if (location.pathname == "/dashboard/uploadFile/singlefileupload" || location.pathname == "/dashboard/uploadFile/multiplefileupload" || location.pathname == "/dashboard/uploadFile/zipfileupload") {

            }
            else {
              navigate(-1)
            }
          }
          }>{"<-- Back"}</button>
        }


        <div className=" heading flex items-center gap-3 primary text-2xl md:text-[28px] font-semibold Campton ">
          <Icon
            icon="charm:menu-hamburger"
            width="40"
            height="40"
            color="#143A62"
            className="md:hidden block"
            onClick={() => {
              setshow(!show);
            }}
          />
          Dashboard
        </div>

        <div className="search_bar bg-searchbar w-2/6 hidden md:flex items-center px-5 !py-4 !gap-3 !rounded-full">
          <Icon
            icon="ri:search-line"
            className=" cursor-pointer"
            color="#ccdbeb"
            width="20"
            height="20"
          />
          <input
            type="text"
            className=" bg-transparent !focus:outline-none border-none w-full  placeholder-placeholderColor p-0 focus:ring-0"
            placeholder="Search for something"
          />
        </div>

        <div
          className="profile  cursor-pointer flex justify-center items-center"
          onClick={() => {
            setshowdropdown(!showdropdown);
          }}
        >
          <div className="img w-14 h-14  rounded-[50%]   overflow-hidden ">
            {" "}
            <img src={profile} alt="" className="h-full w-full" />
          </div>
          <Icon
            icon="gridicons:dropdown"
            width="40"
            height="40"
            color="#143A62"
          />

          {/* <img src={profile} alt="" /> */}
        </div>
        <div
          className={`dropdown1  absolute top-[100px] right-11   w-[176px] bg-[]  flex-col gap-1 justify-center rounded-[9px] items-center ${showdropdown ? "flex" : "hidden"
            }`}
        >
          <p className=" w-full py-1 text-center name text-[#6B2B4B] text-base cursor-pointer">
            Andrew
          </p>
          <div className="edit flex gap-3 justify-start items-center pb-1 ps-2 w-full ">
            <Icon
              icon="iconamoon:profile-fill"
              width="16"
              height="16"
              color="#6B2B4B"
              className=" cursor-pointer "
            />
            <p className=" text-[#6B2B4B] text-base cursor-pointer  ">
              Edit Profile
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
