

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ExtraInput = () => {
    const navigate = useNavigate();
    const [fieldName, setFieldName] = useState('');

    const handleButtonClick = () => {
        if (fieldName.trim() === '') {
            toast.error('Field cannot be empty!');
        } else {
            navigate('/dashboard/uploadFile/uploadtable');
        }
    };

    return (
        <div>
            <ToastContainer />
            <br />
            <label className='text-bold mt-3'>Add Field Name</label>
            <br />
            <input
                type='text'
                className='mt-2 rounded'
                value={fieldName}
                onChange={(e) => setFieldName(e.target.value)}
            />
            <br />
            <div className='d-flex'>
                <button
                    className='save mt-5 text-white py-[5px] px-[35px] rounded text-lg'
                    onClick={handleButtonClick}
                >
                    Add
                </button>
                <Link className='skipUpload ml-5' to="/dashboard/uploadFile/">
                    Skip Process
                </Link>
            </div>

        </div>
    );
};

export default ExtraInput;
