import { React, useState } from "react";
import Email_Icon from "../../assets/images/Email_Icon.png";
import Password_Icon from "../../assets/images/Password_Icon.png";
import { Icon } from "@iconify/react";
import logo from "../../assets/images/root_logo.svg";
import google from "../../assets/images/google.png";
import facebook from "../../assets/images/facebook.png";
import twitter from "../../assets/images/twitter.png";
import { type } from "@testing-library/user-event/dist/type";
import check_ from "../../assets/images/check.png";
import uncheck from "../../assets/images/uncheck.png";
function SignUp() {
  const [showpassword, setshowpassword] = useState(false);
  const [showconfirmpassword, setshowconfirmpassword] = useState(false);
  const [check, setcheck] = useState(false);
  return (
    <div>
      <div className="sign_up   px-5 md:px-0 flex justify-center items-center md:items-stretch lg:items-center  flex-col md:flex-row h-screen overflow-scroll ">
        <div className="sign_up_right rounded-3xl w-full md:max-w-[650px] 2xl:max-w-[782px]      bg-white py-4 lg:py-5 2xl:py-7 px-5 md:px-28 2xl:px-32  ">
          <div className=" w-[300px] h-[40px] lg:w-[360px] 2xl:w-auto text-center  lg:h-[45px] 2xl:h-auto mb-5 lg:mb-6  2xl:mb-14">
            <img src={logo} alt="" className="h-full w-full" />
          </div>

          <form>
            <div className="form-container flex flex-col gap-3 md:gap-4 2xl:gap-8">
              <h2 className=" text-2xl text-[#040404] lg:text-4xl 2xl:text-[50px]  font-semibold Campton">
                Sign up
              </h2>
              <div className="input items-center flex gap-3 gradient-border rounded-xl p-2 md:p-3  2xl:p-[22px] ">
                {" "}
                <img
                  src={Email_Icon}
                  className="w-4 h-4 md:h-auto md:w-auto"
                  alt=""
                />
                <input
                  className="border-none text-xl md:text-2xl focus:outline-none p-0 border focus:ring-0 w-full placeholder:text-placeholderColor placeholder:font-bold Campton  md:placeholder:text-2xl "
                  type="text"
                  placeholder="Email"
                />
              </div>

              <div className="input items-center  flex gap-3 gradient-border  rounded-xl p-2 md:p-3 2xl:p-[22px] ">
                {" "}
                <img
                  src={Password_Icon}
                  className="w-4 h-4 md:h-auto md:w-auto"
                  alt=""
                />
                <input
                  className="border-none text-xl md:text-2xl focus:outline-none p-0 border focus:ring-0 w-full placeholder:text-placeholderColor placeholder:font-bold Campton  md:placeholder:text-2xl "
                  type={showpassword ? "text" : "password"}
                  placeholder="Password"
                />
                {showpassword ? (
                  <>
                    <Icon
                      icon="clarity:eye-show-line"
                      color="rgba(4, 4, 4, 0.50)"
                      width="32"
                      height="25"
                      className=" cursor-pointer"
                      onClick={() => {
                        setshowpassword(false);
                      }}
                    />
                  </>
                ) : (
                  <>
                    <Icon
                      icon="clarity:eye-hide-line"
                      color="rgba(4, 4, 4, 0.50)"
                      width="32"
                      height="25"
                      className=" cursor-pointer"
                      onClick={() => {
                        setshowpassword(true);
                      }}
                    />
                  </>
                )}
              </div>

              <div className="">
                <div className="input items-center  flex gap-3 gradient-border rounded-xl p-2 md:p-3 2xl:p-[22px] ">
                  {" "}
                  <img
                    src={Password_Icon}
                    className="w-4 h-4 md:h-auto md:w-auto"
                    alt=""
                  />
                  <input
                    className="border-none text-xl md:text-2xl focus:outline-none p-0 border focus:ring-0 w-full placeholder:text-placeholderColor placeholder:font-bold Campton  md:placeholder:text-2xl "
                    type={showconfirmpassword ? "text" : "password"}
                    placeholder="Re-enter Password"
                  />
                  {showconfirmpassword ? (
                    <>
                      <Icon
                        icon="clarity:eye-show-line"
                        color="rgba(4, 4, 4, 0.50)"
                        width="32"
                        className=" cursor-pointer"
                        height="25"
                        onClick={() => {
                          setshowconfirmpassword(false);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <Icon
                        icon="clarity:eye-hide-line"
                        color="rgba(4, 4, 4, 0.50)"
                        width="32"
                        height="25"
                        className=" cursor-pointer"
                        onClick={() => {
                          setshowconfirmpassword(true);
                        }}
                      />
                    </>
                  )}
                </div>

                <div
                  className="remember_me mt-4 items-center cursor-pointer  flex gap-3 Rubik"
                  onClick={() => {
                    setcheck(!check);
                  }}
                >
                  {check ? (
                    <>
                      <div className=" w-6 h-6">
                        <img src={check_} alt="" className="w-full h-full" />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className=" w-6 h-6">
                        <img src={uncheck} alt="" className="w-full h-full" />
                      </div>
                    </>
                  )}
                  <span className="Campton 2xl:text-[22px]">Remember me</span>
                </div>
              </div>
              <div className="">
                <div className=" Campton btn_bg_gradient logout  lg:text-xl    p-2 md:p-3 2xl:p-5 2xl:text-2xl">
                  Sign up
                </div>
                <p className="Campton-light text-sm xl:text-xl text-center mt-4 ">
                  Do you have an account?{" "}
                  <a href="/signin">
                    <span className="primary ps-3 Campton">Get Started</span>
                  </a>
                </p>
              </div>

              <div className="">
                <p className=" mb-4 text-center Campton-thin text-[#143A62] text-lg font-normal">
                  or sign in with
                </p>
                <div className="social_links flex justify-center gap-8 md:gap-16">
                  <img
                    src={google}
                    alt=""
                    className=" cursor-pointer w-[60px] h-[40px] lg:w-[80px] 2xl:w-auto lg:h-[60px] 2xl:h-auto"
                  />
                  <img
                    src={facebook}
                    alt=""
                    className=" cursor-pointer w-[60px] h-[40px] lg:w-[80px] 2xl:w-auto lg:h-[60px] 2xl:h-auto"
                  />
                  <img
                    src={twitter}
                    alt=""
                    className=" cursor-pointer w-[60px] h-[40px] lg:w-[80px] 2xl:w-auto lg:h-[60px] 2xl:h-auto"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
