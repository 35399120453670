import React from "react";
import "./team.scss";
import Teamcard from "./teamcard";
import John from "../../assets/images/john.svg";
import Jane from "../../assets/images/jane.svg";
import Micheal from "../../assets/images/micheal.svg";
import Johnn from "../../assets/images/john-sci.svg";
import David from "../../assets/images/david.svg";

const Team = () => {
  return (
    <>
      <div className="team-section">
        <div className="team-content">
          <div className="title">
            <h6>Meet Our Team</h6>
            <span>Get to know the experts behind the Root.</span>
          </div>
          <div className="team-card">
            <Teamcard
              img={John}
              title="John Doe"
              subtitle="Legal Consultant"
              textpara="With over 10 years of experience in marketing strategy and data analysis."
            />
            <Teamcard
              img={Jane}
              title="Jane Smith"
              subtitle="Business Consultant"
              textpara="Leading the development of cutting-edge data processing and visualization technologies."
            />
            <Teamcard
              img={Micheal}
              title="Micheal Johson"
              subtitle="Technology Consultant"
              textpara="Crafting innovative marketing strategies to drive engagement and ROL."
            />
            <Teamcard
              img={Johnn}
              title="John"
              subtitle="Data Scientist"
              textpara="Leveraging data and machine learning to uncover valuable insights for clients."
            />
            <Teamcard
              img={David}
              title="David Wilson"
              subtitle="UI/UX Designer"
              textpara="Creating intuitive and visually appealing interfaces for seamless user experiences."
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Team;
