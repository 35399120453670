import React, { useContext, useState } from 'react';
import './UploadFile.scss'
import SingleFileUpload from './SingleFileUpload/SingleFileUpload';
import { Outlet } from 'react-router-dom';
import Tabs from './Tabs/Tabs';
import Chatbot from '../Chatbot/Chatbot';
import { SavedFileContext } from '../../../context/SaveedFileContext';

const UploadFile = () => {
    const {currentlyOpened, setCurrentlyOpened} = useContext(SavedFileContext)

    return (
        <div className='uploadFile-main-container'>
            <div className='uploadFile-mainCentered-container'>
                <Tabs />
                <Outlet />
            </div>
            <div style={currentlyOpened ? {position: "fixed", bottom :"30px", right: "30px", width: "30%", height: "80%"} : {position: "fixed", bottom :"30px", right: "30px"}}>
                <Chatbot></Chatbot>
            </div>
        </div>
    )
}

export default UploadFile