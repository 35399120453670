import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Modal } from "flowbite-react";


const Tabs = () => {
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(1);
    const [currentSelectedTab, setCurrentSelectedTab] = useState("")
    const [openModal, setOpenModal] = useState(false);
const navigate = useNavigate();

    const handleTabChange = (tabNumber) => {
        setActiveTab(tabNumber);
    };
    return (
        <>
            <div className='head-tabs'>
                {/* <Link to="/dashboard/uploadFile/singlefileupload">
                    <button className={location.pathname.includes("/dashboard/uploadFile") ? 'active-tab' : ''} onClick={() => { handleTabChange(1) }}>Upload File</button>
                </Link>
                <Link to="/dashboard/analysis/analysis1">
                    <button className={location.pathname.includes("/dashboard/analysis") ? 'active-tab' : ''} onClick={() => { handleTabChange(2) }}>Analysis</button>
                </Link>
                <Link to="/dashboard/datavisualization/datavisualization1">
                    <button className={location.pathname.includes("/dashboard/datavisualization") ? 'active-tab' : ''} onClick={() => handleTabChange(3)}>Data Visualization</button>
                </Link> */}
                {/* <Link to="/dashboard/uploadFile/singlefileupload"> */}
                    <button className={location.pathname.includes("/dashboard/uploadFile") ? 'active-tab' : ''} onClick={() => { 
                        if (location.pathname.includes("uploadFile") || location.pathname.includes("analysis") || location.pathname.includes("datavisualization")) {
                            setCurrentSelectedTab({ url: "/dashboard/uploadFile/singlefileupload", name: 1 })

                            setOpenModal(true)

                        }
                        else {


                        handleTabChange(1) 
                    
                        }
                    }}>Upload File</button>
                {/* </Link> */}

                <button className={location.pathname.includes("/dashboard/analysis") ? 'active-tab' : ''} onClick={() => { handleTabChange(2) }} disabled={true} >Analysis</button>


                <button className={location.pathname.includes("/dashboard/datavisualization") ? 'active-tab' : ''} onClick={() => handleTabChange(3)} disabled={true} >Data Visualization</button>

            </div>
            {/* Tab 1 Start */}
            <Modal
                show={openModal}
                onClose={() => setOpenModal(false)}
                className="dashboard-modal bg-opacity-60"
                size="2xl"

            >

                <Modal.Body className="large ssss  ">
                    <div className="ddddd">
                        <div className=" px-5 md:px-0 flex justify-center items-center md:items-stretch lg:items-center  flex-col md:flex-row 2xl:py-[20px] " style={{ flexDirection: "column", gap: "20px" }}>
                            <h1>This will reset all the steps. Do you want to proceed?</h1>
                            <style>
                                {`
                    
                    .modalBtns{
                        color: white;
                         padding: 3px 20px;
                        border-radius: 5px;
                    }
                    .modalBtns:hover{
                        opacity: 0.8;
                    }
                    `}


                            </style>
                            <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                                <button className='modalBtns' style={{ backgroundColor: "#00a170" }} onClick={() => {
                                    console.log("dd", currentSelectedTab);
                                    handleTabChange(currentSelectedTab.name);

                                    navigate(currentSelectedTab.url)
                                    // setshow(false);
                                    setOpenModal(false);
                                }}>Yes</button>
                                <button className='modalBtns' style={{ backgroundColor: "crimson" }} onClick={() => {
                                    setOpenModal(false);

                                }}>No</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Tabs