import React, { useContext, useEffect, useState } from 'react'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { SavedFileContext } from '../../../../../context/SaveedFileContext';

const Analysis = () => {

    const location = useLocation();
    const { savedFile, setSavedFile } = useContext(SavedFileContext)
    const navigate = useNavigate();

    const handlePreviousDataSaved = () => {
        setSavedFile(location?.state?.csvData)
        navigate("/dashboard/uploadFile/singlefileupload")
    }

    return (
        <div>


            {/* <div className='innerTabs-box innerTabs-box-analysis'>
                <Link to='/dashboard/analysis/meta' state={{ csvData: location?.state?.csvData }}>
                    <button style={{ border: "1px solid white" }}>Meta</button>
                </Link>
                <Link to='/dashboard/analysis/tiktok' state={{ csvData: location?.state?.csvData }}>
                    <button style={{ border: "1px solid white" }}>TikTok</button>
                </Link>
                <Link to='/dashboard/analysis/aggregateSheet' state={{ csvData: location?.state?.csvData }}>
                    <button style={{ border: "1px solid white" }}>Fixed</button>
                </Link>
                 <div onClick={handlePreviousDataSaved}>
                    <button>Add Another Analysis Report</button>
                </div>
                <Link to=''>
                    <button style={{ border: "1px solid white" }}>Combined</button>
                </Link>
                <Link to='/dashboard/analysis/tt' state={{ csvData: location?.state?.csvData }}>
                    <button style={{ border: "1px solid white" }}>TTD</button>
                </Link>
            </div> */}

            <Outlet />

        </div>
    )
}

export default Analysis

