import React, { useState, useEffect, useRef } from 'react'
import { Table } from 'antd'
import '../../../components/DataTables/DataTables.css'
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';


const AggregateSheet = () => {

    const dataSource = [
        {
            key: '1',
            Measurable: 'Mike',
            SUM_of_Opportunity_Index: 32,
            Net_Spend: '10 Downing Street',

            Impressions: 'Mike',
            Clicks: 32,
            SUM_of_CTR: '10 Downing Street',

            SUM_of_eCPM: 'Mike',
            SUM_of_CPC: 32,
            SUM_of_OTR: '10 Downing Street',

            SUM_of_Viewability: 'Mike',
            SUM_of_Fraud: 32,
            SUM_of_Frequency: '10 Downing Street',

            SUM_of_Avg_OT_CTR: 'Mike',
            SUM_of_OTF: 32,
            SUM_of_NF_vCPM: '10 Downing Street',

            SUM_of_eCPMH: 'Mike',
            SUM_of_OTR_vCPM: 32,
            SUM_of_qCPM: '10 Downing Street',

            SUM_of_VCR: 'Mike',
            SUM_of_vCPCV: 32,
            SUM_of_OT_vCPCV: '10 Downing Street',

            SUM_of_hCPCV: 'Mike',
            SUM_of_qCPH: 32,
            SUM_of_ROAS: '10 Downing Street',

            SUM_of_CPL: 'Mike',
            SUM_of_qCPL: 32,
            SUM_of_CPS: 32,

            SUM_of_qCPS: '10 Downing Street',

        },
        {
            key: '2',
            Measurable: 'Mike',
            SUM_of_Opportunity_Index: 32,
            Net_Spend: '10 Downing Street',

            Impressions: 'Mike',
            Clicks: 32,
            SUM_of_CTR: '10 Downing Street',

            SUM_of_eCPM: 'Mike',
            SUM_of_CPC: 32,
            SUM_of_OTR: '10 Downing Street',

            SUM_of_Viewability: 'Mike',
            SUM_of_Fraud: 32,
            SUM_of_Frequency: '10 Downing Street',

            SUM_of_Avg_OT_CTR: 'Mike',
            SUM_of_OTF: 32,
            SUM_of_NF_vCPM: '10 Downing Street',

            SUM_of_eCPMH: 'Mike',
            SUM_of_OTR_vCPM: 32,
            SUM_of_qCPM: '10 Downing Street',

            SUM_of_VCR: 'Mike',
            SUM_of_vCPCV: 32,
            SUM_of_OT_vCPCV: '10 Downing Street',

            SUM_of_hCPCV: 'Mike',
            SUM_of_qCPH: 32,
            SUM_of_ROAS: '10 Downing Street',

            SUM_of_CPL: 'Mike',
            SUM_of_qCPL: 32,
            SUM_of_CPS: 32,

            SUM_of_qCPS: '10 Downing Street',

        },
        {
            key: '1',
            Measurable: 'Mike',
            SUM_of_Opportunity_Index: 32,
            Net_Spend: '10 Downing Street',

            Impressions: 'Mike',
            Clicks: 32,
            SUM_of_CTR: '10 Downing Street',

            SUM_of_eCPM: 'Mike',
            SUM_of_CPC: 32,
            SUM_of_OTR: '10 Downing Street',

            SUM_of_Viewability: 'Mike',
            SUM_of_Fraud: 32,
            SUM_of_Frequency: '10 Downing Street',

            SUM_of_Avg_OT_CTR: 'Mike',
            SUM_of_OTF: 32,
            SUM_of_NF_vCPM: '10 Downing Street',

            SUM_of_eCPMH: 'Mike',
            SUM_of_OTR_vCPM: 32,
            SUM_of_qCPM: '10 Downing Street',

            SUM_of_VCR: 'Mike',
            SUM_of_vCPCV: 32,
            SUM_of_OT_vCPCV: '10 Downing Street',

            SUM_of_hCPCV: 'Mike',
            SUM_of_qCPH: 32,
            SUM_of_ROAS: '10 Downing Street',

            SUM_of_CPL: 'Mike',
            SUM_of_qCPL: 32,
            SUM_of_CPS: 32,

            SUM_of_qCPS: '10 Downing Street',

        },
        {
            key: '2',
            Measurable: 'Mike',
            SUM_of_Opportunity_Index: 32,
            Net_Spend: '10 Downing Street',

            Impressions: 'Mike',
            Clicks: 32,
            SUM_of_CTR: '10 Downing Street',

            SUM_of_eCPM: 'Mike',
            SUM_of_CPC: 32,
            SUM_of_OTR: '10 Downing Street',

            SUM_of_Viewability: 'Mike',
            SUM_of_Fraud: 32,
            SUM_of_Frequency: '10 Downing Street',

            SUM_of_Avg_OT_CTR: 'Mike',
            SUM_of_OTF: 32,
            SUM_of_NF_vCPM: '10 Downing Street',

            SUM_of_eCPMH: 'Mike',
            SUM_of_OTR_vCPM: 32,
            SUM_of_qCPM: '10 Downing Street',

            SUM_of_VCR: 'Mike',
            SUM_of_vCPCV: 32,
            SUM_of_OT_vCPCV: '10 Downing Street',

            SUM_of_hCPCV: 'Mike',
            SUM_of_qCPH: 32,
            SUM_of_ROAS: '10 Downing Street',

            SUM_of_CPL: 'Mike',
            SUM_of_qCPL: 32,
            SUM_of_CPS: 32,

            SUM_of_qCPS: '10 Downing Street',

        }
    ];

    // const columns = [

    //     // {
    //     //     title: 'SUM of Opportunity Index',
    //     //     dataIndex: 'SUM_of_Opportunity_Index',
    //     //     key: 'SUM of Opportunity Index',
    //     // },
    //     {
    //         title: 'Net Spend',
    //         dataIndex: 'Total Sum of Net Spend',
    //         key: 'Net Spend',
    //     },
    //     {
    //         title: 'Impressions',
    //         dataIndex: 'Total Sum of Platform Impressions',
    //         key: 'Impressions',
    //     },
    //     {
    //         title: 'Clicks',
    //         dataIndex: 'Total Sum of Clicks',
    //         key: 'Clicks',
    //     },
    //     {
    //         title: 'CTR',
    //         dataIndex: 'CTR',
    //         key: 'CTR',
    //     },
    //     {
    //         title: 'eCPM',
    //         dataIndex: 'Net Spend per 1000 Impressions',
    //         key: 'eCPM',
    //     },
    //     {
    //         title: 'CPC',
    //         dataIndex: 'Net Spend per 1000',
    //         key: 'CPC',
    //     },
    //     {
    //         title: 'OTR',
    //         dataIndex: 'Reach/Impression',
    //         key: 'OTR',
    //     },
    //     {
    //         title: 'Viewability',
    //         dataIndex: 'Viewable/Total Impressions Ratio',
    //         key: 'Viewability',
    //     },
    //     {
    //         title: 'Fraud',
    //         dataIndex: 'Fraudulent/Total Impressions Ratio',
    //         key: 'Fraud',
    //     },
    //     {
    //         title: 'Frequency',
    //         dataIndex: 'Impressions/Unique Reach Ratio',
    //         key: 'Frequency',
    //     },
    //     {
    //         title: 'Avg. OT CTR',
    //         dataIndex: 'On Target Ratio',
    //         key: 'Avg. OT CTR',
    //     },
    //     {
    //         title: 'OTF',
    //         dataIndex: 'Targeted Reach/Unique Reach',
    //         key: 'OTF',
    //     },
    //     {
    //         title: 'NF vCPM',
    //         dataIndex: 'Custom Metric A',
    //         key: 'NF vCPM',
    //     },
    //     {
    //         title: 'eCPMH',
    //         dataIndex: 'Adjusted eCPMh',
    //         key: 'eCPMH',
    //     },
    //     {
    //         title: 'OTR vCPM',
    //         dataIndex: 'New Metric B',
    //         key: 'OTR vCPM',
    //     },
    //     {
    //         title: 'qCPM',
    //         dataIndex: 'qCPM',
    //         key: 'qCPM',
    //     },
    //     {
    //         title: 'VCR',
    //         dataIndex: 'Video Completion Rate',
    //         key: 'VCR',
    //     },
    //     {
    //         title: 'vCPCV',
    //         dataIndex: 'vCPCV',
    //         key: 'vCPCV',
    //     },
    //     {
    //         title: 'OT vCPCV',
    //         dataIndex: 'OT_vCPCV',
    //         key: 'SOT vCPCV',
    //     },
    //     {
    //         title: 'hCPCV',
    //         dataIndex: 'hCPCV',
    //         key: 'hCPCV',
    //     },
    //     {
    //         title: 'qCPH',
    //         dataIndex: 'vCPM',
    //         key: 'qCPH',
    //     },
    //     {
    //         title: 'ROAS',
    //         dataIndex: 'Revenue to Net Spend',
    //         key: 'ROAS',
    //     },
    //     {
    //         title: 'CPL',
    //         dataIndex: 'Cost Per Lead',
    //         key: 'CPL',
    //     },
    //     {
    //         title: 'qCPL',
    //         dataIndex: 'qCPL',
    //         key: 'qCPL',
    //     },
    //     {
    //         title: 'CPS',
    //         dataIndex: 'Net Spend to Sales',
    //         key: 'CPS',
    //     },
    //     {
    //         title: 'qCPS',
    //         dataIndex: 'Adjusted CPS',
    //         key: 'qCPS',
    //     },

    //     {
    //         title: 'Average Reach',
    //         dataIndex: 'Impressions_per_Unique_Reach',
    //         key: 'Average Reach',
    //     },
    //     {
    //         title: 'Percentage on Target',
    //         dataIndex: 'Targeted Reach Ratio',
    //         key: 'Percentage on Target',
    //     },
    //     {
    //         title: 'CPA',
    //         dataIndex: 'Cost per Conversion',
    //         key: 'CPA',
    //     },
    //     {
    //         title: 'CPCV',
    //         dataIndex: 'Cost per Completion',
    //         key: 'CPCV',
    //     },
    //     {
    //         title: 'vCPM',
    //         dataIndex: 'vCPM',
    //         key: 'CPCV',
    //     },
    //     {
    //         title: 'OTI',
    //         dataIndex: 'Off-Target Impressions',
    //         key: 'CPCV',
    //     },
    //     // {
    //     //     title: 'CPCV',
    //     //     dataIndex: 'Cost per Completion',
    //     //     key: 'CPCV',
    //     // },
    // ];
    // const columns = [

    //     // {
    //     //     title: 'SUM of Opportunity Index',
    //     //     dataIndex: 'SUM_of_Opportunity_Index',
    //     //     key: 'SUM of Opportunity Index',
    //     // },
    //     {
    //         title: 'Net Spend',
    //         dataIndex: 'Total Sum of Net Spend',
    //         key: 'Net Spend',
    //     },
    //     {
    //         title: 'Platform Impressions',
    //         dataIndex: 'Total Sum of Platform Impressions',
    //         key: 'Impressions',
    //     },
    //     {
    //         title: 'Clicks',
    //         dataIndex: 'Total Sum of Clicks',
    //         key: 'Clicks',
    //     },
    //     {
    //         title: 'CTR',
    //         dataIndex: 'CTR',
    //         key: 'CTR',
    //     },
    //     {
    //         title: 'eCPM',
    //         dataIndex: 'Net Spend per 1000 Impressions',
    //         key: 'eCPM',
    //     },
    //     {
    //         title: 'CPC',
    //         dataIndex: 'Net Spend per 1000',
    //         key: 'CPC',
    //     },
       
    //     {
    //         title: 'Viewability',
    //         dataIndex: 'Viewable/Total Impressions Ratio',
    //         key: 'Viewability',
    //     },
    //     {
    //         title: 'Fraud',
    //         dataIndex: 'Fraudulent/Total Impressions Ratio',
    //         key: 'Fraud',
    //     },
    //     {
    //         title: 'Frequency',
    //         dataIndex: 'Impressions/Unique Reach Ratio',
    //         key: 'Frequency',
    //     },
       
       
    //     {
    //         title: 'NF vCPM',
    //         dataIndex: 'Custom Metric A',
    //         key: 'NF vCPM',
    //     },
    //     {
    //         title: 'eCPMH',
    //         dataIndex: 'Adjusted eCPMh',
    //         key: 'eCPMH',
    //     },
       
    //     {
    //         title: 'qCPM',
    //         dataIndex: 'qCPM',
    //         key: 'qCPM',
    //     },
    //     {
    //         title: 'VCR',
    //         dataIndex: 'Video Completion Rate',
    //         key: 'VCR',
    //     },
    //     {
    //         title: 'vCPCV',
    //         dataIndex: 'vCPCV',
    //         key: 'vCPCV',
    //     },
    //     {
    //         title: 'OT vCPCV',
    //         dataIndex: 'OT_vCPCV',
    //         key: 'SOT vCPCV',
    //     },
    //     {
    //         title: 'hCPCV',
    //         dataIndex: 'hCPCV',
    //         key: 'hCPCV',
    //     },
       
    //     {
    //         title: 'ROAS',
    //         dataIndex: 'Revenue to Net Spend',
    //         key: 'ROAS',
    //     },
       
    //     {
    //         title: 'CPA',
    //         dataIndex: 'Cost per Conversion',
    //         key: 'CPA',
    //     },
    //     {
    //         title: 'CPCV',
    //         dataIndex: 'Cost per Completion',
    //         key: 'CPCV',
    //     },
    //     {
    //         title: 'vCPM',
    //         dataIndex: 'vCPM',
    //         key: 'CPCV',
    //     },
        
       
    //     // {
    //     //     title: 'CPCV',
    //     //     dataIndex: 'Cost per Completion',
    //     //     key: 'CPCV',
    //     // },
    //     {
    //         title: 'Working Media',
    //         dataIndex: 'Total Sum of Working Media',
    //         key: 'Working Media',
    //     },
    //     {
    //         title: 'Waste Media',
    //         dataIndex: 'Total Sum of Waste Media',
    //         key: 'Non Working Media',
    //     },
    // ];
    const columns = [

       // {
            //     title: 'SUM of Opportunity Index',
            //     dataIndex: 'SUM_of_Opportunity_Index',
            //     key: 'SUM of Opportunity Index',
            // },
            {
                title: 'Net Spend',
                dataIndex: 'Total Sum of Net Spend',
                key: 'Net Spend',
            },
            {
                title: 'Platform Impressions',
                dataIndex: 'Total Sum of Platform Impressions',
                key: 'Impressions',
            },
            {
                title: 'Clicks',
                dataIndex: 'Total Sum of Clicks',
                key: 'Clicks',
            },
            {
                title: 'CTR',
                dataIndex: 'CTR',
                key: 'CTR',
            },
            {
                title: 'eCPM',
                dataIndex: 'Net Spend per 1000 Impressions',
                key: 'eCPM',
            },
            {
                title: 'CPC',
                dataIndex: 'Net Spend per 1000',
                key: 'CPC',
            },
            {
                title: 'VCR',
                dataIndex: 'Video Completion Rate',
                key: 'VCR',
            },
            {
                title: 'CPCV',
                dataIndex: 'Cost per Completion',
                key: 'CPCV',
            },
            
            {
                title: 'Viewability %',
                dataIndex: 'Viewable/Total Impressions Ratio',
                key: 'Viewability',
            },
            {
                title: 'vCPCV',
                dataIndex: 'vCPCV',
                key: 'vCPCV',
            },
            {
                title: 'hCPCV',
                dataIndex: 'hCPCV',
                key: 'hCPCV',
            },
            {
                title: 'vCPM',
                dataIndex: 'vCPM',
                key: 'CPCV',
            },
            {
                title: 'Fraud %',
                dataIndex: 'Fraudulent/Total Impressions Ratio',
                key: 'Fraud',
            },
            {
                title: 'NF vCPM',
                dataIndex: 'Custom Metric A',
                key: 'NF vCPM',
            },
            
           
           
            
            {
                title: 'eCPMH',
                dataIndex: 'Adjusted eCPMh',
                key: 'eCPMH',
            },
           
            {
                title: 'qCPM',
                dataIndex: 'qCPM',
                key: 'qCPM',
            },
            {
                title: 'Frequency',
                dataIndex: 'Impressions/Unique Reach Ratio',
                key: 'Frequency',
            },
            {
                title: 'OT vCPCV',
                dataIndex: 'OT_vCPCV',
                key: 'SOT vCPCV',
            },
            
           
            {
                title: 'ROAS',
                dataIndex: 'Revenue to Net Spend',
                key: 'ROAS',
            },
           
            {
                title: 'CPA',
                dataIndex: 'Cost per Conversion',
                key: 'CPA',
            },
            
            
            
           
            // {
            //     title: 'CPCV',
            //     dataIndex: 'Cost per Completion',
            //     key: 'CPCV',
            // },
            {
                title: 'Working Media',
                dataIndex: 'Total Sum of Working Media',
                key: 'Working Media',
            },
            {
                title: 'Waste Media',
                dataIndex: 'Total Sum of Waste Media',
                key: 'Non Working Media',
            },
    ];

    const [tableMeasurable, setTableMeasurable] = useState([{}])
    const [tableCampaign, setTableCampaign] = useState([{}])
    const [tableWeek, setTableWeek] = useState([{}])
    const [tableBrand, setTableBrand] = useState([{}])
    const [tableInventory, setTableInventory] = useState([{}])
    const [tablePlatform, setTablePlatform] = useState([{}])
    const [tableDataTargeting, setTableDataTargeting] = useState([{}])
    const [tableSize, setTableSize] = useState([{}])
    const [csvData, setCsvData] = useState("");
    const [resultsCount, setResultsCount] = useState(0);
    const [loading, setLoading] = useState(false)
    const [totalCalculation, setTotalCalculation] = useState(false);
    const auditSheetRef = useRef();


    const location = useLocation();
    const justForTest = async () => {
        const blob = new Blob([location.state.csvData], { type: 'text/csv' });


        let data = new FormData();
        data.append('file', blob);


        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://mywork.techrecipes.com/upload-csv-CTR/',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: data
        };
        config.url = "https://mywork.techrecipes.com/upload-csv-OT_CTR/"
        axios.request(config)
            .then((response) => {
                if (response.data.detail) { return; } if (response.data.detail) { return; }
                console.log("Our audit data", response.data);
                console.log("Saad bhai");
                setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




                setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))



            })
            .catch((error) => {
                console.log("ohh", error);
            });
    }
    const metaNew = async () => {
        try {
            setLoading(true);
            const blob = new Blob([location.state.csvData], { type: 'text/csv' });


            let data = new FormData();
            data.append('file', blob);


            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://mywork.techrecipes.com/upload-csv-analysis/',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: data
            };

            let response = await axios.request(config);

            Object.keys(response.data).map((key, index)=>{
                console.log("Here in key map ", key);
                Object.keys(response.data[key]).map((key2, index2)=>{
                    response.data[key][key2].map((data, index3)=>{
                        Object.keys(response.data[key][key2][index3]).map((key4, index4)=>{
                            if (response.data[key][key2][index3][key4] == "nan" || response.data[key][key2][index3][key4] == "nan%" || response.data[key][key2][index3][key4] == "nan$"){
                                response.data[key][key2][index3][key4] = "0"
                            }
                        })
                        
                        
                    })
                })
                // if (response.data[key] == "nan"){
                // response.data[key] = "0"
                // }
            })
            console.log("After filtering", response.data);

   
            let localTableMeasureable = [];
            Object.keys(response.data).map((key, indexMain) => {
                setTableMeasurable(prev => {
                    if (response.data[key].Measurable) {

                        console.log((response.data[key].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                        return ((response.data[key].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                    }
                    else {
                        console.log("d", prev);
                        return prev
                    }

                }
                )

                setTableCampaign(prev => {
                    if (response.data[key]["Campaign Objective"]) {

                        console.log((response.data[key]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                        return ((response.data[key]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                    }
                    else {
                        console.log("d", prev);

                        return prev;
                    }
                }
                )




                // setTableCampaign(prev => {
                //     return (response.data[key]["Week"]?.map((data, index) => ({ ...data, ...prev[index] })))

                // })


                setTableBrand(prev => {
                    if (response.data[key]["Brand"]) {

                        return (response.data[key]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] })))
                    }
                    else {
                        console.log("d", prev);

                        return prev;
                    }
                })


                setTableInventory(prev => {
                    if (response.data[key]["Inventory Type"]) {

                        return (response.data[key]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] })))
                    }
                    else {
                        console.log("d", prev);

                        return prev;
                    }
                })


                setTablePlatform(prev => {
                    if (response.data[key]["Platform"]) {

                        return (response.data[key]["Platform"]?.map((data, index) => ({ ...data, ...prev[index] })))
                    }
                    else {
                        console.log("d", prev);

                        return prev;
                    }
                })


                setTableDataTargeting(prev => {
                    if (response.data[key]["Data Targeting"]) {

                        return (response.data[key]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] })))
                    }
                    else {
                        console.log("d", prev);

                        return prev;
                    }
                })


                setTableSize(prev => {
                    if (response.data[key]["Size/Length"]) {

                        return (response.data[key]["Size/Length"]?.map((data, index) => ({ ...data, ...prev[index] })))
                    }
                    else {
                        console.log("d", prev);

                        return prev
                    }
                })
                setTableWeek(prev => {
                    if (response.data[key]["Week"]) {

                        // console.log("In week", response.data[key]["Week"]?.map((data, index) => ({ ...data })));
                        return (response.data[key]["Week"]?.map((data, index) => ({ ...data, ...prev[index] })))
                    }
                    else {
                        console.log("d", prev);

                        return prev
                    }
                })

                // console.log("Let's see", tableMeasurable);
                // console.log("Let's see", tableCampaign);
                // console.log("Let's see", tableBrand);
                // console.log("Let's see", tableInventory);
                // console.log("Let's see", tablePlatform);
                // console.log("Let's see", tableDataTargeting);
                // console.log("Let's see", tableSize);
                // console.log("Let's see", tableWeek);

            })
            config.url = "https://mywork.techrecipes.com/upload-csv-total_sum_m_T/"
            const responseMore = await axios.request(config);
            console.log(responseMore.data);
            setTableMeasurable(prev => {
                if (responseMore.data["Total Sums"].Measurable) {

                    console.log((responseMore.data["Total Sums"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                    return ((responseMore.data["Total Sums"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                }
                else {
                    return prev
                }

            }
            )

            setTableCampaign(prev => {

                // console.log((responseMore.data["Total Sums"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                if (responseMore.data["Total Sums"]["Campaign Objective"])
                    return ((responseMore.data["Total Sums"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                else {
                    return prev
                }
            }
            )




            // setTableCampaign(prev => {
            //     return (responseMore.data["Total Sums"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] })))

            // })


            setTableBrand(prev => {
                if (responseMore.data["Total Sums"]["Brand"])
                    return (responseMore.data["Total Sums"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] })))
                else {
                    return prev
                }
            })


            setTableInventory(prev => {
                if (responseMore.data["Total Sums"]["Inventory Type"])
                    return (responseMore.data["Total Sums"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] })))
                else {
                    return prev
                }
            })


            setTablePlatform(prev => {
                if (responseMore.data["Total Sums"]["Platform"])
                    return (responseMore.data["Total Sums"]["Platform"]?.map((data, index) => ({ ...data, ...prev[index] })))
                else {
                    return prev
                }
            })


            setTableDataTargeting(prev => {
                if (responseMore.data["Total Sums"]["Data Targeting"])
                    return (responseMore.data["Total Sums"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] })))
                else {
                    return prev
                }
            })


            setTableSize(prev => {
                if (responseMore.data["Total Sums"]["Size/Length"])
                    return (responseMore.data["Total Sums"]["Size/Length"]?.map((data, index) => ({ ...data, ...prev[index] })))
                else {
                    return prev
                }
            })
            setTableWeek(prev => {
                // console.log("In week", responseMore.data["Total Sums"]["Week"]?.map((data, index) => ({ ...data })));
                if (responseMore.data["Total Sums"]["Week"])
                    return (responseMore.data["Total Sums"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] })))
                else {
                    return prev
                }
            })
            getTotal();
        }
        catch (e) {
            setLoading(false);

        }
    }
    const metaMapping = () => {
        try {
            const blob = new Blob([location.state.csvData], { type: 'text/csv' });


            let data = new FormData();
            data.append('file', blob);


            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://mywork.techrecipes.com/upload-csv-CTR/',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: data
            };
            axios.request(config)
                .then((response) => {
                    console.log("tests", response.data);
                    if (response.data.detail) { return; }
                    console.log("Our audit data", response.data);
                    console.log("Saad bhai");
                    setResultsCount(prev => prev + 1);
                    setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




                    setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))

                })
                .catch((error) => {
                    console.log(error);
                    setResultsCount(prev => prev + 1);
                });
            // Other API

            config.url = "https://mywork.techrecipes.com/upload-csv-SUM of Impressions,Net Spend,Clicks/"
            axios.request(config)
                .then((response) => {
                    if (response.data.detail) { return; }
                    console.log("Our audit data", response.data);
                    console.log("Saad bhai");
                    setResultsCount(prev => prev + 1);
                    setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




                    setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))

                    setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))

                    setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    // setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    // setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))



                })
                .catch((error) => {
                    console.log(error);
                    setResultsCount(prev => prev + 1);
                });

            config.url = "https://mywork.techrecipes.com/upload-csv-eCPM/"
            axios.request(config)
                .then((response) => {
                    if (response.data.detail) { return; }
                    console.log("Our audit data", response.data);
                    console.log("Saad bhai");
                    setResultsCount(prev => prev + 1);
                    setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




                    setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                })
                .catch((error) => {
                    console.log(error);
                    setResultsCount(prev => prev + 1);
                });

            // Other API

            config.url = "https://mywork.techrecipes.com/upload-csv-CPC/"
            axios.request(config)
                .then((response) => {
                    if (response.data.detail) { return; }
                    console.log("Our audit data", response.data);
                    console.log("Saad bhai");
                    setResultsCount(prev => prev + 1);
                    setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




                    setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                })
                .catch((error) => {
                    console.log(error);
                    setResultsCount(prev => prev + 1);
                });

            // Other API

            config.url = "https://mywork.techrecipes.com/upload-csv-ORT/"
            axios.request(config)
                .then((response) => {
                    if (response.data.detail) { return; }
                    console.log("Our audit data", response.data);
                    console.log("Saad bhai");
                    setResultsCount(prev => prev + 1);
                    setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




                    setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))



                })
                .catch((error) => {
                    console.log(error);
                    setResultsCount(prev => prev + 1);
                });

            // Other API

            config.url = "https://mywork.techrecipes.com/upload-csv-Viewability/"
            axios.request(config)
                .then((response) => {
                    if (response.data.detail) { return; }
                    console.log("Our audit data", response.data);
                    console.log("Saad bhai");
                    setResultsCount(prev => prev + 1);
                    setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




                    setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                })
                .catch((error) => {
                    console.log(error);
                    setResultsCount(prev => prev + 1);
                });

            // Other API

            config.url = "https://mywork.techrecipes.com/upload-csv-Fraud/"
            axios.request(config)
                .then((response) => {
                    if (response.data.detail) { return; }
                    console.log("Our audit data", response.data);
                    console.log("Saad bhai");
                    setResultsCount(prev => prev + 1);
                    setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




                    setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                })
                .catch((error) => {
                    console.log(error);
                    setResultsCount(prev => prev + 1);
                });

            // Other API

            config.url = "https://mywork.techrecipes.com/upload-csv-AverageFrequency/"
            axios.request(config)
                .then((response) => {
                    console.log("broo", response.data);
                    if (response.data.detail) {

                        return;
                    }
                    console.log("Our audit data", response.data);
                    console.log("Saad bhai");
                    setResultsCount(prev => prev + 1);
                    setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




                    setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                })
                .catch((error) => {
                    console.log(error);
                    setResultsCount(prev => prev + 1);
                });

            // Other API

            config.url = "https://mywork.techrecipes.com/upload-csv-OT_CTR/"
            axios.request(config)
                .then((response) => {
                    if (response.data.detail) { return; }
                    console.log("Our audit data", response.data);
                    console.log("Saad bhai");
                    setResultsCount(prev => prev + 1);
                    setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




                    setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))



                })
                .catch((error) => {
                    console.log(error);
                    setResultsCount(prev => prev + 1);
                });

            // Other API

            config.url = "https://mywork.techrecipes.com/upload-csv-OTF/"
            axios.request(config)
                .then((response) => {
                    if (response.data.detail) { return; }
                    console.log("Our audit data", response.data);
                    console.log("Saad bhai");
                    setResultsCount(prev => prev + 1);
                    setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




                    setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))

                })
                .catch((error) => {
                    console.log(error);
                    setResultsCount(prev => prev + 1);
                });

            // Other API

            config.url = "https://mywork.techrecipes.com/upload-csv-NF_vCPM/"
            axios.request(config)
                .then((response) => {
                    if (response.data.detail) { return; }
                    console.log("Our audit data", response.data);
                    console.log("Saad bhai");
                    setResultsCount(prev => prev + 1);
                    setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




                    setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                })
                .catch((error) => {
                    console.log(error);
                    setResultsCount(prev => prev + 1);
                });
            // Other API

            config.url = "https://mywork.techrecipes.com/upload-csv-eCPMh/"
            axios.request(config)
                .then((response) => {
                    if (response.data.detail) { return; }
                    console.log("Our audit data", response.data);
                    console.log("Saad bhai");
                    setResultsCount(prev => prev + 1);
                    setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




                    setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                })
                .catch((error) => {
                    console.log(error);
                    setResultsCount(prev => prev + 1);
                });
            // Other API

            config.url = "https://mywork.techrecipes.com/upload-csv-OTvCPM/"
            axios.request(config)
                .then((response) => {
                    if (response.data.detail) { return; }
                    console.log("Our audit data", response.data);
                    console.log("Saad bhai");
                    setResultsCount(prev => prev + 1);
                    setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




                    setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))



                })
                .catch((error) => {
                    console.log(error);
                    setResultsCount(prev => prev + 1);
                });
            // Other API

            // config.url = "https://mywork.techrecipes.com/upload-csv-eCPMh/"
            // axios.request(config)
            //     .then((response) => {
            //         if (response.data.detail) { return; }
            //         console.log("Our audit data", response.data);
            //         console.log("Saad bhai");
            setResultsCount(prev => prev + 1);
            //         setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
            //         setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




            //         setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


            //         setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


            //         setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


            //         setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


            //         setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


            //         setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
            //         setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))



            //     })
            //     .catch((error) => {
            //         console.log(error);
            setResultsCount(prev => prev + 1);
            //     });
            // Other API

            config.url = "https://mywork.techrecipes.com/upload-csv-qCPM/"
            axios.request(config)
                .then((response) => {
                    if (response.data.detail) { return; }
                    console.log("Our audit data", response.data);
                    console.log("Saad bhai");
                    setResultsCount(prev => prev + 1);
                    setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




                    setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                })
                .catch((error) => {
                    console.log(error);
                    setResultsCount(prev => prev + 1);
                });
            // Other API

            config.url = "https://mywork.techrecipes.com/upload-csv-VCR/"
            axios.request(config)
                .then((response) => {
                    if (response.data.detail) { return; }
                    console.log("Our audit data", response.data);
                    console.log("Saad bhai");
                    setResultsCount(prev => prev + 1);
                    setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




                    setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                })
                .catch((error) => {
                    console.log(error);
                    setResultsCount(prev => prev + 1);
                });
            // Other API

            config.url = "https://mywork.techrecipes.com/upload-csv-vCPCV/"
            axios.request(config)
                .then((response) => {
                    if (response.data.detail) { return; }
                    console.log("Our audit data", response.data);
                    console.log("Saad bhai");
                    setResultsCount(prev => prev + 1);
                    setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




                    setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))



                })
                .catch((error) => {
                    console.log(error);
                    setResultsCount(prev => prev + 1);
                });

            // Other API

            config.url = "https://mywork.techrecipes.com/upload-csv-OT_vCPCV/"
            axios.request(config)
                .then((response) => {
                    if (response.data.detail) { return; }
                    console.log("Our audit data", response.data);
                    console.log("Saad bhai");
                    setResultsCount(prev => prev + 1);
                    setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




                    setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                })
                .catch((error) => {
                    console.log(error);
                    setResultsCount(prev => prev + 1);
                });

            // Other API

            config.url = "https://mywork.techrecipes.com/upload-csv-hCPCV/"
            axios.request(config)
                .then((response) => {
                    if (response.data.detail) { return; }
                    console.log("Our audit data", response.data);
                    console.log("Saad bhai");
                    setResultsCount(prev => prev + 1);
                    setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




                    setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                })
                .catch((error) => {
                    console.log(error);
                    setResultsCount(prev => prev + 1);
                });
            // Other API

            config.url = "https://mywork.techrecipes.com/upload-csv-qCPH/"
            axios.request(config)
                .then((response) => {
                    if (response.data.detail) { return; }
                    console.log("Our audit data", response.data);
                    console.log("Saad bhai");
                    setResultsCount(prev => prev + 1);
                    setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




                    setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                })
                .catch((error) => {
                    console.log(error);
                    setResultsCount(prev => prev + 1);
                });
            // Other API

            config.url = "https://mywork.techrecipes.com/upload-csv-ROAS/"
            axios.request(config)
                .then((response) => {
                    if (response.data.detail) { return; }
                    console.log("Our audit data", response.data);
                    console.log("Saad bhai");
                    setResultsCount(prev => prev + 1);
                    setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




                    setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                })
                .catch((error) => {
                    console.log(error);
                    setResultsCount(prev => prev + 1);
                });
            // Other API

            config.url = "https://mywork.techrecipes.com/upload-csv-CPL/"
            axios.request(config)
                .then((response) => {
                    if (response.data.detail) { return; }
                    console.log("Our audit data", response.data);
                    console.log("Saad bhai");
                    setResultsCount(prev => prev + 1);
                    setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




                    setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))



                })
                .catch((error) => {
                    console.log(error);
                    setResultsCount(prev => prev + 1);
                });
            // Other API

            config.url = "https://mywork.techrecipes.com/upload-csv-qCPL/"
            axios.request(config)
                .then((response) => {
                    if (response.data.detail) { return; }
                    console.log("Our audit data", response.data);
                    console.log("Saad bhai");
                    setResultsCount(prev => prev + 1);
                    setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




                    setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))



                })
                .catch((error) => {
                    console.log(error);
                    setResultsCount(prev => prev + 1);
                });
            // Other API

            config.url = "https://mywork.techrecipes.com/upload-csv-CPS/"
            axios.request(config)
                .then((response) => {
                    if (response.data.detail) { return; }
                    console.log("Our audit data", response.data);
                    console.log("Saad bhai");
                    setResultsCount(prev => prev + 1);
                    setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




                    setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                })
                .catch((error) => {
                    console.log(error);
                    setResultsCount(prev => prev + 1);
                });
            // Other API

            config.url = "https://mywork.techrecipes.com/upload-csv-qCPS/"
            axios.request(config)
                .then((response) => {
                    if (response.data.detail) { return; }
                    console.log("Our audit data", response.data);
                    console.log("Saad bhai");
                    setResultsCount(prev => prev + 1);
                    setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




                    setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))

                })
                .catch((error) => {
                    console.log(error);
                    setResultsCount(prev => prev + 1);
                });
            // Other API

            config.url = "https://mywork.techrecipes.com/upload-csv-Average_Reach/"
            axios.request(config)
                .then((response) => {
                    if (response.data.detail) { return; }
                    console.log("Our audit data", response.data);
                    console.log("Saad bhai");
                    setResultsCount(prev => prev + 1);
                    setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




                    setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))



                })
                .catch((error) => {
                    console.log(error);
                    setResultsCount(prev => prev + 1);
                });
            // Other API

            config.url = "https://mywork.techrecipes.com/upload-csv-Percentage_on_target/"
            axios.request(config)
                .then((response) => {
                    if (response.data.detail) { return; }
                    console.log("Our audit data", response.data);
                    console.log("Saad bhai");
                    setResultsCount(prev => prev + 1);
                    setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




                    setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))

                })
                .catch((error) => {
                    console.log(error);
                    setResultsCount(prev => prev + 1);
                });
            // Other API

            config.url = "https://mywork.techrecipes.com/upload-csv-CPA/"
            axios.request(config)
                .then((response) => {
                    if (response.data.detail) { return; }
                    console.log("Our audit data", response.data);
                    console.log("Saad bhai");
                    setResultsCount(prev => prev + 1);
                    setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




                    setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))



                })
                .catch((error) => {
                    console.log(error);
                    setResultsCount(prev => prev + 1);
                });
            // Other API

            config.url = "https://mywork.techrecipes.com/upload-csv-CPCV/"
            axios.request(config)
                .then((response) => {
                    if (response.data.detail) { return; }
                    console.log("Our audit data", response.data);
                    console.log("Saad bhai");
                    setResultsCount(prev => prev + 1);
                    setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




                    setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))



                })
                .catch((error) => {
                    console.log(error);
                    setResultsCount(prev => prev + 1);
                });
            // Other API

            config.url = "https://mywork.techrecipes.com/upload-csv-vCPM/"
            axios.request(config)
                .then((response) => {
                    if (response.data.detail) { return; }
                    console.log("Our audit data", response.data);
                    console.log("Saad bhai");
                    setResultsCount(prev => prev + 1);
                    setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




                    setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))



                })
                .catch((error) => {
                    console.log(error);
                    setResultsCount(prev => prev + 1);
                });
            // Other API

            config.url = "https://mywork.techrecipes.com/upload-csv-OTI/"
            axios.request(config)
                .then((response) => {
                    if (response.data.detail) { return; }
                    console.log("Our audit data", response.data);
                    console.log("Saad bhai");
                    setResultsCount(prev => prev + 1);
                    setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




                    setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))



                })
                .catch((error) => {
                    console.log(error);
                    setResultsCount(prev => prev + 1);
                });
            // Other API

            config.url = "https://mywork.techrecipes.com/upload-csv-CPCV/"
            axios.request(config)
                .then((response) => {
                    if (response.data.detail) { return; }
                    console.log("Our audit data", response.data);
                    console.log("Saad bhai");
                    setResultsCount(prev => prev + 1);
                    setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




                    setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))



                })
                .catch((error) => {
                    console.log(error);
                    setResultsCount(prev => prev + 1);
                });
            // Other API

            config.url = "https://mywork.techrecipes.com/upload-csv-CPCV/"
            axios.request(config)
                .then((response) => {
                    if (response.data.detail) { return; }
                    console.log("Our audit data", response.data);
                    console.log("Saad bhai");
                    setResultsCount(prev => prev + 1);
                    setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




                    setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))



                })
                .catch((error) => {
                    console.log(error);
                    setResultsCount(prev => prev + 1);
                });
            // Other API

            config.url = "https://mywork.techrecipes.com/upload-csv-CPCV/"
            axios.request(config)
                .then((response) => {
                    if (response.data.detail) { return; }
                    console.log("Our audit data", response.data);
                    console.log("Saad bhai");
                    setResultsCount(prev => prev + 1);
                    setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




                    setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                    setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                    setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))



                })
                .catch((error) => {
                    console.log(error);
                    setResultsCount(prev => prev + 1);
                });





        }
        catch (e) {
            console.log("Error Occured", e);
        }
    }

    useEffect(() => {
        console.log("Extra Multiple File", location);
        if (location?.state?.csvData) {

            console.log("location", location.state);
            // Papa.parse(location.state.csvData, {
            //     header: true,
            //     dynamicTyping: true,
            //     complete: (result) => {
            //         console.log(result.data);
            //         setCsvData(result.data)
            //         setTableColumn(Object.keys(result.data[0]).map((csvColumnName) => (
            //             { title: csvColumnName, dataIndex: csvColumnName }
            //         )))
            //     },
            // });
            setCsvData(location.state.csvData);
            // metaMapping();
            metaNew();
            // setTableColumn(Object.keys(location.state.csvData[0]).map((csvColumnName) => (
            //     { title: csvColumnName, dataIndex: csvColumnName }
            // )))

        }
    }, [])

    const getTotal = async () => {
        const blob = new Blob([location.state.csvData], { type: 'text/csv' });


        let data = new FormData();
        data.append('file', blob);
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://cof.techrecipes.com/upload-csv-analysis/',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: data
        };
        try {
            let response = await axios.request(config);
            Object.keys(response.data).map((key, index)=>{
                console.log("Here in key map ", key);
                if (response.data[key] == "nan" || response.data[key] == "nan$" || response.data[key] == "nan%"){
                response.data[key] = "0"
                }
            })
            console.log("After filtering", response.data);

            config.url = 'https://cof.techrecipes.com/upload-csv-total_sum_m_T/';
            const responseOther = await axios.request(config);

            if (response.data.detail || responseOther.data.detail) { return; }
            console.log("Our audit data", response.data);
            console.log("Saad bhai");
            console.log("dasfj", tableMeasurable);
            Object.keys(responseOther.data["Total total_sum-m-T"]).map((key, index)=>{
                console.log("Here in key map ", key);
                if (responseOther.data["Total total_sum-m-T"][key] == "nan" || responseOther.data["Total total_sum-m-T"][key] == "$nan" || responseOther.data["Total total_sum-m-T"][key] == "%nan"){
                    responseOther.data["Total total_sum-m-T"][key] = "0"
                }
            })
            console.log("After filtering", response.data);
            setTotalCalculation({...response.data, ...responseOther.data})
            

            Array.isArray(tableMeasurable) && setTableMeasurable(prev => ([...prev, { ...response.data, Measurable: "Total", ...responseOther.data["Total total_sum-m-T"] }]))
            Array.isArray(tableCampaign) && setTableCampaign(prev => ([...prev, { ...response.data, "Campaign Objective": "Total", ...responseOther.data["Total total_sum-m-T"] }]))
            Array.isArray(tableWeek) && setTableWeek(prev => ([...prev, { ...response.data, Week: "Total", ...responseOther.data["Total total_sum-m-T"] }]))
            Array.isArray(tableBrand) && setTableBrand(prev => ([...prev, { ...response.data, "Brand": "Total", ...responseOther.data["Total total_sum-m-T"] }]))
            Array.isArray(tableInventory) && setTableInventory(prev => ([...prev, { ...response.data, "Inventory Type": "Total", ...responseOther.data["Total total_sum-m-T"] }]))
            Array.isArray(tablePlatform) && setTablePlatform(prev => ([...prev, { ...response.data, "Platform": "Total", ...responseOther.data["Total total_sum-m-T"] }]))
            Array.isArray(tableDataTargeting) && setTableDataTargeting(prev => ([...prev, { ...response.data, "Data Targeting": "Total", ...responseOther.data["Total total_sum-m-T"] }]))
            Array.isArray(tableSize) && setTableSize(prev => ([...prev, { ...response.data, "Size/Length": "Total", ...responseOther.data["Total total_sum-m-T"] }]))
            setLoading(false);

        }
        catch (error) {
            setLoading(false);

            console.log("Error");
        }





    }

    const handlePrint = useReactToPrint({
        content: () => auditSheetRef.current,
      });

    useEffect(() => {
        console.log("Bro", resultsCount);
        if (resultsCount == 35) {
            getTotal();
        }
    }, [resultsCount])

    const getStylingForPrint = () => {
        return `
        @media print{
            .audit-sheet-container{
                width: max-content !important;
            }
            .divForPrint{
                width: max-content !important; 
            }
            @page{
                margin: 50px !important;
                size: tabloid landscape;
            }

        }
        `
    }

    return (
        <div className='audit-sheet-container'>
            <style>{getStylingForPrint()}</style>
            <h1 className='audit-h1'><span>Full Audit Sheet:</span></h1>
            <Link to="/dashboard/analysis/auditsheet" state={{ csvData: csvData }}>
                <button className='btn_hover font-semibold py-2 px-4 mb-2 text-center bg-custom_primary text-white rounded-xl '>Custom Audit Sheet</button>
            </Link>
                
                <Link to="/dashboard/datavisualization/datavisualization1" state={{ csvData: location?.state?.csvData, totalCalculation: totalCalculation }}>
                    <button className='btn_hover font-semibold py-2 px-4 mb-2 text-center bg-custom_primary text-white rounded-xl ml-2 '>Visualization</button>
                </Link>
                <button className='btn_hover font-semibold py-2 px-4 mb-2 text-center bg-custom_primary text-white rounded-xl ml-2 ' onClick={handlePrint}>Download</button>

            {loading ?
                <p>Loading...</p>

                :

                <div className="divForPrint" ref={auditSheetRef}>

                    <div className='border'>
                        <Table dataSource={tableMeasurable} columns={[{
                            title: 'Measurable',
                            dataIndex: 'Measurable',
                            key: 'Measurable',
                            width: 200
                        }, , ...columns]} />
                    </div>
                    <div className='border'>
                        <Table dataSource={tableCampaign} columns={[{
                            title: 'Campaign Objective',
                            dataIndex: 'Campaign Objective',
                            key: 'Campaign Objective',
                            width: 200
                        }, ...columns]} />
                    </div>
                    <div className='border'>
                        <Table dataSource={tableWeek} columns={[{
                            title: 'Week',
                            dataIndex: 'Week',
                            key: 'Week',
                            width: "80%",
                            width: 200
                        }, ...columns]} />
                    </div>
                    <div className='border'>
                        <Table dataSource={tableBrand} columns={[{
                            title: 'Brand',
                            dataIndex: 'Brand',
                            key: 'Brand',
                            width: 200
                        }, ...columns]} />
                    </div>
                    <div className='border'>
                        <Table dataSource={tableInventory} columns={[{
                            title: 'Inventory Type',
                            dataIndex: 'Inventory Type',
                            key: 'Inventory Type',
                            width: 200
                        }, ...columns]} />
                    </div>
                    <div className='border'>
                        <Table dataSource={tablePlatform} columns={[{
                            title: 'Platform',
                            dataIndex: 'Platform',
                            key: 'Platform',
                            width: 200
                        }, ...columns]} />
                    </div>
                    <div className='border'>
                        <Table dataSource={tableDataTargeting} columns={[{
                            title: 'Data Targeting',
                            dataIndex: 'Data Targeting',
                            key: 'Data Targeting',
                            width: 200
                        }, ...columns]} />
                    </div>
                    <div className='border'>
                        <Table dataSource={tableSize} columns={[{
                            title: 'Size',
                            dataIndex: 'Size/Length',
                            key: 'Size/Length',
                            width: 200
                        }, ...columns]} />
                    </div>
                </div>
            }

        </div>
    )
}

export default AggregateSheet