import React, { useState, useEffect, useContext } from 'react'
import { Table } from 'antd';
import '../../../../../components/DataTables/DataTables.css'
import { Link, useLocation } from 'react-router-dom';
import Papa from 'papaparse';
import { SavedFileContext } from '../../../../../../context/SaveedFileContext';
import axios from 'axios';


const metaMapping = `Sr No,Orignal Column,Meta Mapping,Updated Column
1,Reporting starts,Mapped To,Week/Day
2,Objective,Mapped To,Campaign Objective
3,Platform,Mapped To,Platform
4,Ad Name,Mapped To,Ad Name
5,Ad Set Name,Mapped To,Ad Set Name
6,Delivery,Mapped To,Status
7,Ad ID,Mapped To,Placement ID
8,Device,Mapped To,Device
9,Country,Mapped To,Geo
10,Gender,Mapped To,Demo
11,Amount Spent,Mapped To,Net Spend
12,Purchases conversion value,Mapped To,Revenue
13,Impressions,Mapped To,Platform Impressions
14,Frequency,Mapped To,Frequency
15,Clicks(all),Mapped To,Clicks
16,Video Plays,Mapped To,Video Views
17,Video Plays at 100%,Mapped To,Video Completions
18,Landing Page Views,Mapped To,Landing Page Visits
19,Adds To Cart,Mapped To,Add To Cart
20,Leads,Mapped To,Leads
21,Purchases ,Mapped To,Purchases`

const tiktokMapping = `Sr No,Orignal Column,Tiktok Mapping,Updated Column
1,By Day,Mapped to,Day / Week
2,Optimization Goal,Mapped to,Campaign Objective
3,Placement,Mapped to,Platform
4,Ad Name,Mapped to,Ad Name
5,Ad Group Name,Mapped to,Ad Set Name
6,Total Cost,Mapped to,Net Spend
7,Total Purchase Value,Mapped to,Revenue
8,Impression,Mapped to,Platform Impressions
9,Frequency,Mapped to,Frequency
10,Clicks (Destination),Mapped to,Clicks
11,Video Views,Mapped to,Video Views
12,Video Views at 100%,Mapped to,Total Completions
13,Conversions,Mapped to,Conversions
14,Total Landing Page View,Mapped to,Landing Page Visits
15,Total add-to cart,Mapped to,Add To Cart
`
const CombinedAggregate = () => {

    const [csvData, setCsvData] = useState("")
    const [tableColumn, setTableColumn] = useState("")
    const [tableData, setTableData] = useState("")
    const { currentMapping, setCurrentMapping, fileForChat, setFileForChat, currentlyMappedFile, setCurrentlyMappedFile } = useContext(SavedFileContext)
    const [filteredDataForDownload, setFilteredDataForDownload] = useState("")


    const [csvMapped, setCsvMapped] = useState("")
    const [loading, setLoading] = useState(false);

    const location = useLocation()
    // const attributes = ["Day", "Client", "Brand", "Campaign Objective", "Platform", "Ad Name", "Ad Set Name", "Status", "Placement ID", "Inventory Type", "Device", "Format", "Size/Length", "Geo", "Demo", "Data Targeting", "Measurable Imps", "Net Spend", "Revenue", "Platform Impressions", "Frequency", "Viewable Impressions", "Monitored Imps (Fraud)", "Clicks", "Video Views", "Video Completions", "Unique Reach Impressions", "Fraudulent Impression", "Conversions", "Landing Page Visits", "Add To Cart", "Leads", "Purchases", "Fraud Rate %", "Non Fraudulent Impressions", "Reach", "Nonviewable Impressions", "Viewability Rate %", "Unmeasured Impressions"]
    // const attributes = ["Week","Day", "Client", "Brand", "Campaign Objective", "Platform", "Ad Name", "Ad Set Name", "Status", "Placement ID", "Inventory Type", "Device", "Format", "Size/Length", "Geo", "Demo", "Data Targeting", "Measurable Imps", "Net Spend", "Revenue", "Platform Impressions", "Frequency", "Viewable Impressions", "Monitored Imps (Fraud)", "Clicks", "Video Views", "Video Completions", "Unique Reach Impressions", "Fraudulent Impression", "Conversions", "Landing Page Visits", "Add To Cart", "Leads", "Purchases", "Fraud Rate %", "Non Fraudulent Impressions", "Reach", "Nonviewable Impressions", "Viewability Rate %", "Unmeasured Impressions", "Frequency", "eCPM", "CTR", "CPC", "ROAS", "Viewability", "Fraud", "Exceeded Format", "eCPM Goal Comparison", "CTR Goal Comparison", "Viewability Goal Comparison", "Fraud Goal Comparison", "Frequency Goal Comparison", "Meet All Conditions", "Working Media", "Non Working Media"]
    // let attributes = ["Day", "Week","Client", "Brand", "Campaign Objective", "Platform", "Ad Name", "Ad Set Name","Format", "Status", "Placement ID", "Inventory Type", "Device", "Format", "Size/Length", "Geo", "Demo", "Data Targeting", "Net Spend", "Revenue", "Platform Impressions", "Frequency", "Measurable Imps", "Viewable Impressions", "Monitored Imps (Fraud)", "Fraudulent Impression", "Clicks", "Video Views", "Video Completions", "Unique Reach Impressions", "Conversions", "Landing Page Visits", "Add To Cart", "Leads", "Purchases", "Reach", "ROAS", "Nonviewable Impressions", "Non Fraudulent Impressions", "Viewability Rate %", "Fraud Rate %", "Exceeded Format", "Display CPM", "Video CPM", "eCPM Goal Comparison", "CTR Goal Comparison", "Viewability Goal Comparison", "Fraud Goal Comparison", "Frequency Goal Comparison", "Meet All Conditions", "Non Working Media","Working Media", "Waste Impressions", "Working Impressions", "eCPM", "CTR", "CPC", "Unmeasured Impressions (Viewability & Fraud)", "Qualified Cost Per Thousand (qCPM)", "Unique Reach %"];
    // let attributes = ["Day", "Week","Client", "Brand", "Campaign Objective", "Platform", "Ad Name", "Ad Set Name","Format", "Status", "Placement ID", "Inventory Type", "Device", "Format", "Size/Length", "Geo", "Demo", "Data Targeting", "Net Spend", "Revenue", "Platform Impressions", "Frequency", "Measurable Imps", "Viewable Impressions", "Monitored Imps (Fraud)", "Fraudulent Impression", "Clicks", "Video Views", "Video Completions", "Unique Reach Impressions", "Conversions", "Landing Page Visits", "Add To Cart", "Leads", "Purchases", "Reach", "ROAS", "Nonviewable Impressions", "Non Fraudulent Impressions", "Viewability", "Fraud", "Exceeded Format", "Display CPM", "Video CPM", "eCPM Goal Comparison", "CTR Goal Comparison", "Viewability Goal Comparison", "Fraud Goal Comparison", "Frequency Goal Comparison", "Meet All Conditions", "Non Working Media","Working Media", "Waste Impressions", "Working Impressions", "eCPM", "CTR", "CPC", "Unmeasured Impressions (Viewability & Fraud)", "Qualified Cost Per Thousand (qCPM)", "Unique Reach %"];
    let attributes = ["Day", "Week","Client", "Brand", "Campaign Name", "Campaign Objective", "Platform", "Ad Group Name", "Advertiser Country", "Inventory Type", "Ad Name", "Ad Group Audience", "Ad Set Name","Format", "Status", "Placement ID", "Device", "Format", "Size/Length", "Geo", "Demo", "Data Targeting", "Net Spend", "Revenue", "Platform Impressions", "Frequency", "Ad Frequency", "Measurable Imps", "Viewable Impressions", "Monitored Imps (Fraud)", "Fraudulent Impression", "Impressions", "Clicks", "Video Views", "Video Completions", "Unique Reach Impressions", "Conversions", "Landing Page Visits", "Add To Cart", "Leads", "Purchases", "Reach", "ROAS", "Nonviewable Impressions", "Non Fraudulent Impressions", "Viewability", "Fraud", "Exceeded Format", "Display CPM", "Video CPM", "eCPM Goal Comparison", "CTR Goal Comparison", "Viewability Goal Comparison", "Fraud Goal Comparison", "Frequency Goal Comparison", "Meet All Conditions", "Waste Media","Working Media", "Waste Impressions", "Working Impressions", "eCPM", "CTR", "CPC", "Unmeasured Impressions (Viewability & Fraud)", "Qualified Cost Per Thousand (qCPM)", "Unique Reach %"];


    const metaMapping = async () => {
        console.log("Extra Multiple File", location);
        if (location?.state?.csvDataFromGoals) {           
            setLoading(true);
             setCsvMapped(location?.state?.csvDataFromGoals)
             setFileForChat(location?.state?.csvDataFromGoals)

try{


            Papa.parse(location?.state?.csvDataFromGoals, {
                header: true,
                dynamicTyping: true,
                complete: (result) => {
                    console.log("Upload Table Nehal bhi",result);
                                        
                    // setFilteredDataForDownload(result.data.map((allField) => {
                    //     let allF = allField
                    //     let forbiddenList = Object.keys(allField).filter((oneKey) => (
                    //         !attributes.includes(oneKey)
                    //     ))
                    //     // console.log("hehe", forbiddenList);
                    //     forbiddenList.forEach(e => delete allF[e]);
                    //     return allF
                    // }))
                    
                    setCsvData(result.data)
                    setTableColumn(Object.keys(result.data[0]).sort((a, b) => {
                        let indexA = attributes.indexOf(a);
                        let indexB = attributes.indexOf(b);
                        
                        // If both elements are found in the attributes, compare their positions
                        if (indexA !== -1 && indexB !== -1) {
                            return indexA - indexB;
                        }
                        // If only one element is found in the attributes, prioritize it
                        else if (indexA !== -1) {
                            return -1;
                        }
                        else if (indexB !== -1) {
                            return 1;
                        }
                        // If neither element is found in the attributes, maintain their original order
                        else {
                            return 0;
                        }
                    }).map((csvColumnName) => {
                        console.log(csvColumnName + " ----- " + attributes.includes(csvColumnName));
                        return (
                        attributes.includes(csvColumnName) ? (csvColumnName == "Fraud"|| csvColumnName == "Viewability") ? {title: csvColumnName + " Rate %", dataIndex: csvColumnName} : { title: csvColumnName, dataIndex: csvColumnName } : {}
                    )}).filter(value => Object.keys(value).length !== 0))
                    setFilteredDataForDownload(result.data.map((allField) => {
                        let allF = allField
                        let forbiddenList = Object.keys(allField).filter((oneKey) => (
                            !attributes.includes(oneKey)
                        ))
                        // console.log("hehe", forbiddenList);
                        forbiddenList.forEach(e => delete allF[e]);
                        return allF
                    }))
                    setLoading(false);

                },
            });
        }
        catch(e){
            setLoading(false);

        }

        }
        else{

        
            setLoading(true);

            console.log("location", location.state);
            setTableData(location.state.csvData)
            setCsvMapped(location.state.csvData)
            setFileForChat(location.state.csvData)

            const blob = new Blob([location.state.csvData], { type: 'text/csv' });
            let data = new FormData();
            data.append('csv_file', blob);
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://mywork.techrecipes.com/calculate-exter-metrics/',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: data
            };
            const withCtrAndOther = await axios.request(config);
            console.log("WithCtr", withCtrAndOther.data);
            // setCsvMapped(response.data)
            setCsvMapped(withCtrAndOther.data)
            setFileForChat(withCtrAndOther.data)

            Papa.parse(withCtrAndOther.data, {
                header: true,
                dynamicTyping: true,
                complete: (result) => {
                    console.log("Upload Table Nehal bhi",result);
                                        
                    // setFilteredDataForDownload(result.data.map((allField) => {
                    //     let allF = allField
                    //     let forbiddenList = Object.keys(allField).filter((oneKey) => (
                    //         !attributes.includes(oneKey)
                    //     ))
                    //     // console.log("hehe", forbiddenList);
                    //     forbiddenList.forEach(e => delete allF[e]);
                    //     return allF
                    // }))
                    
                    setCsvData(result.data)
                    setTableColumn(Object.keys(result.data[0]).sort((a, b) => {
                        let indexA = attributes.indexOf(a);
                        let indexB = attributes.indexOf(b);
                        
                        // If both elements are found in the attributes, compare their positions
                        if (indexA !== -1 && indexB !== -1) {
                            return indexA - indexB;
                        }
                        // If only one element is found in the attributes, prioritize it
                        else if (indexA !== -1) {
                            return -1;
                        }
                        else if (indexB !== -1) {
                            return 1;
                        }
                        // If neither element is found in the attributes, maintain their original order
                        else {
                            return 0;
                        }
                    }).map((csvColumnName) => {
                        console.log(csvColumnName + " ----- " + attributes.includes(csvColumnName));
                        return (
                        attributes.includes(csvColumnName) ? (csvColumnName == "Fraud"|| csvColumnName == "Viewability") ? {title: csvColumnName + " Rate %", dataIndex: csvColumnName} : { title: csvColumnName, dataIndex: csvColumnName } : {}
                    )}).filter(value => Object.keys(value).length !== 0))
                    setFilteredDataForDownload(result.data.map((allField) => {
                        let allF = allField
                        let forbiddenList = Object.keys(allField).filter((oneKey) => (
                            !attributes.includes(oneKey)
                        ))
                        // console.log("hehe", forbiddenList);
                        forbiddenList.forEach(e => delete allF[e]);
                        return allF
                    }))
                    setLoading(false);
                            
        
                            
                       

                },
            });
            setLoading(false);

            // setCsvData(location.state.csvData);
            // setTableColumn(Object.keys(location.state.csvData[0]).map((csvColumnName) => (
            //     { title: csvColumnName, dataIndex: csvColumnName }
            // )))
            
        }
    }

    useEffect(  () => {
        if (location.state.csvData || location.state.csvDataFromGoals){
            metaMapping();
        }
    }, [])

    const dataa = [
        {
            title: "jsaksa",
            dataIndex: "jsaksa"
        },
        {
            title: "hehe",
            dataIndex: "hehe"
        },
        {
            title: "bro",
            dataIndex: "bro"
        }
    ]

    const hehe = [
        {
            jsaksa: "qasd",
            hehe: "q",
            bro: "qwertyuiop"
        },

    ]
    // const attributes = [
    //     "Week",
    //     "Client",
    //     "Brand",
    //     "Campaign Objective",
    //     "Platform/Vendor",
    //     "Placement Name",
    //     "Ad Group",
    //     "Status",
    //     "Placement ID",
    //     "Device",
    //     "Format",
    //     "Size",
    //     "Geo",
    //     "Demo",
    //     "Data Targeting",
    //     "Measurable",
    //     "Reach",
    //     "Net Spend",
    //     "Revenue",
    //     "Impressions",
    //     "Viewable Impressions",
    //     "Viewability Rate %",
    //     "Unmeasured Impressions",
    //     "Clicks",
    //     "Video Views",
    //     "Video Completions",
    //     "Unique Reach",
    //     "Non Fraudulent Impressions",
    //     "Fraudulent Impression",
    //     "Fraud Rate %",
    //     "Conversion Count",
    //     "Product View",
    //     "Add To Cart",
    //     "Leads"
    // ]
    const [oldColumnName, setOldColumnName] = useState("");
    const [newColumnName, setNewColumnName] = useState("");

    const handleDownload = () => {

        console.log("CSV", csvData);
        const csv = Papa.unparse(csvData);
        var csvDataBlob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        var csvURL = null;
        if (navigator.msSaveBlob) {
            csvURL = navigator.msSaveBlob(csvDataBlob, 'download.csv');
        }
        else {
            csvURL = window.URL.createObjectURL(csvDataBlob);
        }

        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', "result" + '.csv');
        tempLink.click();
    }

    const handleColumnNameChange = () => {

        let csv = Papa.unparse(csvData);
        console.log("hehe boy old", csv);


        // console.log("Data Index", columnDataIndex);

        let newCsv = csv.replace(`${oldColumnName}`, newColumnName)
        setTableData(newCsv)

        Papa.parse(newCsv, {
            header: true,
            dynamicTyping: true,
            complete: (result) => {
                console.log("new data", result.data);
                setCsvData(result.data)
                setTableColumn(Object.keys(result.data[0]).map((csvColumnName) => (
                    { title: csvColumnName, dataIndex: csvColumnName }
                )))


            },
        });


    }
    useEffect(() => {
        console.log("hehe", tableData);
    }, [tableData])
    const [currentMappingDetails, setCurrentMappingDetails] = useState("");
    const [currentMappingColumn, setCurrentMappingColumn] = useState("");

    const showMappingDetails = () => {
        if (currentMappingDetails) {
            setCurrentMappingDetails("")
        }
        else {
            let currentDetailsToShow = metaMapping;
            if (currentMapping == "meta") {
                currentDetailsToShow = metaMapping
            }
            else if (currentMapping == "tiktok") {
                currentDetailsToShow = tiktokMapping
            }

            Papa.parse(currentDetailsToShow, {
                header: true,
                dynamicTyping: true,
                complete: (result) => {
                    console.log("new data", result.data);
                    setCurrentMappingDetails(result.data)
                    setCurrentMappingColumn(Object.keys(result.data[0]).map((csvColumnName) => (
                        { title: csvColumnName, dataIndex: csvColumnName }
                    )))
                },
            });
        }
    }

    return (
        <div>
            <h1 style={{ fontWeight: "700", fontSize: "30px", marginTop: "20px", marginBottom: "20px" }}>{currentMapping == "other" ? "Aggregated Sheet" : "Combined Aggregated Sheet"}</h1>
            <div style={{ width: "900px" }}>
                <button onClick={handleDownload} style={{ backgroundColor: "#374151", color: "white", borderRadius: "5px", padding: "4px 10px", marginBottom: "20px" }}>Download</button>

            </div>
            {location?.state?.csvDataFromGoals ?
                <div style={{ width: "900px" }}>
                    <Link to="/dashboard/analysis/aggregateSheet" state={{ csvData: filteredDataForDownload ? Papa.unparse(filteredDataForDownload): csvMapped }}>
                        <button style={{ backgroundColor: "#374151", color: "white", borderRadius: "5px", padding: "4px 10px", marginBottom: "20px" }}>Audit Sheet</button>
                    </Link>
                </div>
                :
                ""
            }

            <div style={{ width: "900px" }}>
                <Link to="/dashboard/analysis/goals" state={{ csvData: csvMapped }}>
                    <button style={{ backgroundColor: "#374151", color: "white", borderRadius: "5px", padding: "4px 10px", marginBottom: "20px" }}>Set Goals</button>
                </Link>
            </div>

{loading ? 
"loading..."
:

            <Table dataSource={csvData} columns={tableColumn} />
}

        </div>
    )
}

export default CombinedAggregate;