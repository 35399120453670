import React, { useState, useEffect, useContext } from 'react'
import { Table } from 'antd';
import '../../../components/DataTables/DataTables.css'
import { Link, useLocation } from 'react-router-dom';
import Papa from 'papaparse';
import { SavedFileContext } from '../../../../context/SaveedFileContext';
import { toast } from 'react-toastify';

const metaMapping = `Sr No,Orignal Column,Meta Mapping,Updated Column
1,Reporting starts,Mapped To,Week/Day
2,Objective,Mapped To,Campaign Objective
3,Platform,Mapped To,Platform
4,Ad Name,Mapped To,Ad Name
5,Ad Set Name,Mapped To,Ad Set Name
6,Delivery,Mapped To,Status
7,Ad ID,Mapped To,Placement ID
8,Device,Mapped To,Device
9,Country,Mapped To,Geo
10,Gender,Mapped To,Demo
11,Amount Spent,Mapped To,Net Spend
12,Purchases conversion value,Mapped To,Revenue
13,Impressions,Mapped To,Platform Impressions
14,Frequency,Mapped To,Frequency
15,Clicks(all),Mapped To,Clicks
16,Video Plays,Mapped To,Video Views
17,Video Plays at 100%,Mapped To,Video Completions
18,Landing Page Views,Mapped To,Landing Page Visits
19,Adds To Cart,Mapped To,Add To Cart
20,Leads,Mapped To,Leads
21,Purchases ,Mapped To,Purchases
22,Data Targeting,Mapped To,Data Targeting
23,Delivery,Mapped To,Status
24,Unique Reach Impressions,Mapped To,Unique Reach Impressions
25,Brand,Mapped To,Brand
26,Client,Mapped To,Client`

const tiktokMapping = `Sr No,Orignal Column,Tiktok Mapping,Updated Column
1,By Day,Mapped to,Day / Week
2,Optimization Goal,Mapped to,Campaign Objective
3,Placement,Mapped to,Platform
4,Ad Name,Mapped to,Ad Name
5,Ad Group Name,Mapped to,Ad Set Name
6,Cost,Mapped to,Net Spend
7,Total Purchase Value,Mapped to,Revenue
8,Impressions,Mapped to,Platform Impressions
9,Frequency,Mapped to,Frequency
10,Clicks (Destination),Mapped to,Clicks
11,Video Views,Mapped to,Video Views
12,Video Views at 100%,Mapped to,Total Completions
13,Conversions,Mapped to,Conversions
14,Total Landing Page View,Mapped to,Landing Page Visits
15,Total add-to cart,Mapped to,Add To Cart
16,Data Targeting,Mapped to,Data Targeting
17,Leads,Mapped to,Leads
18,Status,Mapped to,Status
19,Geo,Mapped to,Geo
20,Unique Reach Impressions,Mapped to,Unique Reach Impressions
21,Device,Mapped to,Device
22,Brand,Mapped to,Brand
23,Client,Mapped to,Client`

const ttdMapping = `Sr No,Orignal Column,TTD Mapping,Updated Column
1,Date,Mapped to,Day
2,Advertiser,Mapped to,Client
3,Ad Group Name,Mapped to,Campagin Objective
4,Ad Group,Mapped to,Ad Name
5,Ad Group ID,Mapped to,Placement ID
6,Inventory contract Type,Mapped to,Inventory type
7,Device Type,Mapped to,Device
8,Ad Format,Mapped to,Format
9,Country,Mapped to,Geo
10,IAS video Viewable 25% completed Rate,Mapped to,Measurable Imps
11,Reach,Mapped to,Frequency
12,Clicks,Mapped to,Clicks
13,Total Views,Mapped to,Video Views
14,Player completed views,Mapped to,Video Completions
15,Unique Reach,Mapped to,Unique Reach Impressions
16,Click Conversion,Mapped to,Conversions
17,Impressions,Mapped to,Platform Impressions
18,Platform/Vender,Mapped to,Platform
19,Advertiser Cost,Mapped to,Net Spend
20,Total Clicks + View Conversion Revenue,Mapped to,Revenue
21,Day,Mapped to,Week
22,Data Targeting,Mapped to,Data Targeting
23,Leads,Mapped to,Leads
24,Status,Mapped to,Status
25,Add To Cart,Mapped to,Add To Cart
26,Brand,Mapped to,Brand`
const gptMapping = `Sr No,Orignal Column,GPT Mapping,Updated Column
1,Day,Mapped to,Day
2,Client,Mapped to,Client
3,Brand,Mapped to,Brand
4,Campaign Objective,Mapped to,Campaign Objective
5,Platform,Mapped to,Platform
6,Week,Mapped to,Week
7,Ad Name,Mapped to,Ad Name
8,Ad Set Name,Mapped to,Ad Set Name
9,Status,Mapped to,Status
10,Placement ID,Mapped to,Placement ID
11,Inventory Type,Mapped to,Inventory Type
12,Device,Mapped to,Device
13,Format,Mapped to,Format
14,Size/Length,Mapped to,Size/Length
15,Geo,Mapped to,Geo
16,Demo,Mapped to,Demo
17,Data Targeting,Mapped to,Data Targeting
18,Measurable Imps,Mapped to,Measurable Imps
19,Net Spend,Mapped to,Net Spend
20,Revenue,Mapped to,Revenue
21,Platform Impressions,Mapped to,Platform Impressions
22,Frequency,Mapped to,Frequency
23,Viewable Impressions,Mapped to,Viewable Impressions
24,Monitored Imps (Fraud),Mapped to,Monitored Imps (Fraud)
25,Clicks,Mapped to,Clicks
26,Video Views,Mapped to,Video Views
27,Video Completions,Mapped to,Video Completions
28,Unique Reach Impressions,Mapped to,Unique Reach Impressions
29,Fraudulent Impression,Mapped to,Fraudulent Impression
30,Conversions,Mapped to,Conversions
31,Landing Page Visits,Mapped to,Landing Page Visits
32,Add To Cart,Mapped to,Add To Cart
33,Leads,Mapped to,Leads
34,Purchases,Mapped to,Purchases
35,Fraud Rate %,Mapped to,Fraud Rate %
36,Non Fraudulent Impressions,Mapped to,Non Fraudulent Impressions
37,Reach,Mapped to,Reach
38,Nonviewable Impressions,Mapped to,Nonviewable Impressions
39,Viewability Rate %,Mapped to,Viewability Rate %
40,Unmeasured Impressions,Mapped to,Unmeasured Impressions`
const UploadTable = () => {

    const [csvData, setCsvData] = useState("")
    const [tableColumn, setTableColumn] = useState("")
    const [tableData, setTableData] = useState("")
    const { currentMapping, setCurrentMapping, gptHeaders, setGptHeaders ,gptHeadersOld, setGptHeadersOld } = useContext(SavedFileContext)
    const location = useLocation()

    useEffect(()=>{
console.log("hehe", gptHeaders);
console.log("heheOld", gptHeadersOld);
// console.log("let's see ",gptHeaders.map((newHeader, index)=>({"Orignal Column": gptHeadersOld[index], "GPT Mapping": "Mapped to", "Updated Column": newHeader})));
    },[gptHeaders, gptHeadersOld])

    useEffect(() => {
        console.log("Extra Multiple File", location);
        if (location?.state?.csvData) {

            console.log("location", location.state);
            setTableData(location.state.csvData)
            Papa.parse(location?.state?.csvData, {
                header: true,
                dynamicTyping: true,
                complete: (result) => {
                    console.log("Upload Table Nehal bhi",result);
                    setCsvData(result.data)
                    setTableColumn(Object.keys(result.data[0]).map((csvColumnName) => (
                        { title: csvColumnName, dataIndex: csvColumnName }
                    )))

                },
            });
            // setCsvData(location.state.csvData);
            // setTableColumn(Object.keys(location.state.csvData[0]).map((csvColumnName) => (
            //     { title: csvColumnName, dataIndex: csvColumnName }
            // )))
        }
    }, [])

    const dataa = [
        {
            title: "jsaksa",
            dataIndex: "jsaksa"
        },
        {
            title: "hehe",
            dataIndex: "hehe"
        },
        {
            title: "bro",
            dataIndex: "bro"
        }
    ]

    const hehe = [
        {
            jsaksa: "qasd",
            hehe: "q",
            bro: "qwertyuiop"
        },

    ]
    // const attributes = [
    //     "Week",
    //     "Client",
    //     "Brand",
    //     "Campaign Objective",
    //     "Platform/Vendor",
    //     "Placement Name",
    //     "Ad Group",
    //     "Status",
    //     "Placement ID",
    //     "Device",
    //     "Format",
    //     "Size",
    //     "Geo",
    //     "Demo",
    //     "Data Targeting",
    //     "Measurable",
    //     "Reach",
    //     "Net Spend",
    //     "Revenue",
    //     "Impressions",
    //     "Viewable Impressions",
    //     "Viewability Rate %",
    //     "Unmeasured Impressions",
    //     "Clicks",
    //     "Video Views",
    //     "Video Completions",
    //     "Unique Reach",
    //     "Non Fraudulent Impressions",
    //     "Fraudulent Impression",
    //     "Fraud Rate %",
    //     "Conversion Count",
    //     "Product View",
    //     "Add To Cart",
    //     "Leads"
    // ]
    // const attributes = ["Day", "Client", "Brand", "Campaign Objective", "Platform", "Ad Name", "Ad Set Name", "Status", "Placement ID", "Inventory Type", "Device", "Format", "Size/Length", "Geo", "Demo", "Data Targeting", "Measurable Imps", "Net Spend", "Revenue", "Platform Impressions", "Frequency", "Viewable Impressions", "Monitored Imps (Fraud)", "Clicks", "Video Views", "Video Completions", "Unique Reach Impressions", "Fraudulent Impression", "Conversions", "Landing Page Visits", "Add To Cart", "Leads", "Purchases", "Fraud Rate %", "Non Fraudulent Impressions", "Reach", "Nonviewable Impressions", "Viewability Rate %", "Unmeasured Impressions"]
    const attributes = ["Day", "Week","Client", "Brand", "Campaign Objective", "Platform", "Ad Name", "Ad Set Name", "Inventory Type", "Device", "Format", "Size/Length", "Geo", "Demo", "Data Targeting", "Measurable Imps", "Net Spend", "Revenue", "Platform Impressions", "Frequency", "Viewable Impressions", "Clicks", "Video Views", "Video Completions", "Unique Reach Impressions", "Fraudulent Impression", "Conversions", "Landing Page Visits", "Fraud Rate %", "Non Fraudulent Impressions", "Reach", "Nonviewable Impressions", "Viewability Rate %"]
    const [oldColumnName, setOldColumnName] = useState("");
    const [newColumnName, setNewColumnName] = useState("");

    const handleDownload = () => {

        console.log("CSV", csvData);
        const csv = Papa.unparse(csvData);
        var csvDataBlob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        var csvURL = null;
        if (navigator.msSaveBlob) {
            csvURL = navigator.msSaveBlob(csvDataBlob, 'download.csv');
        }
        else {
            csvURL = window.URL.createObjectURL(csvDataBlob);
        }

        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', "result" + '.csv');
        tempLink.click();
    }

    const handleColumnNameChange = () => {

        let csv = Papa.unparse(csvData);
        console.log("hehe boy old", csv);


        // console.log("Data Index", columnDataIndex);

        let newCsv = csv.replace(`${oldColumnName}`, newColumnName)
        console.log("Old Index", oldColumnName);
        console.log("New Index", newColumnName);
        console.log("Data Index", newCsv);

        setTableData(newCsv)

        Papa.parse(newCsv, {
            header: true,
            dynamicTyping: true,
            complete: (result) => {
                console.log("new data", result.data);
                setCsvData(result.data)
                setTableColumn(Object.keys(result.data[0]).map((csvColumnName) => (
                    { title: csvColumnName, dataIndex: csvColumnName }
                )))

                toast.success(`${oldColumnName} successfully renamed to ${newColumnName}`)
            },
        });
        // showMappingDetails()
        setCurrentMappingDetails(false)
        if (currentMapping == "other"){

            setGptHeaders(prev=>{
                return Array.isArray(prev) ? prev.map((newHeader)=>(newHeader == oldColumnName ? newColumnName : newHeader)) : prev
            })
        }

    }
    useEffect(() => {
        console.log("hehe", tableData);
    }, [tableData])
    const [currentMappingDetails, setCurrentMappingDetails] = useState("");
    const [currentMappingColumn, setCurrentMappingColumn] = useState("");
    const [currentNotMappingDetails, setCurrentNotMappingDetails] = useState("");

    const showMappingDetails = () => {
        console.log("currentMapping ", currentMapping);
        console.log("currentMappingDetails ", currentMappingDetails);
        if (currentMappingDetails) {
            setCurrentMappingDetails("")
            return
        }
        else {
            let currentDetailsToShow = metaMapping;
            if (currentMapping == "meta") {
                currentDetailsToShow = metaMapping
            }
            else if (currentMapping == "tiktok") {
                currentDetailsToShow = tiktokMapping
            }
            else if (currentMapping == "ttd") {
                currentDetailsToShow = ttdMapping;

            }
            else if (currentMapping == "other") {
                // currentDetailsToShow = gptMapping;
                const gptHeadersArray = [ "Day", "Client", "Brand", "Campaign", "Platform", "Week", "Ad Name", "Ad Set Name", "Status", "Placement", "Inventory", "Device", "Format", "Size/Length", "Geo", "Demo", "Data Target", "Measurable", "Net Spend", "Revenue", "Platform ID", "Frequency", "Viewable", "Monitored", "Clicks", "Video Views", "Video Complete", "Unique Reach", "Fraudulent", "Conversions", "Landing Page", "Add To Cart", "Leads", "Purchases", "Fraud Rate", "Non Fraudulent", "Reach", "Nonviewable", "Viewability", "Unmeasurable" ]
                console.log("In others");
                setCurrentMappingDetails( Array.isArray(gptHeaders) ? gptHeaders.map((newHeader, index)=>({"Orignal Column": gptHeadersOld[index], "GPT Mapping": "Mapped to", "Updated Column": newHeader})) : "")
                setCurrentMappingColumn([{ title: "Orignal Column", dataIndex: "Orignal Column" }, { title: "GPT Mapping", dataIndex: "GPT Mapping" }, { title: "Updated Column", dataIndex: "Updated Column" }])
                // setCurrentNotMappingDetails(gptHeadersArray.filter((header)=>!gptHeaders.includes(header)))
                setCurrentNotMappingDetails(gptHeaders.filter((header)=>!gptHeadersArray.includes(header)))
                return;

            }
            Papa.parse(currentDetailsToShow, {
                header: true,
                dynamicTyping: true,
                complete: (result) => {
                    console.log("new dataxx", result.data.filter((data) => {
                        return location?.state?.csvData.includes(data["Orignal Column"])
                    }));
                    console.log("f", result.data);
                    console.log("fa", location?.state?.csvData);

                    let allFileHeaders;
                    if (gptHeaders) {
                        console.log("yesssss", gptHeaders);
                        allFileHeaders = gptHeaders.headers
                    }
                    else {

                        allFileHeaders = Object.keys(csvData[0]);
                    }
                    // setCurrentMappingDetails(result.data.filter((data) => {
                    //     return location?.state?.csvData.includes(data["Orignal Column"])
                    // }))
                    setCurrentMappingDetails(result.data.filter((data) => {
                        return allFileHeaders.find((headerName) => {
                            // console.log("d", headerName,);
                            // console.log("d", data["Orignal Column"]);
                            // console.log("d", data);
                            // return headerName == data["Orignal Column"]
                            return headerName == data["Orignal Column"] || headerName == data["Updated Column"]
                        }) ? true : false
                    }))
                    setCurrentMappingColumn(Object.keys(result.data[0]).map((csvColumnName) => (
                        { title: csvColumnName, dataIndex: csvColumnName }
                    )))

                    // Before Getting Original Files Headers
                    // setCurrentNotMappingDetails(Object.keys(csvData[0]).map((csvColumnName) => (
                    //     !(result.data.map((data) => {
                    //         return location?.state?.csvData.includes(data["Orignal Column"]) ? data["Orignal Column"] : ""
                    //     }).includes(csvColumnName)) ? csvColumnName : ""
                    // ))
                    // )
                    setCurrentNotMappingDetails(allFileHeaders.map((csvColumnName) => (
                        !(result.data.map((data) => {
                            return location?.state?.csvData.includes(data["Orignal Column"]) ? data["Orignal Column"] : ""
                        }).includes(csvColumnName)) ? csvColumnName : ""
                    ))
                    )

                    console.log("jsakdj", Object.keys(csvData[0]).map((csvColumnName) => (
                        !(result.data.map((data) => {
                            return location?.state?.csvData.includes(data["Orignal Column"]) ? data["Orignal Column"] : ""
                        }).includes(csvColumnName)) ? csvColumnName : ""
                    )));

                },
            });
        }
    }

    return (
        <div>
            <div style={{ width: "900px" }}>
                <button onClick={handleDownload} style={{ backgroundColor: "#374151", color: "white", borderRadius: "5px", padding: "4px 10px", marginBottom: "20px" }}>Download</button>
                <div style={{ display: "flex", flexDirection: "column", gap: "5px", width: "30%" }}>
                    {/* <input type='text' placeholder='Enter Column you want to rename' value={oldColumnName} onChange={(e) => { setOldColumnName(e.target.value) }}></input> */}
                    <select onChange={(e) => { setOldColumnName(e.target.value) }} value={oldColumnName}>
                        {tableColumn && tableColumn.sort(function(a, b) {
   return a.title.localeCompare(b.title);
}).map((table, index) => (
                            <>
                                {index == 0 ? <option value=""> {"Select Columns"} </option> : ""}
                                <option value={table.title}> {table.title} </option>
                            </>
                        ))}
                    </select>
                    <select onChange={(e) => { setNewColumnName(e.target.value) }} value={newColumnName}>
                        {attributes && attributes.sort().map((table, index) => (
                            <>
                                {index == 0 ? <option value=""> {"Select Columns"} </option> : ""}
                                <option value={table}> {table} </option>
                            </>
                        ))}
                    </select>
                    {/* <input type='text' placeholder='Enter New Column Header' value={newColumnName} onChange={(e) => { setNewColumnName(e.target.value) }}></input> */}
                    <button style={{ backgroundColor: "#374151", color: "white", borderRadius: "5px", padding: "4px 10px", marginBottom: "20px" }} onClick={handleColumnNameChange} disabled={newColumnName && oldColumnName ? false : true}>Rename</button>
                </div>
            </div>
            {currentMapping != "fixed" && <button style={{ backgroundColor: "#374151", color: "white", borderRadius: "5px", padding: "4px 10px", marginBottom: "20px" }} onClick={showMappingDetails}> {currentMappingDetails ? "Hide Mapping Details" : "See Mapping Details"}</button>}
            {currentMappingDetails ?
                <div style={{ display: "flex", gap: "20px", marginBottom: "10px" }}>
                    <div style={{ width: "700px" }}>
                        <Table dataSource={currentMappingDetails} columns={currentMappingColumn} />
                    </div>
                    { currentMapping != "other" ?
                        <div style={{ backgroundColor: "white", padding: "15px", borderRadius: "4px" }}>
                        <h1 style={{ fontWeight: "700" }}>Fields not yet mapped</h1>
                        <ul>
                            {currentNotMappingDetails.map((notMappedColumns) => (
                                <li onClick={() => setOldColumnName(notMappedColumns)} style={{ cursor: "pointer" }}>{notMappedColumns}</li>
                            ))}
                        </ul>
                    </div>
                    :
                    <div style={{ backgroundColor: "white", padding: "15px", borderRadius: "4px" }}>
                        <h1 style={{ fontWeight: "700" }}>Fields not yet mapped</h1>
                        <ul>
                            {currentNotMappingDetails.map((notMappedColumns) => (
                                <li style={{ cursor: "pointer" }}>{notMappedColumns}</li>
                            ))}
                        </ul>
                    </div>}
                </div>
                :
                ""}

            <Table dataSource={csvData} columns={tableColumn} />
            <Link to={`${currentMapping == "meta" ? "/dashboard/analysis/meta"
                :
                currentMapping == "tiktok" ?
                    "/dashboard/analysis/tiktok"
                    :
                    currentMapping == "fixed" ?
                        "/dashboard/analysis/fixedaggregate"
                        :
                        currentMapping == "ttd" ?

                            "/dashboard/analysis/ttd"
                            :
                            currentMapping == "other" ?

                                "/dashboard/analysis/aggregate"
                                :
                                ""}`} state={{ csvData: tableData }}>
                <button className='btn_hover font-semibold py-2 px-4 text-center bg-custom_primary text-white rounded-xl'>Next Step</button>
            </Link>
        </div>
    )
}

export default UploadTable