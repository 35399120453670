import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import { useEffect, useState } from "react";

import Dashboard from "./dashboard/Dashboard";
import Result from "./dashboard/components/result/Result";
import ResetPassword from "./components/account/ResetPassword";
import Recovery from "./components/account/Recovery";
import DashboardMain from "./dashboard/components/dashbaord_main/DashboardMain";
import Setting from "./dashboard/components/setting/Setting";
import DataTables from "./dashboard/components/DataTables/DataTables";
import UploadFile from "./dashboard/components/UploadFile/UploadFile";
import { Body } from "./dashboard/components/body/Body";
import SignUp from "./components/account/SignUp";
import App from "./test";
import SingleFileUpload from "./dashboard/components/UploadFile/SingleFileUpload/SingleFileUpload";
import Analysis from "./dashboard/components/UploadFile/Tabs/Analysis/Analysis";
import DataVisualization from "./dashboard/components/UploadFile/Tabs/DataVisualization/DataVisualization";
import NonSturctured from "./dashboard/components/UploadFile/SingleFileUpload/NonSturctured/NonSturctured";
import UploadAgain from "./dashboard/components/UploadFile/SingleFileUpload/UploadAgain/UploadAgain";
import MultipleFileUpload from "./dashboard/components/UploadFile/MultipleFileUpload/MultipleFileUpload";
import UploadTable from "./dashboard/components/UploadFile/UploadTable/UploadTable";
import ExtraMultipleFile from "./dashboard/components/UploadFile/SingleFileUpload/ExtraMultipleFile/ExtraMultipleFile";
import AiPoweredInput from "./dashboard/components/UploadFile/AiPoweredInput";
import UnStructuredPowered from "./dashboard/components/UploadFile/UnStructuredPowered";
import ZipFileUpload from "./dashboard/components/UploadFile/ZipFileUpload/ZipFileUpload";
import ZipSelected from "./dashboard/components/UploadFile/ZipFileUpload/ZipSelected/ZipSelected";
import ExtraInput from "./dashboard/components/UploadFile/ExtraInput";
import Home from "./pages/home";
import AuditSheet from "./dashboard/components/UploadFile/AuditSheet/AuditSheet";
import Meta from "./dashboard/components/UploadFile/Tabs/Analysis/Meta/Meta";
import TikTok from "./dashboard/components/UploadFile/Tabs/Analysis/TikTok/TikTok";
import Fixed from "./dashboard/components/UploadFile/Tabs/Analysis/Fixed/Fixed";
// import Home from "./pages/home";
// import AuditSheet from "./dashboard/components/UploadFile/AuditSheet/AuditSheet";
import { SavedFileContext } from "./context/SaveedFileContext";
import AggregateSheet from "./dashboard/components/UploadFile/aggregateSheet/AggregateSheet";
import TT from "./dashboard/components/UploadFile/Tabs/Analysis/TT/TT";
import Ttd from "./dashboard/components/UploadFile/Tabs/Analysis/Ttd/Ttd";
import CombinedAggregate from "./dashboard/components/UploadFile/Tabs/Analysis/combinedAggregateSheet/CombinedAggregate";
import Goals from "./dashboard/components/UploadFile/Tabs/Analysis/goals/Goals";
import FixedAggregateSheet from "./dashboard/components/UploadFile/Tabs/Analysis/FixedAggregate/FixedAggregateSheet";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from "./components/login/Login";
import Protected from "./components/protectedRoutes/ProtectedRoutes";

const AppLaout = () => {
  const [savedFile, setSavedFile] = useState([]);
  const [currentMapping, setCurrentMapping] = useState("")
  const [gptHeaders, setGptHeaders] = useState(false);
  const [gptHeadersOld, setGptHeadersOld] = useState(false);
  const [goalsValue, setGoalsValue] = useState(false);
  const [currentlyOpened, setCurrentlyOpened] = useState(false)
  const [fileForChat, setFileForChat] = useState(false);
  const [currentlyMappedFile, setCurrentlyMappedFile] = useState("")


  useEffect(() => {
    console.log("Saved Files", savedFile);
  })

  const contextProps = { savedFile, setSavedFile, currentMapping, setCurrentMapping, gptHeaders, setGptHeaders, goalsValue,setGoalsValue, currentlyOpened, setCurrentlyOpened, fileForChat, setFileForChat, currentlyMappedFile, setCurrentlyMappedFile, gptHeadersOld, setGptHeadersOld }

  return (
    <>
      <SavedFileContext.Provider value={{...contextProps}}>
        <RouterProvider router={appRouter} />
        <ToastContainer />
      </SavedFileContext.Provider>
    </>
  );
};

const AppNew = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

const appRouter = createBrowserRouter([
  {
    path: "/dashboard",
    element: <AppNew />,
    children: [
      {
        path: "/dashboard",
        element: <Protected><Dashboard /></Protected>,
        children: [
          {
            path: "home",
            element: <DashboardMain />,
          },
          {
            path: "data",
            element: <DataTables />,
          },
          {
            path: "uploadFile",
            element: <UploadFile />,
            children: [
              {
                path: "singlefileupload",
                element: <SingleFileUpload uploadTxt="Single File Upload" />,
              },
              {
                path: "structure",
                element: <ExtraMultipleFile uploadTxt="Extra Files" />,
              },
              {
                path: "nonsturctured",
                element: <NonSturctured />,
              },
              {
                path: "aipoweredinput",
                element: <AiPoweredInput />,
              },
              {
                path: "unstructuredaipoweredinput",
                element: <UnStructuredPowered />,
              },
              {
                path: "unstructuredaiextrainput",
                element: <ExtraInput />,
              },
              {
                path: "extraupload",
                element: <UploadAgain uploadTxt="Extra upload" />,
              },
              {
                path: "uploadtable",
                element: <UploadTable />,
              },
              {
                path: "extramultiplefile",
                element: <ExtraMultipleFile uploadTxt="Extra Files" />,
              },
              {
                path: "multiplefileupload",
                element: (
                  <MultipleFileUpload uploadTxt="Multiple File Upload" />
                ),
              },
              {
                path: "zipfileupload",
                element: <ZipFileUpload uploadTxt="Zip File Upload" />
              },
              {
                path: "zipfileselected",
                element: <ZipSelected />
              }
            ]
          },
          {
            path: "analysis",
            element: <UploadFile />,
            children: [
              {
                path: "analysis1",
                element:
                  (
                    <div>
                      <Analysis />
                      {/* <Meta /> */}
                    </div>
                  )
              },
              {
                path: "meta",
                element:
                  (
                    <div>
                      <Analysis />
                      <Meta />
                    </div>
                  )
              },
              {
                path: "goals",
                element:
                  (
                    <div>
                      <Analysis />
                      <Goals />
                    </div>
                  )
              },
              {
                path: "tiktok",
                element:
                  (
                    <div>
                      <Analysis />
                      <TikTok />
                    </div>
                  )
              },
              {
                path: "ttd",
                element:
                  (
                    <div>
                      <Analysis />
                      <Ttd />
                    </div>
                  )
              },
              {
                path: "fixed",
                element:
                  (
                    <div>
                      <Analysis />
                      <Fixed />
                    </div>
                  )
              },
              {
                path: "auditsheet",
                element:
                  (
                    <div>
                      <Analysis />
                      <AuditSheet />
                    </div>
                  )
              },
              {
                path: "aggregate",
                element:
                  (
                    <div>
                      <Analysis />
                      <CombinedAggregate />
                    </div>
                  )
              },
              {
                path: "fixedaggregate",
                element:
                  (
                    <div>
                      <Analysis />
                      <FixedAggregateSheet />
                    </div>
                  )
              },
              {
                path: "aggregateSheet",
                element:
                  (
                    <div>
                      <Analysis />
                      <AggregateSheet />
                    </div>
                  )
              },
              {
                path: "tt",
                element:
                  (
                    <div>
                      <Analysis />
                      <TT />
                    </div>
                  )
              },
            ],
          },
          {
            path: "datavisualization",
            element: <UploadFile />,
            children: [
              {
                path: "datavisualization1",
                element: <DataVisualization />,
              },
            ],
          },
          {
            path: "setting",
            element: <UploadFile />,
          },
        ],
      },
      
    ],
  },
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "login",
    element: <Login></Login>,
  },
]);

export default AppLaout;