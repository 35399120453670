import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import ReactApexChart from 'react-apexcharts';

const BarChartChatbot = ({platformName,platformData, what, graphName}) => {
  const seriesData = [
   
    {
      name: graphName ? 'Working' : 'Non Working',
      data: platformData ? platformData
      
      :
      [0, 0]
    }
  ];

  const optionsData = {
    chart: {
        height: 350,
        type: 'bar',
        events: {
          click: function(chart, w, e) {
            // console.log(chart, w, e)
          }
        }
      },
      colors: ["#57AEDC"],
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false
      },
      xaxis: {
        categories: 
          platformName ? platformName : ["fetching"]
        ,
        labels: {
          style: {
            colors: "#000",
            fontSize: '12px'
          }
        }
      }
  };

  useEffect(()=>{
    console.log("Platform data", platformData);
  })

  return (
    
    <div style={{width: `${"100%"}`}}>
      {/* <h1 className='font-bold text-4xl px-4 py-4' style={{fontSize: "28px", textAlign: "center"}}>{what}</h1> */}
      <div id="chart">
        <ReactApexChart options={optionsData} series={seriesData} type="bar" height={300} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
}

export default BarChartChatbot;