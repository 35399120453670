import React, { useState } from 'react';
import upload_icon from "../../../../../assets/images/upload_icon.png";
import { Link } from 'react-router-dom';

const UploadAgain = ({ uploadTxt }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [checkFileName, setCheckFileName] = useState(false);
    const [uploadBtn, setuploadBtn] = useState(false);
    const [selectedFileNames, setSelectedFileNames] = useState([]);

    const handleFileUpload = (event) => {
        const fileInput = event.target;
        const allowedExtensions = /(\.csv)$/i;
        const files = fileInput.files;

        if (files.length === 0) {
            return;
        }

        const newFileNames = [];

        for (let i = 0; i < files.length; i++) {
            const fileName = files[i].name;

            if (!allowedExtensions.exec(fileName)) {
                setErrorMessage('Please upload only CSV files.');
                fileInput.value = '';
                setTimeout(() => {
                    setErrorMessage('');
                }, 3000);
                return;
            }

            newFileNames.push(fileName);
        }
        if (files) {
            setuploadBtn(true)
        }

        setCheckFileName(true);
        setSelectedFileNames(newFileNames);
    };

    return (
        <div className='subtab1-box'>
            <div className="w-full md:w-1/2 left flex flex-col gap-6 items-center">
                <label
                    htmlFor="ch"
                    className="Campton-light cursor-pointer  px-6 md:px-8 py-1 md:py-2 rounded-lg text-white"
                >
                    <div
                        className="uplaod cursor-pointer w-11/12 py-6 md:py-12 md:py-24 text-center flex justify-center flex-col gap-2 items-center border"
                    >
                        <img src={upload_icon} alt="" />

                        <p className="Campton text-xl md:text-2xl text-black ">
                            {uploadTxt}
                        </p>
                    </div>
                    <input type="file" accept=".csv" id="ch" onChange={handleFileUpload} className='text-black' multiple />
                    {errorMessage && <div className="toast pb-2 text-black">{errorMessage}</div>}
                </label>

                {checkFileName && (
                    <div className="selected-files">
                        <p className="Campton text-lg text-black font-semibold">Selected Files:</p>
                        <ul>
                            {selectedFileNames.map((fileName, index) => (
                                <li key={index}>{fileName}</li>
                            ))}
                        </ul>
                    </div>
                )}
                {uploadBtn &&
                    <Link to="/dashboard/uploadFile/aipoweredinput">
                        <button className='save mt-5 text-white py-[5px] px-5 rounded text-lg' >Upload</button>
                    </Link>
                }
            </div>
            <Link className='skipUpload' to="/dashboard/uploadFile/aipoweredinput">
                Skip Process
            </Link>

        </div>
    );
};

export default UploadAgain;


// import React, { useState } from 'react'
// import upload_icon from "../../../../../assets/images/upload_icon.png";
// import { Link } from 'react-router-dom';

// const UploadAgain = ({ uploadTxt }) => {
//     const [errorMessage, setErrorMessage] = useState('');
//     const [extraUpload, setextraUpload] = useState(true);
//     const [extraUploadBtn, setextraUploadBtn] = useState(false);

//     const handleFileUpload = (event) => {
//         const fileInput = event.target;
//         const allowedExtensions = /(\.csv)$/i;
//         const fileName = fileInput.value;

//         if (!allowedExtensions.exec(fileName)) {
//             setErrorMessage('Please upload a CSV file.');
//             fileInput.value = '';
//             setTimeout(() => {
//                 setErrorMessage('');
//             }, 3000);
//         }
//         if (fileName) {
//             setextraUploadBtn(true);
//         }
//     };

//     return (
//         <>
//             {extraUpload ? (
//                 <div className='subtab1-box w-full'>
//                     <div className="w-full md:w-1/2 left flex flex-col gap-6 items-center">
//                         <label
//                             htmlFor="ch"
//                             className="Campton-light cursor-pointer  px-6 md:px-8 py-1 md:py-2 rounded-lg text-white"
//                         >
//                             <div
//                                 className="uplaod cursor-pointer w-11/12 py-6 md:py-12 md:py-24 text-center flex justify-center flex-col gap-2 items-center border"
//                             >
//                                 <img src={upload_icon} alt="" />

//                                 <p className="Campton text-xl md:text-2xl text-black ">
//                                     {uploadTxt}
//                                 </p>
//                             </div>
//                             <input type="file" accept=".csv" id="ch" onChange={handleFileUpload} className='text-black' />
//                             {errorMessage && <div className="toast pb-2 text-black">{errorMessage}</div>}
//                         </label>
//                     </div>
//                     {extraUploadBtn && (<Link to='/dashboard/uploadFile/uploadtable'><div><button className='save mt-5 text-white py-[5px] px-[35px] rounded text-lg'>Upload File</button></div></Link>)}
//                 </ div>
//             ) : (
//                 <>
//                 </>
//             )}
//         </>
//     )
// }

// export default UploadAgain