import React, { useEffect } from 'react'
import ReactApexChart from 'react-apexcharts';

const PieChart = ({pieData}) => {

    useEffect(()=>{
console.log("PieDatadd ", pieData);
    })
    // Data for the pie chart
    const chartData = {
        series: pieData ? [pieData.percentageOfWorking, pieData.percentageOfNonWorking] : [0, 0],
        options: {
            chart: {
                type: 'donut',
            },
            labels: ['Working Media', 'Waste Media'],
        },
    };

    return (
        <div style={{width :"47%"}}>
            <h1 className='font-bold text-4xl px-4 py-4' style={{fontSize: "28px", textAlign: "center"}}>Working vs Waste Media</h1>
            <ReactApexChart
                options={chartData.options}
                series={chartData.series}
                type="donut"
                height="300"
            />
        </div>
    )
}

export default PieChart