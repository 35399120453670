import React from "react";
import "./work.scss";
import Workimg from "../../assets/images/workimg.png";
import Rawdata from "../../assets/images/raw.svg";
import Cleandata from "../../assets/images/clean.svg";
import Enhancesight from "../../assets/images/enhance.svg";
import Structureddata from "../../assets/images/structure.svg";

const Work = () => {
  return (
    <>
      <div className="work">
        <div className="work-content">
          <div className="titlem">
            <h6>How It works</h6>
          </div>
          <div className="workmain-content">
            <div className="workleft">
              <div className="title">
                <h6>
                  Data Processing System Flowchart? From Raw Data to Actionable
                  Insights
                </h6>
              </div>
              <div className="title-bottom">
                <div className="bottom-contnt">
                  <img src={Workimg} alt="..." />
                </div>
              </div>
            </div>
            <div className="workright">
              <div className="data-section">
                <div className="top-section">
                  <div className="left">
                    <img src={Rawdata} alt="..." />
                  </div>
                  <div className="right">
                    <div className="title">
                      <h6>Raw Data</h6>
                    </div>
                    <div className="subtitle">
                      <p>
                        Input your raw marketing data from Facebook, Instagram,
                        Twitter, and more.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="top-section">
                  <div className="left">
                    <img src={Cleandata} alt="..." />
                  </div>
                  <div className="right">
                    <div className="title">
                      <h6>Clean Data</h6>
                    </div>
                    <div className="subtitle">
                      <p>
                        Clean and organize your data with sophisticated AI
                        tools.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="top-section">
                  <div className="left">
                    <img src={Enhancesight} alt="..." />
                  </div>
                  <div className="right">
                    <div className="title">
                      <h6>Enhance Insights</h6>
                    </div>
                    <div className="subtitle">
                      <p>Enhance your insights with external data sources.</p>
                    </div>
                  </div>
                </div>
                <div className="top-section">
                  <div className="left">
                    <img src={Structureddata} alt="..." />
                  </div>
                  <div className="right">
                    <div className="title">
                      <h6>Structured Data</h6>
                    </div>
                    <div className="subtitle">
                      <p>
                        Witness the transformation into a clean, structured
                        format.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Work;
