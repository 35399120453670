import React, { useState, useEffect, useContext } from 'react'
import { Table } from 'antd'

import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import "./goals.scss"
import { SavedFileContext } from '../../../../../../context/SaveedFileContext';
import { toast } from 'react-toastify';
import { Icon } from '@iconify/react/dist/iconify.js';
import Papa from "papaparse"

const Goals = () => {

    const [allGoals, setGoals] = useState({})
    const [csvData, setCsvData] = useState("");
    const { currentMapping, savedFile, goalsValue, setGoalsValue,fileForChat, setFileForChat} = useContext(SavedFileContext);
    const [goalsData, setGoalsData] = useState({})
    const [csvWithGoalsComparison, setCsvWithGoalsComparison] = useState("")
    const [goalsCompared, setGoalsCompared] = useState(false);
    const [loading, setLoading] = useState(false);
    const [filteredDataForDownload, setFilteredDataForDownload] = useState("")
    let attributes = ["Day", "Week","Client", "Brand", "Campaign Objective", "Platform", "Ad Name", "Ad Set Name","Format", "Status", "Placement ID", "Inventory Type", "Device", "Format", "Size/Length", "Geo", "Demo", "Data Targeting", "Net Spend", "Revenue", "Platform Impressions", "Frequency", "Measurable Imps", "Viewable Impressions", "Monitored Imps (Fraud)", "Fraudulent Impression", "Clicks", "Video Views", "Video Completions", "Unique Reach Impressions", "Conversions", "Landing Page Visits", "Add To Cart", "Leads", "Purchases", "Reach", "ROAS", "Nonviewable Impressions", "Non Fraudulent Impressions", "Viewability Rate %", "Fraud Rate %", "Exceeded Format", "Display CPM", "Video CPM", "eCPM Goal Comparison", "CTR Goal Comparison", "Viewability Goal Comparison", "Fraud Goal Comparison", "Frequency Goal Comparison", "Meet All Conditions", "Non Working Media","Working Media", "Waste Impressions", "Working Impressions", "eCPM", "CTR", "CPC", "Unmeasured Impressions (Viewability & Fraud)", "Qualified Cost Per Thousand (qCPM)", "Unique Reach %"];

    const location = useLocation();

    const handleGoalsInputChange = (e) => {
        setGoals({ [e.target.name]: e.target.value })
    }

    const handleGoalsValueChange = (e) => {
        if (e.target.name == "ctr_goal" || e.target.name == "viewability_goal" || e.target.name == "fraud_goal"){
            setGoalsData(prev => ({ ...prev, [e.target.name]: Number(e.target.value) }))
            setGoalsValue(prev => ({ ...prev, [e.target.name]: Number(e.target.value) }))

        }
        else{

            setGoalsData(prev => ({ ...prev, [e.target.name]: e.target.value }))
            setGoalsValue(prev => ({ ...prev, [e.target.name]: e.target.value }))
        }
    }
    useEffect(() => {
        console.log("Extra Multiple File", location);
        if (location?.state?.csvData) {

            console.log("location", location.state);
            // Papa.parse(location.state.csvData, {
            //     header: true,
            //     dynamicTyping: true,
            //     complete: (result) => {
            //         console.log(result.data);
            //         setCsvData(result.data)
            //         setTableColumn(Object.keys(result.data[0]).map((csvColumnName) => (
            //             { title: csvColumnName, dataIndex: csvColumnName }
            //         )))
            //     },
            // });
            setCsvData(location.state.csvData);
            setCsvWithGoalsComparison(location.state.csvData)
            // metaMapping();

            // setTableColumn(Object.keys(location.state.csvData[0]).map((csvColumnName) => (
            //     { title: csvColumnName, dataIndex: csvColumnName }
            // )))

        }
    }, [])

    const compareGoals = async () => {
        if (goalsValue.display_cpm_goal &&
            goalsValue.ctr_goal &&

            goalsValue.fraud_goal &&

            goalsValue.frequency_goal &&
        
            goalsValue.viewability_goal &&
            goalsValue.video_cpm_goal

){


        try {
            setLoading(true)
            const blob = new Blob([location.state.csvData], { type: 'text/csv' });
            let data = new FormData();
            data.append('csv_file', blob);
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://mywork.techrecipes.com/compare-goals/',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: data
            };
            console.log("dsf", goalsData);
            // data.append("cpm_format", "display");
            Object.keys(goalsData).map((key) => {
                data.append(key, goalsData[key]);
            })
            
            const withCtrAndOther = await axios.request(config);
            console.log("WithCtr", withCtrAndOther.data);
            setCsvWithGoalsComparison(withCtrAndOther.data)
            toast.success("Comparsion successfully made. Please Click on Aggregate Sheet to Check!")
            setFileForChat(withCtrAndOther.data)
            setGoalsCompared(withCtrAndOther.data);
            setLoading(false)
            Papa.parse(withCtrAndOther.data, {
                header: true,
                dynamicTyping: true,
                complete: (result) => {
                    

                    setFilteredDataForDownload(result.data.map((allField) => {
                        let allF = allField
                        let forbiddenList = Object.keys(allField).filter((oneKey) => (
                            !attributes.includes(oneKey)
                        ))
                        // console.log("hehe", forbiddenList);
                        forbiddenList.forEach(e => delete allF[e]);
                        return allF
                    }))
                    

                    
                },
            });

        }
        catch (e) {
            console.log("Error", e);
            if (Array.isArray(e?.response?.data?.detail)){
                toast.error(e?.response?.data?.detail[0].msg)
            }
            else{

                toast.error(`Some Error Occured During Comparision (${e?.response?.data?.detail} column is missing)`)
            }
            setLoading(false)

        }
    }
    else{
        toast.error("Please fill all fields")
    }

    }

    useEffect(()=>{
        console.log("Well", goalsValue);
    },[goalsValue])
    return (
        <div className='audit-sheet-container'>
            <h1 className='audit-h1'><span>Set Your Goals</span></h1>

            {/* <h1 style={{ fontWeight: "700", fontSize: "25px", marginTop: "10px", marginBottom: "10px" }}>Set your Goals</h1> */}
            <div>
                {/* <div className='goalsInputContainer'>
                    <label htmlFor="">
                        CPM Format<span style={{color: "crimson"}}> * </span> :
                    </label> */}
                    {/* <select name="cpm_format" id="" onChange={handleGoalsValueChange} style={{height: "43px", padding: "5px 10px"}}>
                        <option value="">Select CPM Format</option>
                        <option value="Display">Display</option>
                        <option value="Video">Video</option>
                    </select> */}
                    {/* <input type="text" name='cpm_format' placeholder='Display CPM' onChange={handleGoalsValueChange} /> */}
                {/* </div> */}
                <div className='goalsInputContainer'>
                    <label htmlFor="">
                        Display CPM ($) <span style={{color: "crimson"}}> * </span>:
                    </label>
                    <input type="text" name='display_cpm_goal' placeholder='Display CPM' onChange={handleGoalsValueChange} />
                </div>
                <div className='goalsInputContainer'>
                    <label htmlFor="">
                        Video CPM ($) <span style={{color: "crimson"}}> * </span>:
                    </label>
                    <input type="text" name='video_cpm_goal' placeholder='Video CPM' onChange={handleGoalsValueChange} />
                </div>
                <div className='goalsInputContainer'>
                    <label htmlFor="">
                        CTR Goal (%) <span style={{color: "crimson"}}> * </span>:
                    </label>
                    <input type="text" name='ctr_goal' placeholder='CTR Goal' onChange={handleGoalsValueChange} />
                </div>
                <div className='goalsInputContainer'>
                    <label htmlFor="">
                        Viewablility Goal (%) <span style={{color: "crimson"}}> * </span>:
                    </label>
                    <input type="text" name='viewability_goal' placeholder='Viewablility Goal' onChange={handleGoalsValueChange} />
                </div>
                <div className='goalsInputContainer'>
                    <label htmlFor="">
                        Fraud Goal (%) <span style={{color: "crimson"}}> *</span> :
                    </label>
                    <input type="text" name='fraud_goal' placeholder='Fraud Goal' onChange={handleGoalsValueChange} />
                </div>
                <div className='goalsInputContainer'>
                    <label htmlFor="">
                        Frequency Per Day Goal (#) <span style={{color: "crimson"}}> *</span> :
                    </label>
                    <input type="text" name='frequency_goal' placeholder='Frequency Per Day Goal' onChange={handleGoalsValueChange} />
                </div>


            </div>
            <p style={{marginTop: "20px", marginBottom: "20px"}} >
            <Icon icon="material-symbols:info-outline" width="30" height="30"  style={{color: "#15ADDC", display: "inline"}} /> Please enter desired goals value and click <strong>"Apply"</strong> and click Aggregate Sheet. If you want to proceed to Audit Sheet without Setting Goals, click Audit Sheet without setting goals.
            </p>
            <div style={{ display: "flex", gap: "10px" }}>
                <button className='btn_hover font-semibold py-2 px-4 mb-2 text-center bg-custom_primary text-white rounded-xl ' disabled={loading} onClick={compareGoals}>{loading ? "Applying..." : "Apply"}</button>

              { goalsCompared ? <Link to={`${
                savedFile.length > 0 ?
                "/dashboard/analysis/aggregate"
                :
                currentMapping == "meta" ? "/dashboard/analysis/meta"
                    :
                    currentMapping == "tiktok" ?
                        "/dashboard/analysis/tiktok"
                        :
                        currentMapping == "fixed" ?
                            "/dashboard/analysis/fixedaggregate"
                            :
                            currentMapping == "ttd" ?

                                "/dashboard/analysis/ttd"
                                :
                                currentMapping == "other" ?

                                    "/dashboard/analysis/aggregate"
                                    :
                                    ""}`} state={{ csvDataFromGoals: csvWithGoalsComparison }}>
                    <button className='btn_hover font-semibold py-2 px-4 text-center bg-custom_primary text-white rounded-xl'>Aggregated Sheet</button>
                </Link>
                :
                ""}
                <Link to="/dashboard/analysis/aggregateSheet" state={{ csvData: filteredDataForDownload ? Papa.unparse(filteredDataForDownload) : csvWithGoalsComparison }}>
                    <button className='btn_hover font-semibold py-2 px-4 mb-2 text-center bg-custom_primary text-white rounded-xl '>Audit Sheet</button>
                </Link>
                
            
            </div>

        </div>
    )
}

export default Goals