import { useState, useEffect } from "react";
import "./login.scss";
import rootLogo from "../../assets/images/The Root.webp";
import { toast } from "react-toastify";
import { onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../config";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [credentials, setCredentials] = useState({});
  const navigate = useNavigate();
  const handleInputChange = (e) => {
    setCredentials((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handleLogin = async () => {
    if (credentials.email == "" || credentials.email == undefined) {
      console.log("In if");
      toast.error("Please enter email");
    } else if (credentials.password == "" || credentials.password == undefined)    
    {
      toast.error("Please enter password");
    }
    else{
      console.log("All Fields Filled");
      try {
        const userCred = await signInWithEmailAndPassword(
            auth,
            credentials.email,
            credentials.password
        );
        // localStorage.setItem("isSigninSuccess", true);
        toast.success("Login success", { toastId: "userloggedin" });
        // console.log(auth.currentUser);

    } catch (error) {
        toast.error("Invalid Credentials", {
            toastId: "loginError",
        });
    }
    }
  };

  useEffect(() => {
    // const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
            // User is signed in.
            const { uid } = user;
            navigate("/dashboard");
            // setUserisLogged(true);
        } else {
            // User is signed out.
            // setUserisLogged(false);

            // router.push({
            //     pathname: "/login",
            // });
        }
    });
    return () => unsubscribe();

}, []);

  return (
    <div className="loginContainer">
      <div className="loginBox">
        <div className="headers">
          <img src={rootLogo} alt="" />
        </div>
        <div className="loginInputContainer">
          <div className="descriptionBox">
            <h1>Login</h1>
            <p>
              Please enter your <span>The Root</span> credentials to login
            </p>
          </div>

          <div className="loginInput">
            <label htmlFor="email">Email:</label>
            <input
              type="text"
              name="email"
              id="email"
              placeholder="Email"
              value={credentials.email}
              onChange={handleInputChange}
            />
          </div>
          <div className="loginInput">
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              name="password"
              id="password"
              placeholder="Password"
              value={credentials.password}
              onChange={handleInputChange}
            />
          </div>
          <button onClick={handleLogin}>Login</button>
        </div>
      </div>
    </div>
  );
};

export default Login;
