import React from "react";
import { Icon } from "@iconify/react";
import item_img from "../../../assets/images/item_img.png";
import Vegan_img from "../../../assets/images/Vegan_food.png";
import other_img from "../../../assets/images/other_img.png";
import other2 from "../../../assets/images/other2.png";
import other3 from "../../../assets/images/other3.png";
import other4 from "../../../assets/images/other4.png";

const Other_Data = [
  {
    id: 1,
    img: other_img,
    heading: "Vendor Name",
    para: "Order last time on 01/06/2022 Products: Potato, Tomato, Chilli Bill Amount: 400$",
  },
  {
    id: 2,
    img: other2,
    heading: "Vendor Name",
    para: "Order last time on 01/06/2022 Products: Potato, Tomato, Chilli Bill Amount: 400$",
  },
  {
    id: 3,
    img: other3,
    heading: "Vendor Name",
    para: "Order last time on 01/06/2022 Products: Potato, Tomato, Chilli Bill Amount: 400$",
  },
  {
    id: 4,
    img: other4,
    heading: "Vendor Name",
    para: "Order last time on 01/06/2022 Products: Potato, Tomato, Chilli Bill Amount: 400$",
  },
];

function Result() {
  return (
    <div className="custom_height">
      <div className="flex flex-col md:flex-row  mt-6 md:pt-7 2xl:pt-14 ps-5 md:ps-8 2xl:ps-18 pe-5 md:pe-14 2xl:pe-22 md:gap-8  2xl:gap-32  ">
        <div className="w-full  md:w-1/2 ">
          <h1 className=" primary text-3xl font-semibold mb-3">Recommended</h1>{" "}
          <div className="recommended overflow-hidden   border rounded-xl md:rounded-3xl  pb-28 mb-24">
            {/* <img
              className=" absolute top-0 left-0 w-full "
              src={Vegan_img}
              alt=""
            /> */}
            <div className="recommended_top h-[151px] pt-4  ">
              <h2 className="!w-full ps-9  text-3xl font-bold flex items-center    z-10 text-white">
                VEGAN FOOD
              </h2>
            </div>

            <div className="flex flex-col gap-14 ">
              <div className="flex-col md:flex-row w-full items mt-20 md:mt-7 xl:mt-7 2xl:mt-8 flex justify-between ">
                <div className="flex items-center justify-center md:items-end">
                  <img
                    src={item_img}
                    alt=""
                    className=" max-w-full 2xl:max-w-[80%]  xl:max-w-[85%]"
                  />
                </div>
                <div className=" w-full md:w-3/5 content md:pt-7 2xl:pt-14">
                  <h1 className=" clip_path item_bg p-2 ps-16 text-xl text-white mb-6">
                    Items
                  </h1>
                  <ul className="px-5  md:pe-3">
                    <li className="flex justify-between items-baseline text-lg">
                      <p>items</p>{" "}
                      <div className="divider w-52 border-t border-dashed h-1"></div>{" "}
                      <div className="price">4$</div>
                    </li>
                    <li className="flex justify-between items-baseline text-lg">
                      <p>Tomato</p>{" "}
                      <div className="divider w-52 border-t border-dashed h-1"></div>{" "}
                      <div className="price">4$</div>
                    </li>
                    <li className="flex justify-between items-baseline text-lg">
                      <p>Lettuce</p>{" "}
                      <div className="divider w-52 border-t border-dashed h-1"></div>{" "}
                      <div className="price">4$</div>
                    </li>
                    <li className="flex justify-between items-baseline text-lg">
                      <p>Carrot</p>{" "}
                      <div className="divider w-52 border-t border-dashed h-1"></div>{" "}
                      <div className="price">4$</div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="bill text-center w-[90%] md:w-80 xl:w-80 2xl:max-w-96 m-auto  py-3">
                <h1 className="text-xl">Bill</h1>
                <div className="data px-8">
                  <p className="md:text-sm xl:text-lg ">
                    Carrot (50kg)=50X4$= 200$
                  </p>
                  <p className="md:text-sm xl:text-lg ">
                    CPotato (100kg)=50X4$= 200$
                  </p>
                  <p className="md:text-sm xl:text-lg ">
                    Tomato (75kg)==50X4$= 200$
                  </p>
                  <p className="md:text-sm xl:text-lg ">
                    Lettuce (25kg)===50X4$= 200$
                  </p>
                </div>

                <p className=" text-right px-3 text-custom_primary">
                  At: 10:00pm (12/14/2023)
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 ">
          <h1 className="primary text-3xl font-semibold mb-3">Others</h1>
          <div className="others border rounded-xl md:rounded-3xl  py-8 px-5 md:px-9 mb-24">
            <div className="other_cards flex flex-col gap-8">
              {Other_Data.map((data) => {
                return (
                  <>
                    {" "}
                    <div className="other_card cursor-pointer flex-col md:flex-row flex bill md:gap-4 2xl:gap-7 items-center px-5 md:px-7 2xl:px-14 py-6">
                      <div className="img">
                        <img src={data.img} alt="" />
                      </div>

                      <div className="content 2xl:w-2/4 flex flex-col 2xl:gap-4">
                        <h1 className=" md:text-xl 2xl:text-2xl font-medium">
                          {data.heading}
                        </h1>
                        <p className="md:text-sm text-xl">{data.para}</p>
                      </div>
                    </div>
                  </>
                );
              })}

              <div className="paginatiion flex gap-3 justify-center">
                <span className="flex  cursor-pointer justify-center items-center px-2 py-2 rounded-md border">
                  <Icon
                    icon="radix-icons:double-arrow-left"
                    width="13px"
                    height="13px"
                  />
                </span>
                <span className="flex cursor-pointer items-center justify-center px-[9px] py-2 rounded-md border">
                  <Icon
                    icon="simple-line-icons:arrow-left"
                    width="10px"
                    height="10px"
                  />
                </span>
                <span className="flex cursor-pointer bg-custom_primary text-white items-center px-[11px] py-2 rounded-md border ">
                  {" "}
                  1
                </span>
                <span className=" hidden md:flex cursor-pointer items-center px-[11px] py-2  rounded-md border">
                  2
                </span>
                <span className=" hidden md:flex cursor-pointer items-center px-[11px] py-2 rounded-md border">
                  3
                </span>
                <span className="flex cursor-pointer items-center px-[9px] py-2 rounded-md border">
                  ...
                </span>
                <span className="flex cursor-pointer items-center px-[6px] py-2 rounded-md border">
                  10
                </span>
                <span className="flex cursor-pointer items-center px-[9px] py-2 rounded-md border">
                  <Icon
                    icon="simple-line-icons:arrow-right"
                    width="10px"
                    height="10px"
                  />
                </span>
                <span className="flex cursor-pointer items-center px-2 py-2 rounded-md border">
                  <Icon
                    icon="radix-icons:double-arrow-right"
                    width="13px"
                    height="13px"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Result;
