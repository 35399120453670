import React from "react";
import "./powerdata.scss";

const Powerdata = () => {
  return (
    <>
      <div className="powerdata">
        <div className="powerdata-content">
          <div className="content-main">
            <div className="title">
              <h6>Unleash the Power of Data</h6>
            </div>
            <div className="subtitle">
              <p>
                Unlock the Future of Marketing with The Root - Transform your
                marketing campaigns with data analysis and machine learning.
              </p>
            </div>
            <div className="data-btn">
              <button className="learn-more">Learn More</button>
              <button className="signup-more">sign up</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Powerdata;
