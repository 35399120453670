import React from "react";
import "./teamcard.scss";
import Linkdin from "../../assets/images/linkdin.svg";
import Facebook from "../../assets/images/facebook.svg";
import Twitter from "../../assets/images/twitter.svg";

const Teamcard = ({ img, title, subtitle, textpara }) => {
  return (
    <>
      <div className="teamcard">
        <div className="teamcard-content">
          <div className="top-img">
            <img src={img} alt="..." />
          </div>
          <div className="card-title">
            <h6>{title}</h6>
          </div>
          <div className="card-subtitle">
            <h6>{subtitle}</h6>
          </div>
          <div className="card-paragraph">
            <span>{textpara}</span>
          </div>
          <div className="card-socialmedia">
            <img src={Linkdin} alt="..." style={{ cursor: "pointer" }} />
            <img src={Facebook} alt="..." style={{ cursor: "pointer" }} />
            <img src={Twitter} alt="..." style={{ cursor: "pointer" }} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Teamcard;
