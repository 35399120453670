import React from "react";
import Navbar from "../components/navbar/heronavbar";
import Hero from "../components/hero/hero";
import Corefeature from "../components/corefeature/corefeature";
import Whychoose from "../components/whychoose/whychoose";
import Powerdata from "../components/powerdata/powerdata";
import Team from "../components/team/team";
import Footer from "../components/footer/footer";
import Work from "../components/work/work";

const Home = () => {
  return (
    <div>
      <Hero />
      <Corefeature />
      <Work />
      <Whychoose />
      <Powerdata />
      <Team />
      <Footer />
    </div>
  );
};

export default Home;
