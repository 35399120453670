import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import ReactApexChart from 'react-apexcharts';

const BarChart = ({platformData, what}) => {
  const seriesData = [
    {
      name: 'Working',
      data: platformData ? Object.keys(platformData).map((key)=>{
        return platformData[key]["Working Media Total"]
      })
      
      :
      [0, 0]
    },
    {
      name: 'Waste Media',
      data: platformData ? Object.keys(platformData).map((key)=>{
        return platformData[key]["Waste Media Total"]
      })
      
      :
      [0, 0]
    }
  ];

  const optionsData = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      stackType: '100%'
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          position: 'bottom',
          offsetX: -10,
          offsetY: 0
        }
      }
    }],
    xaxis: {
      categories: 
      platformData ? Object.keys(platformData)
      
      :
      ['Facebook', 'Instagram'],
    },
    fill: {
      opacity: 1
    },
    legend: {
      position: 'right',
      offsetX: 0,
      offsetY: 50
    }
  };

  useEffect(()=>{
    console.log("Platform data", platformData);
  })

  return (
    
    <div style={{width: `${what == "Week" ? "100%" : "50%"}`, }}>
      <h1 className='font-bold text-4xl px-4 py-4' style={{fontSize: "28px", textAlign: "center"}}>{what}</h1>
      <div id="chart">
        <ReactApexChart options={optionsData} series={seriesData} type="bar" height={300} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
}

export default BarChart;