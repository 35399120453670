import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AiPoweredInput = () => {
    const navigate = useNavigate();
    const [fieldName, setFieldName] = useState('');
    const [csvData, setCsvData] = useState("")
    const location = useLocation()


    const handleButtonClick = () => {
        if (fieldName.trim() === '') {
            // Show toast/error message when the field is empty
            toast.error('Field cannot be empty!');
        } else {
            // Navigate only if there is text in the field
            const blob = new Blob([location.state.csvData], { type: 'text/csv' });
            // const formData = new FormData();
            // formData.append("selected_column", "Ad name");
            // formData.append("file", blob);
            // const config = {
            //     headers: {
            //         'Content-Type': 'multipart/form-data'
            //     },
            // };
            // const response = await axios.post("https://api1.techrecipes.com/process-csv", formData, config)

            let data = new FormData();
            data.append('file', blob);
            data.append('selected_column', fieldName);

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://api1.techrecipes.com/process-csv',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    console.log("Our data Ai", JSON.stringify(response.data));
                    navigate('/dashboard/uploadFile/uploadtable', { state: { csvData: response.data.csv_content } });
                })
                .catch((error) => {
                    console.log(error);
                });

        }
    };


    useEffect(() => {
        console.log("Extra Multiple File", location);
        if (location?.state?.csvData) {

            console.log("location", location.state);
            setCsvData(location.state.csvData);
        }
    }, [])

    return (
        <div>
            <ToastContainer />
            <br />
            <label className='text-bold mt-3'>Add Field Name</label>
            <br />
            <input
                type='text'
                className='mt-2 rounded'
                value={fieldName}
                onChange={(e) => setFieldName(e.target.value)}
            />
            <br />
            <div className='d-flex'>
                <button
                    className='save mt-5 text-white py-[5px] px-[35px] rounded text-lg'
                    onClick={handleButtonClick}
                >
                    Add
                </button>
                <Link className='skipUpload ml-5' to="/dashboard/uploadFile/uploadtable" state={{ csvData: csvData }}>
                    Skip Process
                </Link>
            </div>

        </div>
    );
};

export default AiPoweredInput;
