import axios from 'axios';
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UnStructuredPowered = () => {
    const navigate = useNavigate();
    const [fieldName, setFieldName] = useState('');
    const location = useLocation();

    const handleButtonClick = async () => {
        if (fieldName.trim() === '') {
            toast.error('Field cannot be empty!');
        } else {
            try {
                const blob = new Blob([location.state.csvData], { type: 'text/csv' });
                // const formData = new FormData();
                // formData.append("selected_column", "Ad name");
                // formData.append("file", blob);
                // const config = {
                //     headers: {
                //         'Content-Type': 'multipart/form-data'
                //     },
                // };
                // const response = await axios.post("https://api1.techrecipes.com/process-csv", formData, config)

                let data = new FormData();
                data.append('file', blob);
                data.append('selected_column', fieldName);

                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: 'https://api1.techrecipes.com/process-csv',
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    data: data
                };
                axios.request(config)
                    .then((response) => {
                        console.log("Our data", JSON.stringify(response.data));
                        console.log("Saad bhai");
                        navigate('/dashboard/uploadFile/extramultiplefile', { state: { csvData: response.data.csv_content } });

                    })
                    .catch((error) => {
                        console.log(error);
                    });

                // console.log("So the response is ai", response.data);
                // Papa.parse(response.data, {
                //     header: true,
                //     dynamicTyping: true,
                //     complete: (result) => {
                //         console.log(result.data);
                //         setCsvData(result.data)
                //     },
                // });

            }
            catch (e) {
                console.log("Error Occured", e);
            }
            // navigate('/dashboard/uploadFile/extramultiplefile');
        }
    };

    return (
        <div>
            <ToastContainer />
            <br />
            <label className='text-bold mt-3'>Add Field Name</label>
            <br />
            <input
                type='text'
                className='mt-2 rounded'
                value={fieldName}
                onChange={(e) => setFieldName(e.target.value)}
            />
            <br />
            <div className='d-flex'>
                <button
                    className='save mt-5 text-white py-[5px] px-[35px] rounded text-lg'
                    onClick={handleButtonClick}
                >
                    Add
                </button>
                <Link className='skipUpload ml-5' to="/dashboard/uploadFile/">
                    Skip Process
                </Link>
            </div>

        </div>
    );
};

export default UnStructuredPowered;
