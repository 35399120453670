import { useState, useEffect, useContext } from 'react'
import { Table } from 'antd';
import '../../../../DataTables/DataTables.css'
import { Link, useLocation, useNavigate } from 'react-router-dom';
// import 'antd/dist/antd.css';
import axios from 'axios';
import Papa from "papaparse"
import { SavedFileContext } from '../../../../../../context/SaveedFileContext';
import { toast } from 'react-toastify';


const Meta = () => {

    const [csvData, setCsvData] = useState("")
    const [tableColumn, setTableColumn] = useState("");
    const [csvMapped, setCsvMapped] = useState("")
    const [filteredDataForDownload, setFilteredDataForDownload] = useState("")
    const location = useLocation();
    // const attributes = ["Day", "Client", "Brand", "Campaign Objective", "Platform", "Ad Name", "Ad Set Name", "Status", "Placement ID", "Inventory Type", "Device", "Format", "Size/Length", "Geo", "Demo", "Data Targeting", "Measurable Imps", "Net Spend", "Revenue", "Platform Impressions", "Frequency", "Viewable Impressions", "Monitored Imps (Fraud)", "Clicks", "Video Views", "Video Completions", "Unique Reach Impressions", "Fraudulent Impression", "Conversions", "Landing Page Visits", "Add To Cart", "Leads", "Purchases", "Fraud Rate %", "Non Fraudulent Impressions", "Reach", "Nonviewable Impressions", "Viewability Rate %", "Unmeasured Impressions", "Frequency", "eCPM", "CTR", "CPC", "ROAS", "Viewability", "Fraud", "Exceeded Format", "eCPM Goal Comparison", "CTR Goal Comparison", "Viewability Goal Comparison", "Fraud Goal Comparison", "Frequency Goal Comparison", "Meet All Conditions", "Working Media", "Non Working Media"]
    // const attributes = ["Week","Day", "Client", "Brand", "Campaign Objective", "Platform", "Ad Name", "Ad Set Name", "Status", "Placement ID", "Inventory Type", "Device", "Format", "Size/Length", "Geo", "Demo", "Data Targeting", "Measurable Imps", "Net Spend", "Revenue", "Platform Impressions", "Frequency", "Viewable Impressions", "Monitored Imps (Fraud)", "Clicks", "Video Views", "Video Completions", "Unique Reach Impressions", "Fraudulent Impression", "Conversions", "Landing Page Visits", "Add To Cart", "Leads", "Purchases", "Fraud Rate %", "Non Fraudulent Impressions", "Reach", "Nonviewable Impressions", "Viewability Rate %", "Unmeasured Impressions", "Frequency", "eCPM", "CTR", "CPC", "ROAS", "Viewability", "Fraud", "Exceeded Format", "Display CPM", "Video CPM", "eCPM Goal Comparison", "CTR Goal Comparison", "Viewability Goal Comparison", "Fraud Goal Comparison", "Frequency Goal Comparison", "Meet All Conditions", "Non Working Media","Working Media"]
    // let attributes = ["Day", "Week","Client", "Brand", "Campaign Objective", "Platform", "Ad Name", "Ad Set Name","Format", "Status", "Placement ID", "Inventory Type", "Device", "Format", "Size/Length", "Geo", "Demo", "Data Targeting", "Net Spend", "Revenue", "Platform Impressions", "Frequency", "Measurable Imps", "Viewable Impressions", "Monitored Imps (Fraud)", "Fraudulent Impression", "Clicks", "Video Views", "Video Completions", "Unique Reach Impressions", "Conversions", "Landing Page Visits", "Add To Cart", "Leads", "Purchases", "Reach", "ROAS", "Nonviewable Impressions", "Non Fraudulent Impressions", "Viewability", "Fraud", "Exceeded Format", "Display CPM", "Video CPM", "eCPM Goal Comparison", "CTR Goal Comparison", "Viewability Goal Comparison", "Fraud Goal Comparison", "Frequency Goal Comparison", "Meet All Conditions", "Non Working Media","Working Media", "Waste Impressions", "Working Impressions", "eCPM", "CTR", "CPC", "Unmeasured Impressions (Viewability & Fraud)", "Qualified Cost Per Thousand (qCPM)", "Unique Reach %"];
    let attributes = ["Day", "Week","Client", "Brand", "Campaign Name", "Campaign Objective", "Platform", "Ad Group Name", "Advertiser Country", "Inventory Type", "Ad Name", "Ad Group Audience", "Ad Set Name","Format", "Status", "Placement ID", "Device", "Format", "Size/Length", "Geo", "Demo", "Data Targeting", "Net Spend", "Revenue", "Platform Impressions", "Frequency", "Ad Frequency", "Measurable Imps", "Viewable Impressions", "Monitored Imps (Fraud)", "Fraudulent Impression", "Impressions", "Clicks", "Video Views", "Video Completions", "Unique Reach Impressions", "Conversions", "Landing Page Visits", "Add To Cart", "Leads", "Purchases", "Reach", "ROAS", "Nonviewable Impressions", "Non Fraudulent Impressions", "Viewability", "Fraud", "Exceeded Format", "Display CPM", "Video CPM", "eCPM Goal Comparison", "CTR Goal Comparison", "Viewability Goal Comparison", "Fraud Goal Comparison", "Frequency Goal Comparison", "Meet All Conditions", "Waste Media","Working Media", "Waste Impressions", "Working Impressions", "eCPM", "CTR", "CPC", "Unmeasured Impressions (Viewability & Fraud)", "Qualified Cost Per Thousand (qCPM)", "Unique Reach %"];



    const [loading, setLoading] = useState(false)
    const metaMapping = () => {
        try {
            if (location?.state?.csvDataFromGoals) {
                setCsvMapped(location?.state?.csvDataFromGoals)
                setFileForChat(location?.state?.csvDataFromGoals)

                Papa.parse(location?.state?.csvDataFromGoals, {
                    header: true,
                    dynamicTyping: true,
                    complete: (result) => {
                        console.log("Upload Table Nehal bhi",result);
                                        
                        // setFilteredDataForDownload(result.data.map((allField) => {
                        //     let allF = allField
                        //     let forbiddenList = Object.keys(allField).filter((oneKey) => (
                        //         !attributes.includes(oneKey)
                        //     ))
                        //     // console.log("hehe", forbiddenList);
                        //     forbiddenList.forEach(e => delete allF[e]);
                        //     return allF
                        // }))
                        
                        setCsvData(result.data)
                        setTableColumn(Object.keys(result.data[0]).sort((a, b) => {
                            let indexA = attributes.indexOf(a);
                            let indexB = attributes.indexOf(b);
                            
                            // If both elements are found in the attributes, compare their positions
                            if (indexA !== -1 && indexB !== -1) {
                                return indexA - indexB;
                            }
                            // If only one element is found in the attributes, prioritize it
                            else if (indexA !== -1) {
                                return -1;
                            }
                            else if (indexB !== -1) {
                                return 1;
                            }
                            // If neither element is found in the attributes, maintain their original order
                            else {
                                return 0;
                            }
                        }).map((csvColumnName) => {
                            console.log(csvColumnName + " ----- " + attributes.includes(csvColumnName));
                            return (
                            attributes.includes(csvColumnName) ? (csvColumnName == "Fraud"|| csvColumnName == "Viewability") ? {title: csvColumnName + " Rate %", dataIndex: csvColumnName} : { title: csvColumnName, dataIndex: csvColumnName } : {}
                        )}).filter(value => Object.keys(value).length !== 0))
                        setFilteredDataForDownload(result.data.map((allField) => {
                            let allF = allField
                            let forbiddenList = Object.keys(allField).filter((oneKey) => (
                                !attributes.includes(oneKey)
                            ))
                            // console.log("hehe", forbiddenList);
                            forbiddenList.forEach(e => delete allF[e]);
                            return allF
                        }))
                        setLoading(false);
                    },
                });
            }
            else {


                setCurrentlyMappedFile(location.state.csvData)
                setLoading(true);
                const blob = new Blob([location.state.csvData], { type: 'text/csv' });
                // const formData = new FormData();
                // formData.append("selected_column", "Ad name");
                // formData.append("file", blob);
                // const config = {
                //     headers: {
                //         'Content-Type': 'multipart/form-data'
                //     },
                // };
                // const response = await axios.post("https://api1.techrecipes.com/process-csv", formData, config)

                let data = new FormData();
                data.append('file', blob);
                // data.append('selected_column', fieldName);

                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: 'https://myapi.techrecipes.com/upload-csv',
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    data: data
                };
                axios.request(config)
                    .then((response) => {
                        console.log("Our data", JSON.stringify(response.data));
                        console.log("Saad bhai");
                        const blob = new Blob([response.data], { type: 'text/csv' });
                        let data = new FormData();
                        data.append('file', blob);
                        let config = {
                            method: 'post',
                            maxBodyLength: Infinity,
                            url: 'https://myapi.techrecipes.com/process-meta-csv',
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            data: data
                        };
                        axios.request(config)
                            .then(async (response) => {
                                if (response.data.error_message){
                                    toast.error (response.data.error_message)
                                }
                                console.log("Our data", JSON.stringify(response.data));
                                console.log("Saad bhai");
                                const blob = new Blob([response.data], { type: 'text/csv' });
                                let data = new FormData();
                                data.append('csv_file', blob);
                                let config = {
                                    method: 'post',
                                    maxBodyLength: Infinity,
                                    url: 'https://mywork.techrecipes.com/calculate-exter-metrics/',
                                    headers: {
                                        'Content-Type': 'multipart/form-data'
                                    },
                                    data: data
                                };
                                const withCtrAndOther = await axios.request(config);
                                console.log("WithCtr", withCtrAndOther.data);
                                
                                // setCsvMapped(response.data)
                                setCsvMapped(withCtrAndOther.data)
                                setFileForChat(withCtrAndOther.data)
                                Papa.parse(withCtrAndOther.data, {
                                    header: true,
                                    dynamicTyping: true,
                                    complete: (result) => {
                                        console.log("Upload Table Nehal bhi",result);
                                        
                                        // setFilteredDataForDownload(result.data.map((allField) => {
                                        //     let allF = allField
                                        //     let forbiddenList = Object.keys(allField).filter((oneKey) => (
                                        //         !attributes.includes(oneKey)
                                        //     ))
                                        //     // console.log("hehe", forbiddenList);
                                        //     forbiddenList.forEach(e => delete allF[e]);
                                        //     return allF
                                        // }))
                                        
                                        setCsvData(result.data)
                                        setTableColumn(Object.keys(result.data[0]).sort((a, b) => {
                                            let indexA = attributes.indexOf(a);
                                            let indexB = attributes.indexOf(b);
                                            
                                            // If both elements are found in the attributes, compare their positions
                                            if (indexA !== -1 && indexB !== -1) {
                                                return indexA - indexB;
                                            }
                                            // If only one element is found in the attributes, prioritize it
                                            else if (indexA !== -1) {
                                                return -1;
                                            }
                                            else if (indexB !== -1) {
                                                return 1;
                                            }
                                            // If neither element is found in the attributes, maintain their original order
                                            else {
                                                return 0;
                                            }
                                        }).map((csvColumnName) => {
                                            console.log(csvColumnName + " ----- " + attributes.includes(csvColumnName));
                                            return (
                                            attributes.includes(csvColumnName) ? (csvColumnName == "Fraud"|| csvColumnName == "Viewability") ? {title: csvColumnName + " Rate %", dataIndex: csvColumnName} : { title: csvColumnName, dataIndex: csvColumnName } : {}
                                        )}).filter(value => Object.keys(value).length !== 0))
                                        setFilteredDataForDownload(result.data.map((allField) => {
                                            let allF = allField
                                            let forbiddenList = Object.keys(allField).filter((oneKey) => (
                                                !attributes.includes(oneKey)
                                            ))
                                            // console.log("hehe", forbiddenList);
                                            forbiddenList.forEach(e => delete allF[e]);
                                            return allF
                                        }))
                                        setLoading(false);
                    
                                    },
                                });
                                
                                // Papa.parse(location.state.csvData, {
                                //     header: true,
                                //     dynamicTyping: true,
                                //     complete: (result) => {
                                //         console.log("Nehal bhai", result);
                                //         console.log("Nehal bhai 2", Papa.unparse(result.data));
                                //         console.log("new data", result.data.map((allField) => {
                                        //     let allF = allField
                                        //     let forbiddenList = Object.keys(allField).filter((oneKey) => (
                                        //         !attributes.includes(oneKey)
                                        //     ))
                                        //     // console.log("hehe", forbiddenList);
                                        //     forbiddenList.forEach(e => delete allF[e]);
                                        //     return allF
                                        // }));

                                        // setFilteredDataForDownload(result.data.map((allField) => {
                                        //     let allF = allField
                                        //     let forbiddenList = Object.keys(allField).filter((oneKey) => (
                                        //         !attributes.includes(oneKey)
                                        //     ))
                                        //     // console.log("hehe", forbiddenList);
                                        //     forbiddenList.forEach(e => delete allF[e]);
                                        //     return allF
                                        // }))

                                        // setCsvData(result.data)
                                        // setTableColumn(Object.keys(result.data[0]).sort((a, b) => {
                                        //     let indexA = attributes.indexOf(a);
                                        //     let indexB = attributes.indexOf(b);
                                            
                                        //     // If both elements are found in the attributes, compare their positions
                                        //     if (indexA !== -1 && indexB !== -1) {
                                        //         return indexA - indexB;
                                        //     }
                                        //     // If only one element is found in the attributes, prioritize it
                                        //     else if (indexA !== -1) {
                                        //         return -1;
                                        //     }
                                        //     else if (indexB !== -1) {
                                        //         return 1;
                                        //     }
                                        //     // If neither element is found in the attributes, maintain their original order
                                        //     else {
                                        //         return 0;
                                        //     }
                                        // }).map((csvColumnName) => {
                                        //     console.log(csvColumnName + " ----- " + attributes.includes(csvColumnName));
                                        //     return (
                                        //     attributes.includes(csvColumnName) ? { title: csvColumnName, dataIndex: csvColumnName } : {}
                                        // )}))
                                        // setLoading(false);
                                //     },
                                // });

                            })
                            .catch((error) => {
                                setLoading(false);
                                console.log(error);
                            });
                        // Papa.parse(response.data, {
                        //     header: true,
                        //     dynamicTyping: true,
                        //     complete: (result) => {
                        //         console.log("new data", result.data);
                        //         setCsvData(result.data)
                        //         setTableColumn(Object.keys(result.data[0]).map((csvColumnName) => (
                        //             { title: csvColumnName, dataIndex: csvColumnName }
                        //         )))
                        //     },
                        // });

                    })
                    .catch((error) => {
                        setLoading(false);

                        console.log(error);
                    });

                // console.log("So the response is ai", response.data);
                // Papa.parse(response.data, {
                //     header: true,
                //     dynamicTyping: true,
                //     complete: (result) => {
                //         console.log(result.data);
                //         setCsvData(result.data)
                //     },
                // });

            }
        }
        catch (e) {
            console.log("Error Occured", e);
        }
    }

    useEffect(() => {
        console.log("Extra Multiple File", location);
        if (location?.state?.csvData || location?.state?.csvDataFromGoals) {

            console.log("location", location.state);
            // Papa.parse(location.state.csvData, {
            //     header: true,
            //     dynamicTyping: true,
            //     complete: (result) => {
            //         console.log(result.data);
            //         setCsvData(result.data)
            //         setTableColumn(Object.keys(result.data[0]).map((csvColumnName) => (
            //             { title: csvColumnName, dataIndex: csvColumnName }
            //         )))
            //     },
            // });
            // setCsvData(location.state.csvData);
            metaMapping();
            // setTableColumn(Object.keys(location.state.csvData[0]).map((csvColumnName) => (
            //     { title: csvColumnName, dataIndex: csvColumnName }
            // )))

        }
    }, [])

    const dataSource = [
        {
            key: '1',
            Measurable: 'Mike',
            SUM_of_Opportunity_Index: 32,
            Net_Spend: '10 Downing Street',

            Impressions: 'Mike',
            Clicks: 32,
            SUM_of_CTR: '10 Downing Street',

            SUM_of_eCPM: 'Mike',
            SUM_of_CPC: 32,
            SUM_of_OTR: '10 Downing Street',

            SUM_of_Viewability: 'Mike',
            SUM_of_Fraud: 32,
            SUM_of_Frequency: '10 Downing Street',

            SUM_of_Avg_OT_CTR: 'Mike',
            SUM_of_OTF: 32,
            SUM_of_NF_vCPM: '10 Downing Street',

            SUM_of_eCPMH: 'Mike',
            SUM_of_OTR_vCPM: 32,
            SUM_of_qCPM: '10 Downing Street',

            SUM_of_VCR: 'Mike',
            SUM_of_vCPCV: 32,
            SUM_of_OT_vCPCV: '10 Downing Street',

            SUM_of_hCPCV: 'Mike',
            SUM_of_qCPH: 32,
            SUM_of_ROAS: '10 Downing Street',

            SUM_of_CPL: 'Mike',
            SUM_of_qCPL: 32,
            SUM_of_CPS: 32,

            SUM_of_qCPS: '10 Downing Street',

        },
        {
            key: '2',
            Measurable: 'Mike',
            SUM_of_Opportunity_Index: 32,
            Net_Spend: '10 Downing Street',

            Impressions: 'Mike',
            Clicks: 32,
            SUM_of_CTR: '10 Downing Street',

            SUM_of_eCPM: 'Mike',
            SUM_of_CPC: 32,
            SUM_of_OTR: '10 Downing Street',

            SUM_of_Viewability: 'Mike',
            SUM_of_Fraud: 32,
            SUM_of_Frequency: '10 Downing Street',

            SUM_of_Avg_OT_CTR: 'Mike',
            SUM_of_OTF: 32,
            SUM_of_NF_vCPM: '10 Downing Street',

            SUM_of_eCPMH: 'Mike',
            SUM_of_OTR_vCPM: 32,
            SUM_of_qCPM: '10 Downing Street',

            SUM_of_VCR: 'Mike',
            SUM_of_vCPCV: 32,
            SUM_of_OT_vCPCV: '10 Downing Street',

            SUM_of_hCPCV: 'Mike',
            SUM_of_qCPH: 32,
            SUM_of_ROAS: '10 Downing Street',

            SUM_of_CPL: 'Mike',
            SUM_of_qCPL: 32,
            SUM_of_CPS: 32,

            SUM_of_qCPS: '10 Downing Street',

        },
        {
            key: '1',
            Measurable: 'Mike',
            SUM_of_Opportunity_Index: 32,
            Net_Spend: '10 Downing Street',

            Impressions: 'Mike',
            Clicks: 32,
            SUM_of_CTR: '10 Downing Street',

            SUM_of_eCPM: 'Mike',
            SUM_of_CPC: 32,
            SUM_of_OTR: '10 Downing Street',

            SUM_of_Viewability: 'Mike',
            SUM_of_Fraud: 32,
            SUM_of_Frequency: '10 Downing Street',

            SUM_of_Avg_OT_CTR: 'Mike',
            SUM_of_OTF: 32,
            SUM_of_NF_vCPM: '10 Downing Street',

            SUM_of_eCPMH: 'Mike',
            SUM_of_OTR_vCPM: 32,
            SUM_of_qCPM: '10 Downing Street',

            SUM_of_VCR: 'Mike',
            SUM_of_vCPCV: 32,
            SUM_of_OT_vCPCV: '10 Downing Street',

            SUM_of_hCPCV: 'Mike',
            SUM_of_qCPH: 32,
            SUM_of_ROAS: '10 Downing Street',

            SUM_of_CPL: 'Mike',
            SUM_of_qCPL: 32,
            SUM_of_CPS: 32,

            SUM_of_qCPS: '10 Downing Street',

        },
        {
            key: '2',
            Measurable: 'Mike',
            SUM_of_Opportunity_Index: 32,
            Net_Spend: '10 Downing Street',

            Impressions: 'Mike',
            Clicks: 32,
            SUM_of_CTR: '10 Downing Street',

            SUM_of_eCPM: 'Mike',
            SUM_of_CPC: 32,
            SUM_of_OTR: '10 Downing Street',

            SUM_of_Viewability: 'Mike',
            SUM_of_Fraud: 32,
            SUM_of_Frequency: '10 Downing Street',

            SUM_of_Avg_OT_CTR: 'Mike',
            SUM_of_OTF: 32,
            SUM_of_NF_vCPM: '10 Downing Street',

            SUM_of_eCPMH: 'Mike',
            SUM_of_OTR_vCPM: 32,
            SUM_of_qCPM: '10 Downing Street',

            SUM_of_VCR: 'Mike',
            SUM_of_vCPCV: 32,
            SUM_of_OT_vCPCV: '10 Downing Street',

            SUM_of_hCPCV: 'Mike',
            SUM_of_qCPH: 32,
            SUM_of_ROAS: '10 Downing Street',

            SUM_of_CPL: 'Mike',
            SUM_of_qCPL: 32,
            SUM_of_CPS: 32,

            SUM_of_qCPS: '10 Downing Street',

        }
    ];

    const columns = [
        {
            title: 'Measurable',
            dataIndex: 'Measurable',
            key: 'Measurable',
        },
        {
            title: 'SUM of Opportunity Index',
            dataIndex: 'SUM_of_Opportunity_Index',
            key: 'SUM of Opportunity Index',
        },
        {
            title: 'Net Spend',
            dataIndex: 'Net_Spend',
            key: 'Net Spend',
        },
        {
            title: 'Impressions',
            dataIndex: 'Impressions',
            key: 'Impressions',
        },
        {
            title: 'Clicks',
            dataIndex: 'Clicks',
            key: 'Clicks',
        },
        {
            title: 'SUM of CTR',
            dataIndex: 'SUM_of_CTR',
            key: 'SUM of CTR',
        },
        {
            title: 'SUM of eCPM',
            dataIndex: 'SUM_of_eCPM',
            key: 'SUM of eCPM',
        },
        {
            title: 'SUM of CPC',
            dataIndex: 'SUM_of_CPC',
            key: 'SUM of CPC',
        },
        {
            title: 'SUM of OTR',
            dataIndex: 'SUM_of_OTR',
            key: 'SUM of OTR',
        },
        {
            title: 'SUM of Viewability',
            dataIndex: 'SUM_of_Viewability',
            key: 'SUM of Viewability',
        },
        {
            title: 'SUM of Fraud',
            dataIndex: 'SUM_of_Fraud',
            key: 'SUM of Fraud',
        },
        {
            title: 'SUM of Frequency',
            dataIndex: 'SUM_of_Frequency',
            key: 'SUM of Frequency',
        },
        {
            title: 'SUM of Avg. OT CTR',
            dataIndex: 'SUM_of_Avg_OT_CTR',
            key: 'SUM of Avg. OT CTR',
        },
        {
            title: 'SUM of OTF',
            dataIndex: 'SUM_of_OTF',
            key: 'SUM of OTF',
        },
        {
            title: 'SUM of NF vCPM',
            dataIndex: 'SUM_of_NF_vCPM',
            key: 'SUM of NF vCPM',
        },
        {
            title: 'SUM of eCPMH',
            dataIndex: 'SUM_of_eCPMH',
            key: 'SUM of eCPMH',
        },
        {
            title: 'SUM of OTR vCPM',
            dataIndex: 'SUM_of_OTR_vCPM',
            key: 'SUM of OTR vCPM',
        },
        {
            title: 'SUM of qCPM',
            dataIndex: 'SUM_of_qCPM',
            key: 'SUM of qCPM',
        },
        {
            title: 'SUM of VCR',
            dataIndex: 'SUM_of_VCR',
            key: 'SUM of VCR',
        },
        {
            title: 'SUM of vCPCV',
            dataIndex: 'SUM_of_vCPCV',
            key: 'SUM of vCPCV',
        },
        {
            title: 'SUM of OT vCPCV',
            dataIndex: 'SUM_of_OT_vCPCV',
            key: 'SSUM of OT vCPCV',
        },
        {
            title: 'SUM of hCPCV',
            dataIndex: 'SUM_of_hCPCV',
            key: 'SUM of hCPCV',
        },
        {
            title: 'SUM of qCPH',
            dataIndex: 'SUM_of_qCPH',
            key: 'SUM of qCPH',
        },
        {
            title: 'SUM of ROAS',
            dataIndex: 'SUM_of_ROAS',
            key: 'SUM of ROAS',
        },
        {
            title: 'SUM of CPL',
            dataIndex: 'SUM_of_CPL',
            key: 'SUM of CPL',
        },
        {
            title: 'SUM of qCPL',
            dataIndex: 'SUM_of_qCPL',
            key: 'SUM of qCPL',
        },
        {
            title: 'SUM of CPS',
            dataIndex: 'SUM_of_CPS',
            key: 'SUM of CPS',
        },
        {
            title: 'SUM of qCPS',
            dataIndex: 'SUM_of_qCPS',
            key: 'SUM of qCPS',
        },
    ];
    const { savedFile, setSavedFile, fileForChat, setFileForChat, currentlyMappedFile, setCurrentlyMappedFile } = useContext(SavedFileContext)
    const navigate = useNavigate();

    const handlePreviousDataSaved = () => {
        setSavedFile(prev => ([...prev, csvMapped]))


        navigate("/dashboard/uploadFile/singlefileupload")
    }

    const generateCombineAuditSheet = async () => {
        try {
            // const j = new FormData;
            // let s = ["2", "d", "a"]
            // j.append("dd", s);
            // console.log("ds", typeof (j.get("dd")));

            const blobCurrent = new Blob([csvMapped], { type: 'text/csv' });
            // const blobPrevious = new Blob([savedFile], { type: 'text/csv' });
            const blobPrevious = savedFile.map((file) => (

                new Blob([file], { type: 'text/csv' })
            ))

            let allFiles = [blobCurrent, ...blobPrevious]

            // let teseFiles = [...files]
            console.log("bro", allFiles);

            const formData = new FormData();
            allFiles.map((file) => {
                formData.append("files", file);

            })
            // formData.append("direction", "5");
            console.log("fd", formData);
            console.log("let's see", (formData.get("files")));
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            };
            const response = await axios.post("https://test.techrecipes.com/merge-csv", formData, config)
            console.log("So the response is", response.data);
            // Papa.parse(response.data, {
            //     header: true,
            //     dynamicTyping: true,
            //     complete: (result) => {
            //         console.log(result.data);
            //         setCsvData(result.data)
            //     },
            // });
            const csv = response.data;
            // var csvDataBlob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            // var csvURL = null;
            // if (navigator.msSaveBlob) {
            //     csvURL = navigator.msSaveBlob(csvDataBlob, 'speciallyForNehalBhai.csv');
            // }
            // else {
            //     csvURL = window.URL.createObjectURL(csvDataBlob);
            // }

            // var tempLink = document.createElement('a');
            // tempLink.href = csvURL;
            // tempLink.setAttribute('download', "result" + '.csv');
            // tempLink.click();
            // navigate("/dashboard/analysis/aggregateSheet", { state: { csvData: response.data } })
            // navigate("/dashboard/analysis/auditsheet", { state: { csvData: response.data } })
            handleStructure(response.data)
        }
        catch (e) {
            console.log("Error Occured", e);
        }
    }
    const handleStructure = async (combinedCsvFile) => {
        try {

            console.log("well", combinedCsvFile.split(",")[0]);

            const blob = new Blob([combinedCsvFile], { type: 'text/csv' });
            // const formData = new FormData();
            // formData.append("selected_column", "Ad name");
            // formData.append("file", blob);
            // const config = {
            //     headers: {
            //         'Content-Type': 'multipart/form-data'
            //     },
            // };
            // const response = await axios.post("https://api1.techrecipes.com/process-csv", formData, config)

            let data = new FormData();
            data.append('file', blob);
            const expectedHeaders = [
                "Campaign Objective",
                "Inventory Type",
                "Data Targeting",
                "Size/Length",
                "Platform Impressions",
                "Ad name",
                "Net Spend",
                "Week",
                "Clicks",
                "Brand",
                "Platform",
                "Measurable",
            ]
            // data.append('selected_column', location.state.csvData.split(",")[0]);

            data.append('selected_column', expectedHeaders.find((data) => (combinedCsvFile.includes(data))));
            // data.append('selected_column', location.state.csvData.split(",")[0]);


            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://api1.techrecipes.com/process-csv',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: data
            };
            axios.request(config)
                .then((response) => {
                    console.log("Our data", JSON.stringify(response.data));
                    console.log("Saad bhai");
                    // var csvDataBlob = new Blob([response.data.csv_content], { type: 'text/csv;charset=utf-8;' });
                    // var csvURL = null;
                    // if (navigator.msSaveBlob) {
                    //     csvURL = navigator.msSaveBlob(csvDataBlob, 'download.csv');
                    // }
                    // else {
                    //     csvURL = window.URL.createObjectURL(csvDataBlob);
                    // }

                    // var tempLink = document.createElement('a');
                    // tempLink.href = csvURL;
                    // tempLink.setAttribute('download', "aggregateData" + '.csv');
                    // tempLink.click();
                    navigate('/dashboard/analysis/aggregate', { state: { csvData: response.data.csv_content } });

                })
                .catch((error) => {
                    console.log(error);
                });

            // console.log("So the response is ai", response.data);
            // Papa.parse(response.data, {
            //     header: true,
            //     dynamicTyping: true,
            //     complete: (result) => {
            //         console.log(result.data);
            //         setCsvData(result.data)
            //     },
            // });

        }
        catch (e) {
            console.log("Error Occured", e);
            alert("Unable to structure data")
        }
    }
    const handleDownload = () => {

        const filteredCsv = Papa.unparse(filteredDataForDownload);
        var csvDataBlob = new Blob([filteredCsv], { type: 'text/csv;charset=utf-8;' });
        var csvURL = null;
        if (navigator.msSaveBlob) {
            csvURL = navigator.msSaveBlob(csvDataBlob, 'download.csv');
        }
        else {
            csvURL = window.URL.createObjectURL(csvDataBlob);
        }

        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', "mapping" + '.csv');
        tempLink.click();
    }
    return (
        <div className='dataResults-container'>
            <button className='btn_hover font-semibold py-2 text-center bg-custom_primary text-white rounded-xl ' style={{ width: "150px", marginTop: "20px" }} onClick={handleDownload}>Download</button>
            <Link to="/dashboard/uploadFile/uploadtable" state={{ csvData: location?.state?.csvData || currentlyMappedFile }}>
                <button className='btn_hover font-semibold py-2 text-center bg-custom_primary text-white rounded-xl ' style={{ marginLeft: "10px", paddingLeft: "20px", paddingRight: "20px" }}>Edit Mapping Again</button>
            </Link>
            <div className='dataResults-align'>
                <h1><span>Meta:</span> Aggregate Sheet</h1>

                {!savedFile.length > 0 ?
                <Link to="/dashboard/analysis/goals" state={{ csvData: csvMapped }}>
                    <button className='btn_hover font-semibold py-2 text-center bg-custom_primary text-white rounded-xl '>Set Goals</button>
                </Link>
:
""
                }
                {location?.state?.csvDataFromGoals ?
                    <Link to="/dashboard/analysis/aggregateSheet" state={{ csvData: filteredDataForDownload ? Papa.unparse(filteredDataForDownload): csvMapped }}>
                        <button className='btn_hover font-semibold py-2 text-center bg-custom_primary text-white rounded-xl '>Audit Sheet</button>
                    </Link>
                    :
                    ""
                }
                {
                    savedFile.length > 0 ?
                        <>
                            <div onClick={generateCombineAuditSheet} style={{ backgroundColor: "cadetblue", color: "white", borderRadius: "5px" }}>
                                <button>{loading ? "Processing Data..." : "Combine Aggregated Sheet"}</button>
                            </div>
                            

                        </>
                        :
                        ""}
                <div onClick={handlePreviousDataSaved} style={{ backgroundColor: "cadetblue", color: "white", borderRadius: "5px" }}>
                    <button>Add Another Analysis Report</button>
                </div>
                {
                    savedFile.length > 0 ?
                        <>
                           
                            <div onClick={() => setSavedFile([])} style={{ backgroundColor: "cadetblue", color: "white", borderRadius: "5px" }}>
                                <button>Clear Previous added Reports</button>
                            </div>

                        </>
                        :
                        ""}
            </div>
            {loading ?
                <p>Loading...</p>
                :
                <Table dataSource={csvData ? csvData : []} columns={tableColumn ? tableColumn : []} />
            }
        </div>
    )
}

export default Meta;