import React, { useContext, useEffect, useRef, useState } from "react";
import PieChart from "./PieChart/PieChart";
import BarChart from "./BarChart/BarChart";
import AreaChart from "./AreaChart/AreaChart";
import { useLocation } from "react-router-dom";
import axios from "axios";
import "./datavisualization.scss";
import { SavedFileContext } from "../../../../../context/SaveedFileContext";
import { useReactToPrint } from 'react-to-print';
import { toast } from "react-toastify";


const DataVisualization = () => {
  const [pieData, setPieData] = useState(false);
  const [platformData, setPlatformData] = useState(false);
  const [weekData, setWeekData] = useState(false);
  const [trafficLightsResults, setTrafficLightsResults] = useState({})
  const location = useLocation();
  const { goalsValue } = useContext(SavedFileContext);
  const [loading, setLoading] = useState(false);
  const [cpmValues, setCpmValues] = useState({});
  const visualsRef = useRef();

  const getCpmValues = async () => {
    try {
      const blob = new Blob([location.state.csvData], { type: "text/csv" });

      let data = new FormData();
      data.append("csv_file", blob);
      // data.append("base_col", oldColumnName)
      // data.append("primary_col", newColumnName)

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `https://mywork.techrecipes.com/average_ecpm_by_format/`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: data,
      };
      const response = await axios.request(config);
      console.log("Total CPM Results", response.data);
      setCpmValues(response.data);
    }
    catch(e){
      console.log("Error getting CPMs", e.response.data.detail);
      toast.error(  "Error: " + e.response.data.detail  )
    }
  }

  const getDataForVisualizations = async () => {
    setLoading(true);
    try {
      const blob = new Blob([location.state.csvData], { type: "text/csv" });

      let data = new FormData();
      data.append("csv_file", blob);
      // data.append("base_col", oldColumnName)
      // data.append("primary_col", newColumnName)

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `https://mywork.techrecipes.com/totals_Working Media_Non Working Media/`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: data,
      };
      const response = await axios.request(config);
      console.log("Total Working Media Results", response.data);
      const total =
        response.data["Working Media Total"] +
        response.data["Waste Media Total"];
      const percentageOfWorking =
        (response.data["Working Media Total"] / total) * 100;
      const percentageOfNonWorking =
        (response.data["Waste Media Total"] / total) * 100;
      setPieData({ percentageOfWorking, percentageOfNonWorking });
      console.log({ percentageOfWorking, percentageOfNonWorking });
    } catch (e) {
      console.log("Error getting CPMs", e.response.data.detail);
      toast.error( "Error: " + e.response.data.detail  )
    }
    try {
      const blob = new Blob([location.state.csvData], { type: "text/csv" });

      let data = new FormData();
      data.append("csv_file", blob);
      // data.append("base_col", oldColumnName)
      // data.append("primary_col", newColumnName)

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `https://mywork.techrecipes.com/totals_by_platform/`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: data,
      };
      const response = await axios.request(config);
      console.log("hehe", response.data);
      setPlatformData(response.data);
      let total = {};

      Object.keys(response.data).map((platform) => {
        total[platform] =
          response.data[platform]["Working Media Total"] +
          response.data[platform]["Waste Media Total"];
      });

      console.log("Hehe", total);
      // console.log("Total Working Media Results", response.data);
      // const percentageOfWorking = ((response.data["Working Media Total"]/total) * 100)
      // const percentageOfNonWorking = ((response.data["No Working Media Total"]/total) * 100)
      // setPieData(percentageOfWorking,percentageOfNonWorking )
      // console.log({percentageOfWorking,percentageOfNonWorking});
    } catch (e) {
      console.log("Error:", e.response.data.detail);
      toast.error( "Error: " + e.response.data.detail )
    }
    try {
      const blob = new Blob([location.state.csvData], { type: "text/csv" });
// var csvDataa = new Blob([location.state.csvData], { type: 'text/csv;charset=utf-8;' });
//                     var csvURL = null;
//                     if (navigator.msSaveBlob) {
//                         csvURL = navigator.msSaveBlob(csvDataa, 'download.csv');
//                     }
//                     else {
//                         csvURL = window.URL.createObjectURL(csvDataa);
//                     }

//                     var tempLink = document.createElement('a');
//                     tempLink.href = csvURL;
//                     tempLink.setAttribute('download', "Aggregiate Sheet" + '.csv');
//                     tempLink.click();
      let data = new FormData();
      data.append("csv_file", blob);
      // data.append("base_col", oldColumnName)
      // data.append("primary_col", newColumnName)

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `https://mywork.techrecipes.com/totals_by_week/`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: data,
      };
      const response = await axios.request(config);
      console.log("hehe", response.data);
      setWeekData(response.data);
      let total = {};

      Object.keys(response.data).map((platform) => {
        total[platform] =
          response.data[platform]["Working Media Total"] +
          response.data[platform]["Waste Media Total"];
      });

      console.log("Hehe", total);
      // console.log("Total Working Media Results", response.data);
      // const percentageOfWorking = ((response.data["Working Media Total"]/total) * 100)
      // const percentageOfNonWorking = ((response.data["No Working Media Total"]/total) * 100)
      // setPieData(percentageOfWorking,percentageOfNonWorking )
      // console.log({percentageOfWorking,percentageOfNonWorking});
    } catch (e) {
      console.log("Error getting CPMs", e.response.data.detail);
      toast.error(  "Error: " + e.response.data.detail  )
    }
    setLoading(false);

  };
  useEffect(() => {
    // console.log("In Visualization", location?.state?.csvData);
    console.log("dd", location?.state?.totalCalculation);
    console.log("dd", location?.state?.totalCalculation?.vCPM?.includes("$") ? location?.state?.totalCalculation?.vCPM?.slice(0, location?.state?.totalCalculation?.vCPM?.length-1 ) : location?.state?.totalCalculation?.vCPM);
    console.log("dd", location?.state?.totalCalculation?.CTR?.includes("%") ? location?.state?.totalCalculation?.CTR?.slice(0, location?.state?.totalCalculation?.CTR?.length-1 ) : location?.state?.totalCalculation?.CTR);
    console.log("dd", location?.state?.totalCalculation && location?.state?.totalCalculation["Viewable/Total Impressions Ratio"]?.includes("%") ? location?.state?.totalCalculation["Viewable/Total Impressions Ratio"]?.slice(0, location?.state?.totalCalculation["Viewable/Total Impressions Ratio"]?.length-1 ) : location?.state?.totalCalculation["Viewable/Total Impressions Ratio"]);
    console.log("dd", location?.state?.totalCalculation && location?.state?.totalCalculation[
      "Fraudulent/Total Impressions Ratio"
    ]?.includes("%") ? location?.state?.totalCalculation[
      "Fraudulent/Total Impressions Ratio"
    ]?.slice(0, location?.state?.totalCalculation[
      "Fraudulent/Total Impressions Ratio"
    ]?.length-1 ) : location?.state?.totalCalculation[
      "Fraudulent/Total Impressions Ratio"
    ]);
    console.log("dd", location?.state?.totalCalculation?.vCPM?.includes("$") ? location?.state?.totalCalculation?.vCPM?.slice(0, location?.state?.totalCalculation?.vCPM?.length-1 ) : location?.state?.totalCalculation?.vCPM);
    console.log("dd", goalsValue);
let vCpm = location?.state?.totalCalculation?.vCPM?.includes("$") ? location?.state?.totalCalculation?.vCPM?.slice(0, location?.state?.totalCalculation?.vCPM?.length-1 ) : location?.state?.totalCalculation?.vCPM;
let ctr = location?.state?.totalCalculation?.CTR?.includes("%") ? location?.state?.totalCalculation?.CTR?.slice(0, location?.state?.totalCalculation?.CTR?.length-1 ) : location?.state?.totalCalculation?.CTR;    
let viewability = location?.state?.totalCalculation && location?.state?.totalCalculation["Viewable/Total Impressions Ratio"]?.includes("%") ? location?.state?.totalCalculation["Viewable/Total Impressions Ratio"]?.slice(0, location?.state?.totalCalculation["Viewable/Total Impressions Ratio"]?.length-1 ) : location?.state?.totalCalculation["Viewable/Total Impressions Ratio"];
    let fraud = location?.state?.totalCalculation && location?.state?.totalCalculation[
      "Fraudulent/Total Impressions Ratio"
    ]?.includes("%") ? location?.state?.totalCalculation[
      "Fraudulent/Total Impressions Ratio"
    ]?.slice(0, location?.state?.totalCalculation[
      "Fraudulent/Total Impressions Ratio"
    ]?.length-1 ) : location?.state?.totalCalculation[
      "Fraudulent/Total Impressions Ratio"
    ]
    const trafficLightsResultsLocal = {
      dCpm:
      cpmValues && cpmValues["Average eCPM Display"] <= goalsValue.display_cpm_goal
          ? true
          : false,
      vCpm:
      cpmValues && cpmValues["Average eCPM Video"] <= goalsValue.video_cpm_goal
          ? true
          : false,
      ctr:
      ctr >= goalsValue.ctr_goal
          ? true
          : false,
      format: true,
      fraud:
        fraud <= goalsValue.fraud_goal
          ? true
          : false,
      frequency:
        location?.state?.totalCalculation["Impressions/Unique Reach Ratio"] <=
        goalsValue.frequency_goal
          ? true
          : false,
      viewability:
      viewability >=
        goalsValue.viewability_goal
          ? true
          : false,
    };
    console.log("Traffic Light Results", trafficLightsResultsLocal);
    setTrafficLightsResults(trafficLightsResultsLocal)
    }, [cpmValues]);
  useEffect(()=>{
    getCpmValues();

    getDataForVisualizations();

  },[])
  const getTrafficLightColors = () => {
    return `
            .displayCpm{
                background-color: ${trafficLightsResults.dCpm ? "mediumspringgreen" : "crimson"} !important;
            }
            .videoCpm{
                background-color: ${trafficLightsResults.vCpm ? "mediumspringgreen" : "crimson"} !important;
            }
            .ctrGoal{
                background-color: ${trafficLightsResults.ctr ? "mediumspringgreen" : "crimson"} !important;
            }
            .viewabilityGoal{
                background-color: ${trafficLightsResults.viewability ? "mediumspringgreen" : "crimson"} !important;
            }
            .fraudGoal{
                background-color: ${trafficLightsResults.fraud ? "mediumspringgreen" : "crimson"} !important;
            }
            .frequencyGoal{
                background-color: ${trafficLightsResults.frequency ? "mediumspringgreen" : "crimson"} !important;
            }
            
            
            `;
  };

  const handlePrint = useReactToPrint({
    content: () => visualsRef.current,
  });

  const getPageMargins = () => {
    return `@page { margin: ${"10px"} ${"10px"} ${"10px"} ${"10px"}; !important }`;
  };
  const setPageOrientation = () => {
    return `@page {size: landscape; !important }`
  }
  return (
    <>
    {loading ? 
    <p>Loading...</p>
  :
  <>
      <button style={{padding: "2px 5px", backgroundColor: "grey", color :"white", marginTop: "10px"}} onClick={handlePrint}>Download</button>
          <style>{getPageMargins()}</style>
          <style>{setPageOrientation()}</style>

  <div className="w-full forPrint" ref={visualsRef}>
      <div className="performanceIndicatorContainer">
        <div className="forH1">
          <style>{getTrafficLightColors()}</style>
          <h3 style={{fontSize: "20px", fontWeight: "700", marginBottom: "5px"}}>Performance Indicator</h3>
        </div>
        <div className="performanceIndicatorLightsContainer">
          {/* <div className="indicator">
            <div className="performanceIndicator ivoIndex"></div>
            <p>Ivo index</p>
          </div> */}
          {/* <div className="indicator">
            <div className="performanceIndicator displayCpm"></div>
            <p>Display CPM</p>
          </div> */}
          <div className="indicator">
            <div className="performanceIndicator displayCpm"></div>
            <p>Display CPM</p>
            <p><strong>Actual:</strong> {cpmValues ? cpmValues["Average eCPM Display"] ? cpmValues["Average eCPM Display"]+"$" : "Error" : "Error"}</p> 
            <p><strong>Goal:</strong> {goalsValue.display_cpm_goal}$</p>

          </div>
          <div className="indicator">
            <div className="performanceIndicator videoCpm"></div>
            <p>Video CPM</p>
            <p><strong>Actual:</strong> {cpmValues ? cpmValues["Average eCPM Video"] ? cpmValues["Average eCPM Video"]+"$" : "Error" : "Error"}</p> 
            <p><strong>Goal:</strong> {goalsValue.video_cpm_goal}$</p>

          </div>
          <div className="indicator">
            <div className="performanceIndicator ctrGoal"></div>
            <p>CTR Goal</p>
            <p><strong>Actual:</strong> {location?.state?.totalCalculation.CTR ? location?.state?.totalCalculation.CTR : "Error"}</p> 
            <p><strong>Goal:</strong> {goalsValue.ctr_goal}%</p>
          </div>
          <div className="indicator">
            <div className="performanceIndicator viewabilityGoal"></div>
            <p>Viewability Goal</p>
            <p><strong>Actual:</strong> { location?.state?.totalCalculation["Viewable/Total Impressions Ratio"] ? location?.state?.totalCalculation["Viewable/Total Impressions Ratio"] : "Error"}</p> 
            <p><strong>Goal:</strong> {goalsValue.viewability_goal}%</p>
          </div>
          <div className="indicator">
            <div className="performanceIndicator fraudGoal"></div>
            <p>Fraud Goal</p>
            <p><strong>Actual:</strong> { location?.state?.totalCalculation["Fraudulent/Total Impressions Ratio"] ? location?.state?.totalCalculation["Fraudulent/Total Impressions Ratio"] : "Error"}</p> 
            <p><strong>Goal:</strong> {goalsValue.fraud_goal}%</p>
          </div>
          <div className="indicator">
            <div className="performanceIndicator frequencyGoal"></div>
            <p>Frequency Goal</p>
            <p><strong>Actual:</strong> {location?.state?.totalCalculation["Impressions/Unique Reach Ratio"] ? location?.state?.totalCalculation["Impressions/Unique Reach Ratio"] : "Error"}</p> 
            <p><strong>Goal:</strong> {goalsValue.frequency_goal}</p>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <PieChart pieData={pieData} />
        <BarChart platformData={platformData} what="Platform" />
      </div>
      <div className="noBreakForPrint">
      <BarChart platformData={weekData} what="Week" />
      </div>

      {/* <AreaChart /> */}
    </div>
    </>
  
  }
    
    </>
  );
};

export default DataVisualization;
