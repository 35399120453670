import React from "react";
import "./whychoose.scss";
import Chooselogo from "../../assets/images/choose-logo.svg";
import chooseimg from "../../assets/images/choose-img.png";
const Whychoose = () => {
  return (
    <>
      <div className="whychoose-section">
        <div className="choose-content">
          <div className="title">
            <h6>Why Choose Us</h6>
          </div>
          <div className="choose-main">
            <div className="choose-left">
              <div className="left-content">
                <div className="left-logo">
                  <img src={Chooselogo} alt="..." />
                </div>
                <div className="left-title">
                  <h6>
                    Transform Your Social Media Campaigns with Automated Data
                    Analysis
                  </h6>
                </div>
                <div className="left-subtitle">
                  <h6>
                    Discover successful strategies and optimize engagement and
                    ROI with The Root’s AI and machine Learning.
                  </h6>
                </div>
              </div>
            </div>
            <div className="choose-right">
              <div className="right-content">
                <img src={chooseimg} alt="..." />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Whychoose;
