import React from "react";
import other_img from "../../assets/images/other_img.png";
import { Icon } from "@iconify/react";
import timer from "../../assets/images/timer.png";
import chart from "../../assets/images/Chart.png";
const Other_Data = [
  {
    id: 1,
    img: other_img,
    heading: "Vendor Name",
    para: "Order last time on 01/06/2022 Products: Potato, Tomato, Chilli Bill Amount: 400$",
  },
  {
    id: 2,
    img: other_img,
    heading: "Vendor Name",
    para: "Order last time on 01/06/2022 Products: Potato, Tomato, Chilli Bill Amount: 400$",
  },
  {
    id: 3,
    img: other_img,
    heading: "Vendor Name",
    para: "Order last time on 01/06/2022 Products: Potato, Tomato, Chilli Bill Amount: 400$",
  },
  {
    id: 4,
    img: other_img,
    heading: "Vendor Name",
    para: "Order last time on 01/06/2022 Products: Potato, Tomato, Chilli Bill Amount: 400$",
  },
];
function Companies() {
  return (
    <div>
      <div className="main custom_height flex gap-[138px] px-[125px] pt-[54px] pb-[95px]">
        <div className="left flex flex-col w-[30%] gap-3">
          <div className="heading">
            <h1 className="text-[#143A62]  text-[28px] Campton">
              Best Marketer
            </h1>
          </div>

          <div className="card border border-[#143A62] rounded-[20px] flex flex-col gap-10 py-[16px] px-10 ">
            {/* <div className="innercard flex gap-[10px] w-full ">
              <div className="left w-[40%]">
                <h1 className=" text-[#143A62] text-[16px] Campton">Timer</h1>
                <div className="box border border-[#143A62] rounded-[20px] p-2">
                  <img src={timer} alt="" />
                  <div className="flex justify-between gap-2 ">
                    <button className="p-2 rounded-[50%] w-[30px] h-[30px] flex items-center bg-[#F2F2F2]">
                      +
                    </button>
                    <button className="p-2 rounded-[50%] w-[30px] h-[30px] flex items-center bg-[#F2F2F2]">
                      <Icon icon="mdi:shutdown" width="16" height="16" />
                    </button>
                    <button className="p-2 rounded-[50%] w-[30px] justify-center h-[30px] flex items-center bg-[#F2F2F2]">
                      -
                    </button>
                  </div>
                </div>
              </div>
              <div className="right w-[60%]">
                <h1 className=" text-[#143A62] text-[16px] Campton">
                  Strategies
                </h1>
                <div className="box border border-[#143A62] rounded-[20px] p-5">
                  <img src={chart} alt="" />
                </div>
              </div>
            </div>

            <div className="innercard flex gap-[10px] w-full ">
              <div className="left w-[40%]">
                <h1 className=" text-[#143A62] text-[16px] Campton">Timer</h1>
                <div className="box border border-[#143A62] rounded-[20px] p-2">
                  <img src={timer} alt="" />
                  <div className="flex justify-between gap-2 ">
                    <button className="p-2 rounded-[50%] w-[30px] h-[30px] flex items-center bg-[#F2F2F2]">
                      +
                    </button>
                    <button className="p-2 rounded-[50%] w-[30px] h-[30px] flex items-center bg-[#F2F2F2]">
                      <Icon icon="mdi:shutdown" width="16" height="16" />
                    </button>
                    <button className="p-2 rounded-[50%] w-[30px] justify-center h-[30px] flex items-center bg-[#F2F2F2]">
                      -
                    </button>
                  </div>
                </div>
              </div>
              <div className="right w-[60%]">
                <h1 className=" text-[#143A62] text-[16px] Campton">
                  Strategies
                </h1>
                <div className="box border border-[#143A62] rounded-[20px] p-5">
                  <img src={chart} alt="" />
                </div>
              </div>
            </div>

            <div className="innercard flex gap-[10px] w-full ">
              <div className="left w-[40%]">
                <h1 className=" text-[#143A62] text-[16px] Campton">Timer</h1>
                <div className="box border border-[#143A62] rounded-[20px] p-2">
                  <img src={timer} alt="" />
                  <div className="flex justify-between gap-2 ">
                    <button className="p-2 rounded-[50%] w-[30px] h-[30px] flex items-center bg-[#F2F2F2]">
                      +
                    </button>
                    <button className="p-2 rounded-[50%] w-[30px] h-[30px] flex items-center bg-[#F2F2F2]">
                      <Icon icon="mdi:shutdown" width="16" height="16" />
                    </button>
                    <button className="p-2 rounded-[50%] w-[30px] justify-center h-[30px] flex items-center bg-[#F2F2F2]">
                      -
                    </button>
                  </div>
                </div>
              </div>
              <div className="right w-[60%]">
                <h1 className=" text-[#143A62] text-[16px] Campton">
                  Strategies
                </h1>
                <div className="box border border-[#143A62] rounded-[20px] p-5">
                  <img src={chart} alt="" />
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="right w-[70%]">
          <h1 className=" text-[#143A62]  text-[28px] Campton">
            Others Companies
          </h1>

          <div className="others border rounded-xl md:rounded-3xl  py-8 px-5 md:px-9 mb-24">
            <div className="other_cards flex flex-col gap-8">
              {Other_Data.map((data) => {
                return (
                  <>
                    {" "}
                    <div className="other_card cursor-pointer flex-col md:flex-row flex bill md:gap-4 2xl:gap-7 items-center px-5 md:px-7 2xl:px-14 py-6">
                      <div className="img">
                        <img src={data.img} alt="" />
                      </div>

                      <div className="content 2xl:w-2/4 flex flex-col 2xl:gap-4">
                        <h1 className=" md:text-xl 2xl:text-2xl font-medium">
                          {data.heading}
                        </h1>
                        <p className="md:text-sm text-xl">{data.para}</p>
                      </div>
                    </div>
                  </>
                );
              })}

              <div className="paginatiion flex gap-3 justify-center">
                <span className="flex  cursor-pointer justify-center items-center px-2 py-2 rounded-md border">
                  <Icon
                    icon="radix-icons:double-arrow-left"
                    width="13px"
                    height="13px"
                  />
                </span>
                <span className="flex cursor-pointer items-center justify-center px-[9px] py-2 rounded-md border">
                  <Icon
                    icon="simple-line-icons:arrow-left"
                    width="10px"
                    height="10px"
                  />
                </span>
                <span className="flex cursor-pointer bg-custom_primary text-white items-center px-[11px] py-2 rounded-md border ">
                  {" "}
                  1
                </span>
                <span className=" hidden md:flex cursor-pointer items-center px-[11px] py-2  rounded-md border">
                  2
                </span>
                <span className=" hidden md:flex cursor-pointer items-center px-[11px] py-2 rounded-md border">
                  3
                </span>
                <span className="flex cursor-pointer items-center px-[9px] py-2 rounded-md border">
                  ...
                </span>
                <span className="flex cursor-pointer items-center px-[6px] py-2 rounded-md border">
                  10
                </span>
                <span className="flex cursor-pointer items-center px-[9px] py-2 rounded-md border">
                  <Icon
                    icon="simple-line-icons:arrow-right"
                    width="10px"
                    height="10px"
                  />
                </span>
                <span className="flex cursor-pointer items-center px-2 py-2 rounded-md border">
                  <Icon
                    icon="radix-icons:double-arrow-right"
                    width="13px"
                    height="13px"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Companies;
