import React, { useEffect, useRef, useState } from "react";
import add_icon from "../../../assets/images/add_sign.png";
import upload_icon from "../../../assets/images/upload_icon.png";
import close_btn from "../../../assets/images/close_btn.png";
import photo_img from "../../../assets/images/photo_img.svg";
import Excel_img from "../../../assets/images/Excel.svg";
import PFF_Img from "../../../assets/images/PDF_img.svg";
import DOC_Img from "../../../assets/images/DOC_Img.svg";
import { Button, Modal } from "flowbite-react";
import Result from "../result/Result";
import { Icon } from "@iconify/react";
import Companies from "../Companies";

function DashboardMain() {
  const [show, setShow] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const modalRef = useRef();

  const handleOutsideClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setOpenModal(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      handleOutsideClick(event);
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);

  return (
    <div>
      {show ? (
        <>
          <Companies />
        </>
      ) : (
        <>
          <div className="dashboard_main_container px-5 md:px-0 custom_height flex justify-center items-center">
            <div
              className="upload dashboard_main duration-500 items-center flex gap-3 w-full md:w-auto px-0 md:px-20 cursor-pointer py-7 md:py-10 justify-center   rounded-3xl  "
              onClick={() => setOpenModal(true)}
            >
              <div className="img ">
                <Icon
                  icon="icon-park-outline:add-one"
                  width="26"
                  height="26"
                  color="#143A62"
                />
                {/* <img src={add_icon} alt="" className=" w-full" /> */}
              </div>

              <span className=" Campton text-[#143A62] text-xl md:text-3xl font-semibold ">
                Upload Document
              </span>
            </div>
          </div>

          <Modal
            show={openModal}
            onClose={() => setOpenModal(false)}
            className="dashboard-modal ring-0  bg-opacity-60 "
            ref={modalRef}
            size={"4xl"}
          >
            <Modal.Body className="large2 ">
              <div className="space-y-6  w-full">
                <div className="  flex justify-between my-5 md:my-7">
                  <div className=""></div>
                  <h1 className="Campton text-xl md:text-3xl font-semibold Rubik">
                    Upload Documents
                  </h1>
                  <div
                    className="close_btn cursor-pointer"
                    onClick={() => setOpenModal(false)}
                  >
                    <img src={close_btn} alt="" />
                  </div>
                </div>

                <div className="modal-body flex-col md:flex-row flex gap-5 md:gap-10 ">
                  <div className="w-full md:w-1/2 left flex flex-col gap-6 items-center">
                    <div
                      // onClick={() => {
                      //   setShow(true);
                      // }}
                      className="uplaod cursor-pointer w-11/12 py-6 md:py-12 md:py-24 text-center flex justify-center flex-col gap-2 items-center border"
                    >
                      <img src={upload_icon} alt="" />

                      <p className="Campton text-xl md:text-2xl ">
                        Drag files to upload
                      </p>
                    </div>
                    <label
                      htmlFor="ch"
                      className="Campton-light modal_btn_hover cursor-pointer  px-6 md:px-8 py-1 md:py-2 rounded-lg text-white"
                    >
                      {" "}
                      Choose file
                    </label>
                    <input type="file" id="ch" className=" hidden" />
                    {/* <button
                      type="file"
                      className=" btn_hover bg-custom_primary px-8 py-2 rounded-lg text-white"
                    >
                      Choose file
                    </button> */}
                  </div>
                  <div className="w-full md:w-1/2 right ">
                    <h1 className="Campton text-xl md:text-2xl font-medium mb-2">
                      Uploading
                    </h1>
                    <div className="boxes flex flex-col gap-2">
                      <div className="box flex items-center gap-3">
                        <div className="left-box">
                          <div className="img w-10 md:w-15 h-15 md:h-20">
                            <img
                              className="w-full h-full"
                              src={Excel_img}
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="right-box relative w-full flex flex-col">
                          <div className="top  flex justify-between">
                            <div className="type Campton">Photo.png</div>
                            <div className="size">7.5mb</div>
                            <div className="crose">
                              <Icon
                                icon="gridicons:cross"
                                width="16"
                                height="16"
                              />
                            </div>
                          </div>
                          <div className="center  h-2 bg-[#F2F2F2]   rounded">
                            <div className="blue top-0 left-0 w-1/2 h-2  bg-[#15ADDC] rounded"></div>
                          </div>
                          <div className="bottom flex justify-between">
                            <div className="percentage ">37% done</div>
                            <div className="size">90KB/sec</div>
                          </div>
                        </div>
                      </div>

                      <div className="box items-center flex gap-3">
                        <div className="left-box">
                          <div className="img w-10 md:w-15 h-15 md:h-20">
                            <img
                              className="w-full h-full"
                              src={PFF_Img}
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="right-box w-full flex flex-col">
                          <div className="top  flex justify-between">
                            <div className="type Campton">Sheet.pdf</div>
                            <div className="size">7.5mb</div>
                            <div className="crose">
                              {" "}
                              <Icon
                                icon="gridicons:cross"
                                width="16"
                                height="16"
                              />
                            </div>
                          </div>
                          <div className="center  h-2 bg-[#F2F2F2]   rounded">
                            <div className="blue top-0 left-0 w-[65%] h-2  bg-[#15ADDC]  rounded"></div>
                          </div>
                          <div className="bottom flex justify-between">
                            <div className="percentage">37% done</div>
                            <div className="size">90KB/sec</div>
                          </div>
                        </div>
                      </div>

                      <div className="box items-center flex gap-3">
                        <div className="left-box">
                          <div className="img w-10 md:w-15 h-15 md:h-20">
                            <img
                              className="w-full h-full "
                              src={DOC_Img}
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="right-box w-full flex flex-col">
                          <div className="top  flex justify-between">
                            <div className="type Campton">Data.doc</div>
                            <div className="size">7.5mb</div>
                            <div className="crose">
                              {" "}
                              <Icon
                                icon="gridicons:cross"
                                width="16"
                                height="16"
                              />
                            </div>
                          </div>
                          <div className="center  h-2 bg-[#F2F2F2]   rounded">
                            <div className="blue top-0 left-0 w-[100%] h-2  bg-[#15ADDC]  rounded"></div>
                          </div>
                          <div className="bottom flex justify-between">
                            <div className="percentage">37% done</div>
                            <div className="size">90KB/sec</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </div>
  );
}

export default DashboardMain;
