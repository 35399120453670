import React, { useState, useEffect } from 'react';
import './ZipSelected.scss';
import { Icon } from '@iconify/react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import JSZip from 'jszip';
import axios from 'axios';



const ZipSelected = () => {
    const [checkBtnOpt, setcheckBtnOpt] = useState(true);
    const [csvData, setCsvData] = useState("")
    const [unzipedFiles, setUnzipedFiles] = useState([]);
    const [selectedOption, setSelectedOption] = useState("6")
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    const location = useLocation()
    const data = [
        { id: 'file1', name: 'Data.csv' },
        { id: 'file2', name: 'Document.txt' },
        { id: 'file3', name: 'Image.png' },
    ];

    const [selectedBoxes, setSelectedBoxes] = useState([]);

    const handleBoxClick = (id) => {
        setSelectedBoxes((prevSelectedBoxes) => {
            if (prevSelectedBoxes.includes(id)) {
                return prevSelectedBoxes.filter((selectedId) => selectedId !== id);
            } else {
                return [...prevSelectedBoxes, id];
            }
        });
    };

    useEffect(() => {
        console.log("hehe", selectedBoxes);
    }, [selectedBoxes])

    const isAnyBoxSelected = selectedBoxes.length > 0;

    // const unZipFiles = async () => {
    //     const blob = new Blob([location.state.csvData], {
    //         type: 'application/x-zip-compressed'
    //     })
    //     const zip = new JSZip();
    //     const zipFile = await zip.loadAsync(location.state.csvData);

    //     const fileNames = Object.keys(zipFile.files);
    //     // setZipFileNames(fileNames);
    //     setUnzipedFiles(zipFile)

    // }

    useEffect(() => {
        console.log("Zip Multiple File", location);
        if (location?.state?.csvData) {

            console.log("location", location.state);
            setCsvData(location.state.csvData);
            // unZipFiles()
        }
    }, [])
    useEffect(() => {
        console.log("Zip Multiple Filess", unzipedFiles);

    }, [unzipedFiles])

    const submitCsvFiles = async () => {
        try {
            setLoading(true);
            const formData = new FormData();
            csvData.map((csvFile) => {
                if (selectedBoxes.includes(csvFile.fileName)) {

                    const blob = new Blob([csvFile.csv_content], { type: 'text/csv' });

                    formData.append("files", blob);
                }

            })
            formData.append("direction", selectedOption);
            console.log("fd", formData);
            console.log("let's see", (formData.get("files")));
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            };
            const response = await axios.post("https://test.techrecipes.com/upload-csv", formData, config)
            console.log("So the response is", response.data);
            // Papa.parse(response.data, {
            //     header: true,
            //     dynamicTyping: true,
            //     complete: (result) => {
            //         console.log(result.data);
            //         setCsvData(result.data)
            //     },
            // });
            // setCsvData(response.data)
            // setcheckBtnOpt(false)
            handleStructure(response.data)
        }
        catch (e) {
            setLoading(false);

        }
    }
    const handleStructure = async (csvFile) => {
        try {
            const blob = new Blob([csvFile], { type: 'text/csv' });
            // const formData = new FormData();
            // formData.append("selected_column", "Ad name");
            // formData.append("file", blob);
            // const config = {
            //     headers: {
            //         'Content-Type': 'multipart/form-data'
            //     },
            // };
            // const response = await axios.post("https://api1.techrecipes.com/process-csv", formData, config)

            let data = new FormData();
            const expectedHeaders = [
                "Inventory Type",
                "Data Targeting",
                "Size/Length",
                "Platform Impressions",
                "Impressions",
                "Impression",
                "Ad name",
                "Ad Name",
                "Net Spend",
                "Week",
                // "Brand",
                "Campaign Objective",

                "Platform",
                "Measurable",
                "Clicks",
            ]

            data.append('selected_column', expectedHeaders.find((data) => (csvFile.includes(data))));
            data.append('file', blob);
            // data.append('selected_column', "Ad Name");

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://api1.techrecipes.com/process-csv',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: data
            };
            axios.request(config)
                .then((response) => {
                    console.log("Our data", JSON.stringify(response.data));
                    console.log("Saad bhai");
                    // var csvData = new Blob([response.data.csv_content], { type: 'text/csv;charset=utf-8;' });
                    // var csvURL = null;
                    // if (navigator.msSaveBlob) {
                    //     csvURL = navigator.msSaveBlob(csvData, 'download.csv');
                    // }
                    // else {
                    //     csvURL = window.URL.createObjectURL(csvData);
                    // }

                    // var tempLink = document.createElement('a');
                    // tempLink.href = csvURL;
                    // tempLink.setAttribute('download', "Aggregiate Sheet" + '.csv');
                    // tempLink.click();
                    setLoading(false);

                    navigate('/dashboard/uploadFile/extramultiplefile', { state: { csvData: response.data.csv_content } });

                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                });

            // console.log("So the response is ai", response.data);
            // Papa.parse(response.data, {
            //     header: true,
            //     dynamicTyping: true,
            //     complete: (result) => {
            //         console.log(result.data);
            //         setCsvData(result.data)
            //     },
            // });

        }
        catch (e) {
            setLoading(false);

            console.log("Error Occured", e);
        }
    }
    const handleOptionSelect = (event) => {
        const selectedOption = event.target.value;
        setSelectedOption(selectedOption);
    };
    return (
        <>
            {checkBtnOpt ? (
                <>
                    <div className='extrazipfile-container'>
                    <h1 style={{fontSize: "16px", fontWeight: "800", marginBottom: "15px"}}>Please select files you want to include in the analysis</h1>

                        {csvData && csvData.length > 0 && csvData.map((csvData, index) => (
                            <div
                                key={index}
                                className={`extrazipfile-box ${selectedBoxes.includes(csvData.fileName) ? 'selected' : ''}`}
                                onClick={() => handleBoxClick(csvData.fileName)}
                            >
                                <Icon icon='flat-color-icons:document' className='extrazipfile-icon' />
                                <p>
                                    File: <span>{csvData.fileName}</span>
                                </p>
                            </div>
                        ))}
                    </div>
                    <div className="inline-block relative w-full mt-3 ">
                        <select
                            className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                            onChange={handleOptionSelect} value={selectedOption}
                        >
                           <option value="">Select direction</option>
                            {/* <option value="1">Direction 1</option>
                            <option value="2">Direction 2</option>
                            <option value="3">Direction 3</option>
                            <option value="4">Direction 4</option> */}
                            <option value="5">top to bottom join</option>
                            <option value="6" selected>left to right join</option>
                            <option value="7">Right to left join</option>
                            <option value="8">bottom to top join</option>
                        </select>
                        {selectedOption && (
                        <p className="Campton text-lg text-black font-semibold mt-2">
                            Selected Option: Files will be joined {selectedOption == "5" ? "top to bottom"
                            :

                            selectedOption == "6" ? "left to right"
                            :

                            selectedOption == "7" ? "Right to left"
                            :

                            selectedOption == "8" ? "bottom to top"
                            :
                            "No option Selected"
                            }
                        </p>
                    )}
                    </div>
                    {loading ?
                    
                <p>Uploading and processing files...</p>
            :
            ""
            }
                    {isAnyBoxSelected && selectedOption !="" && (
                        <button className='upload-button save mt-5 ml-5 text-white py-[5px] px-[35px] rounded text-lg' disabled={loading} onClick={submitCsvFiles}>
                            Upload Selected Files
                        </button>
                    )}
                </>
            ) : (
                <div className='checkstructured-btn-container gap-2 w-full'>
                    <Link to="/dashboard/uploadFile/structure" state={{ csvData: csvData }}>
                        <button className="save mt-5 text-white py-[5px] px-[35px] rounded text-lg">
                            Structured
                        </button>
                    </Link>
                    <Link to="/dashboard/uploadFile/nonsturctured" state={{ csvData: csvData }}>
                        <button className="save mt-5 text-white py-[5px] px-[35px] rounded text-lg" >
                            UnStructured
                        </button>
                    </Link>
                </div>
            )}

        </>
    );
};

export default ZipSelected;