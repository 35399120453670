import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useState, useContext, useEffect, useRef } from "react";
import "./chatbot.scss";
import { SavedFileContext } from "../../../context/SaveedFileContext";
import { Exception } from "sass";
import { toast } from "react-toastify";
import axios from "axios";
import { useLocation } from "react-router-dom";
import BarChartChatbot from "./BarChartChatbot";
const samplePrompt = [
  "Mr. Price, could you please indicate the amount of non-working value versus working value for each of the five guardrails I'm utilizing? These include Display CPM, Video CPM, CTR, Viewability, and Fraud.",
  "Mr. Price, how much money are we spending, in total, on days that don't meet any of our five guardrails?",
  "Mr. Price, which are the top five ad groups on each platform with the highest nonworking costs?",
];

const Chatbot = () => {
  const { currentlyOpened, setCurrentlyOpened, fileForChat } =
    useContext(SavedFileContext);
  const [userMessage, setUserMessage] = useState("");
  const [messagesHistory, setMessagesHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [promptContainer, setPromptContainer] = useState(false);
  const [secondPromptData, setSecondPromptData] = useState((false));
  const messagesContainerRef = useRef();
  const location = useLocation();

  useEffect(() => {
    console.log("In Chatbot", fileForChat);
  }, [fileForChat]);

  const secondPrompt = async (message) => {
    

        try {
          setLoading(true);
            const blob = new Blob([fileForChat], { type: 'text/csv' });


            let data = new FormData();
            data.append('file', blob);
           


            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `https://lastapi.techrecipes.com/upload-csv-analysis?base_col=${"Day"}&primary_col=${"Non Working Media"}`,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: data
            };
            axios.request(config)
                .then((response) => {
                    console.log("filter", response.data);
                    if (response.data["Sum filter"]){
                      console.log("bro", JSON.parse(response.data["Sum filter"]));
                      let tempData = JSON.parse(response.data["Sum filter"]);
                      let dayValues =  tempData.map((data)=>(
                        data["Day"]
                      ))
                      let nwValues = tempData.map((data)=>(
                        data["Non Working Media"]
                      ))
                      console.log("d", [...dayValues, ...nwValues]);

                      // setSecondPromptData({dayValues, nwValues})
                      setMessagesHistory((prev) => {
                        return [
                          ...prev,
                          { userType: "ai", message: message, isGraph: true, dayValues, nwValues},
                        ];
                      });
                      }
                      

                  
                    // setFilterColumn(Object.keys(JSON.parse(response.data[selectedApi])[0]).map((csvColumnName, index) => (
                    //     { title: csvColumnName, dataIndex: csvColumnName, key: index, ...getColumnSearchProps(csvColumnName) }
                    // )))
                    // setFilterData(JSON.parse(response.data[selectedApi]))
                    
                    // console.log("Helloooo", selectedApi);
                    // setCurrentTable("Filtered")
                    setLoading(false);
                })
                .catch((error) => {
                  setLoading(false);
                    console.log(error);
                });

        }
        catch (e) {
          setLoading(false);

        }


    
}

const thirdPrompt = async (message) => {
    console.log("here in third");

  try {
    setLoading(true)
      const blob = new Blob([fileForChat], { type: 'text/csv' });


      let data = new FormData();
      data.append('file', blob);
     


      let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `https://lastapi.techrecipes.com/upload-csv-analysis?base_col=${"Ad Group Name"}&primary_col=${"Non Working Media"}`,
          headers: {
              'Content-Type': 'multipart/form-data'
          },
          data: data
      };
      axios.request(config)
          .then(async (response) => {
              console.log("filter", response.data);
              config.url = `https://lastapi.techrecipes.com/upload-csv-analysis?base_col=${"Platform"}&primary_col=${"Non Working Media"}`
              let newRes = await axios.request(config);
              
              if (response.data["Sum filter"] && newRes.data["Sum filter"]){
                console.log("bro", JSON.parse(response.data["Sum filter"]));
                let tempData = JSON.parse(response.data["Sum filter"]);
                let dayValues =  tempData.map((data)=>(
                  data["Ad Group Name"]
                ))
                let nwValues = tempData.map((data)=>(
                  data["Non Working Media"]
                ))
                //
                let tempDataNew = JSON.parse(newRes.data["Sum filter"]);
                let dayValuesNew =  tempDataNew.map((data)=>(
                  data["Platform"]
                ))
                let nwValuesNew = tempDataNew.map((data)=>(
                  data["Non Working Media"]
                ))
                console.log("d", [...dayValuesNew, ...nwValuesNew]);
//
                // setSecondPromptData({dayValues, nwValues})
                setMessagesHistory((prev) => {
                  return [
                    ...prev,
                    { userType: "ai", message: message, isGraph: true, noOfGraphs: 2, graphs: [{dayValues, nwValues}, {dayValues:dayValuesNew, nwValues:nwValuesNew}]},
                  ];
                });
                }
              else if (response.data["Sum filter"]){
                console.log("bro", JSON.parse(response.data["Sum filter"]));
                let tempData = JSON.parse(response.data["Sum filter"]);
                let dayValues =  tempData.map((data)=>(
                  data["Ad Group Name"]
                ))
                let nwValues = tempData.map((data)=>(
                  data["Non Working Media"]
                ))
                console.log("d", [...dayValues, ...nwValues]);

                // setSecondPromptData({dayValues, nwValues})
                setMessagesHistory((prev) => {
                  return [
                    ...prev,
                    { userType: "ai", message: message, isGraph: true, dayValues, nwValues},
                  ];
                });
                }
              else if (newRes.data["Sum filter"]){
                console.log("bro", JSON.parse(newRes.data["Sum filter"]));
                let tempData = JSON.parse(newRes.data["Sum filter"]);
                let dayValues =  tempData.map((data)=>(
                  data["Platform"]
                ))
                let nwValues = tempData.map((data)=>(
                  data["Non Working Media"]
                ))
                console.log("d", [...dayValues, ...nwValues]);

                // setSecondPromptData({dayValues, nwValues})
                setMessagesHistory((prev) => {
                  return [
                    ...prev,
                    { userType: "ai", message: message, isGraph: true, dayValues, nwValues},
                  ];
                });
                }
                

            
              // setFilterColumn(Object.keys(JSON.parse(response.data[selectedApi])[0]).map((csvColumnName, index) => (
              //     { title: csvColumnName, dataIndex: csvColumnName, key: index, ...getColumnSearchProps(csvColumnName) }
              // )))
              // setFilterData(JSON.parse(response.data[selectedApi]))
              
              // console.log("Helloooo", selectedApi);
              // setCurrentTable("Filtered")
              setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
              console.log(error);
          });

  }
  catch (e) {
    setLoading(false);

  }



}

const onePrompt = async (message) => {
  console.log("here in third");

try {
  setLoading(true);
    const blob = new Blob([fileForChat], { type: 'text/csv' });


    let data = new FormData();
    data.append('file', blob);
   


    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `https://lastapi.techrecipes.com/upload-csv-analysis?base_col=${"Week"}&primary_col=${"Non Working Media"}`,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data: data
    };
    axios.request(config)
        .then(async (response) => {
            console.log("filter", response.data);
            config.url = `https://lastapi.techrecipes.com/upload-csv-analysis?base_col=${"Week"}&primary_col=${"Working Media"}`
            let newRes = await axios.request(config);
            
            if (response.data["Sum filter"] && newRes.data["Sum filter"]){
              console.log("bro", JSON.parse(response.data["Sum filter"]));
              let tempData = JSON.parse(response.data["Sum filter"]);
              let dayValues =  tempData.map((data)=>(
                data["Week"]
              ))
              let nwValues = tempData.map((data)=>(
                data["Non Working Media"]
              ))
              //
              let tempDataNew = JSON.parse(newRes.data["Sum filter"]);
              let dayValuesNew =  tempDataNew.map((data)=>(
                data["Week"]
              ))
              let nwValuesNew = tempDataNew.map((data)=>(
                data["Working Media"]
              ))
              console.log("d", [...dayValuesNew, ...nwValuesNew]);
//
              // setSecondPromptData({dayValues, nwValues})
              setMessagesHistory((prev) => {
                return [
                  ...prev,
                  { userType: "ai", message: message, isGraph: true, noOfGraphs: 2, graphs: [{dayValues, nwValues}, {dayValues:dayValuesNew, nwValues:nwValuesNew, type: true}]},
                ];
              });
              }
            else if (response.data["Sum filter"]){
              console.log("bro", JSON.parse(response.data["Sum filter"]));
              let tempData = JSON.parse(response.data["Sum filter"]);
              let dayValues =  tempData.map((data)=>(
                data["Week"]
              ))
              let nwValues = tempData.map((data)=>(
                data["Non Working Media"]
              ))
              console.log("d", [...dayValues, ...nwValues]);

              // setSecondPromptData({dayValues, nwValues})
              setMessagesHistory((prev) => {
                return [
                  ...prev,
                  { userType: "ai", message: message, isGraph: true, dayValues, nwValues},
                ];
              });
              }
            else if (newRes.data["Sum filter"]){
              console.log("bro", JSON.parse(newRes.data["Sum filter"]));
              let tempData = JSON.parse(newRes.data["Sum filter"]);
              let dayValues =  tempData.map((data)=>(
                data["Week"]
              ))
              let nwValues = tempData.map((data)=>(
                data["Working Media"]
              ))
              console.log("d", [...dayValues, ...nwValues]);

              // setSecondPromptData({dayValues, nwValues})
              setMessagesHistory((prev) => {
                return [
                  ...prev,
                  { userType: "ai", message: message, isGraph: true, dayValues, nwValues, type: true},
                ];
              });
              }
              

          
            // setFilterColumn(Object.keys(JSON.parse(response.data[selectedApi])[0]).map((csvColumnName, index) => (
            //     { title: csvColumnName, dataIndex: csvColumnName, key: index, ...getColumnSearchProps(csvColumnName) }
            // )))
            // setFilterData(JSON.parse(response.data[selectedApi]))
            
            // console.log("Helloooo", selectedApi);
            // setCurrentTable("Filtered")
            setLoading(false);

        })
        .catch((error) => {
          setLoading(false);

            console.log(error);
        });

}
catch (e) {
  setLoading(false);

}



}

useEffect(()=>{

  console.log("ss", secondPromptData);
},[secondPromptData])

  const sendQuery = async ({fixedQuery}) => {
    console.log("hehe", fixedQuery);
    if (userMessage != "" || fixedQuery) {
      try {
        setLoading(true);
        setMessagesHistory((prev) => {
          return [...prev, { userType: "user", message: fixedQuery || userMessage }];
        });
        setUserMessage("");
        const blob = new Blob([fileForChat], { type: "text/csv" });
        let data = new FormData();
        data.append("file", blob);
        data.append("user_prompt", fixedQuery || userMessage);
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: "https://myapi.techrecipes.com/upload-csv-and-query/",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: data,
        };
        const withCtrAndOther = await axios.request(config);
        console.log("Chatbot Response", withCtrAndOther.data);
        if (!fixedQuery){

          setMessagesHistory((prev) => {
            return [
              ...prev,
              { userType: "ai", message: withCtrAndOther.data.response },
            ];
          });
          setLoading(false);

        }  
        else if (fixedQuery == "Mr. Price, how much money are we spending, in total, on days that don't meet any of our five guardrails?"){

          await secondPrompt(withCtrAndOther.data.response);
        }
        else if (fixedQuery == "Mr. Price, which are the top five ad groups on each platform with the highest nonworking costs?"){

          await thirdPrompt(withCtrAndOther.data.response);
        }
        else if (fixedQuery == "Mr. Price, could you please indicate the amount of non-working value versus working value for each of the five guardrails I'm utilizing? These include Display CPM, Video CPM, CTR, Viewability, and Fraud."){

          await onePrompt(withCtrAndOther.data.response);
        }

        
      } catch (e) {
        setLoading(false);

        toast.error("Error Sending Message");
        console.log("Error Sending Message", e);
      }
    } else {
      toast.error("Please write message first");
    }
  };
  useEffect(() => {
    console.log("Messages", messagesHistory);
  }, [messagesHistory]);

  const scrollToBottom = () => {
    messagesContainerRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messagesHistory]);

  const handleQueryPrompts = () => {

    setPromptContainer(prev => !prev)

  };
  const handleFixedPromptClick = (prompt) => {
setPromptContainer(false)
sendQuery({fixedQuery: prompt});

  };

  return (
    <div className="chatbotContainer">
      {currentlyOpened ? (
        <div
          className="chatbotMain"
          style={{ display: currentlyOpened ? "block" : "none" }}
        >
          <div className="chatbotHeader">
            <h2>Mr. Price</h2>
            <div className="crossBtn" >
            <Icon icon="pajamas:clear-all" width="28" height="28"  style={{color: "#fff"}} onClick={()=>setMessagesHistory([])}/>
              <Icon
                icon="charm:cross"
                width="48"
                height="48"
                style={{ color: "#fff" }}
                onClick={() => setCurrentlyOpened(false)}
              />
            </div>
          </div>
          <div className="chatbotMessageBox">
            <div className="messages">
              {messagesHistory.length > 0
                ? messagesHistory.map((messagesObj) => {
                    return messagesObj.userType == "user" ? (
                      <div className="message user">
                        <p className="">{messagesObj.message}</p>
                      </div>
                    ) : (
                      <>
                      <div className="message ai">
                        <p className="">{messagesObj.message}</p>
                      </div>
                      {messagesObj.isGraph ?
                      messagesObj.noOfGraphs ?
                        messagesObj.graphs.map((data)=>(
                          data?.dayValues?.length > 0 && 
                    <BarChartChatbot platformName={data.dayValues}  platformData={data.nwValues} what="Bar Chart" graphName={data.type ? data.type : false}></BarChartChatbot>  

                        ))

                      :

                    <BarChartChatbot platformName={messagesObj.dayValues}  platformData={messagesObj.nwValues} what="Bar Chart" graphName={messagesObj.type ? messagesObj.type : false} ></BarChartChatbot>  
                    
                  :
                  null
                  }
                      </>
                    );
                  })
                : ""}
              <div ref={messagesContainerRef}></div>

              {/* <div className="message user">
<p className="">
    Hello
</p>
</div>
<div className="message ai">
<p className="">
    Hi, how can I help you
</p>
</div> */}

{promptContainer ?
  <div className="promptSuggestions">
                <h3>Select any prompt</h3>
                {samplePrompt?.map((sample) => (
                  <div className="prompt" onClick={()=>handleFixedPromptClick(sample)}>{sample}</div>
                ))}
              </div>
:

null}
              
            </div>

            <div className="messageInput">
              {promptContainer ?
            <Icon
            icon="teenyicons:down-solid"
            width="30"
            height="30"
            style={{ color: "#57AEDC", cursor: "pointer" }}
            onClick={handleQueryPrompts}
           
          />
          
          :


              <Icon
                icon="teenyicons:up-solid"
                width="30"
                height="30"
                style={{ color: "#57AEDC", cursor: "pointer" }}
                onClick={handleQueryPrompts}
               
              />
            }
              <input
                className="focus:border-transparent focus:ring-0"
                type="text"
                placeholder="Write Your Query"
                value={userMessage}
                onChange={(e) => {
                  setUserMessage(e.target.value);
                }}
              />
              {loading ? (
                <Icon
                  icon="eos-icons:three-dots-loading"
                  width="48"
                  height="48"
                  style={{ color: "#57AEDC" }}
                />
              ) : (
                <Icon
                  icon="tabler:send"
                  width="30"
                  height="30"
                  style={{ color: "#57AEDC", cursor: "pointer" }}
                  onClick={sendQuery}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          className="chatbotIcon"
          onClick={() => {
            if (fileForChat) {
              setCurrentlyOpened(true);
            } else {
              toast.error("Process any file first to use chatbot.");
            }
          }}
        >
          <Icon
            icon="simple-icons:chatbot"
            width="55"
            height="55"
            style={{ color: "#57AEDC" }}
          />
        </div>
      )}
    </div>
  );
};

export default Chatbot;
