import React, { useState, useEffect, useRef } from 'react'
import { Button, Input, Space, Table } from 'antd'
import '../../../components/DataTables/DataTables.css'
import axios from 'axios';
import { Link, json, useLocation } from 'react-router-dom';
import Papa from "papaparse"

import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { toast } from 'react-toastify';

const AuditSheet = () => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [currentTable, setCurrentTable] = useState("Measurable")
    const [resultsCount, setResultsCount] = useState(0);
    const [totalCalculation, setTotalCalculation] = useState(false);
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    // const dataSource = [
    //     {
    //         key: '1',
    //         Measurable: 'Mike',
    //         SUM_of_Opportunity_Index: 32,
    //         Net_Spend: '10 Downing Street',

    //         Impressions: 'Mike',
    //         Clicks: 32,
    //         SUM_of_CTR: '10 Downing Street',

    //         SUM_of_eCPM: 'Mike',
    //         SUM_of_CPC: 32,
    //         SUM_of_OTR: '10 Downing Street',

    //         SUM_of_Viewability: 'Mike',
    //         SUM_of_Fraud: 32,
    //         SUM_of_Frequency: '10 Downing Street',

    //         SUM_of_Avg_OT_CTR: 'Mike',
    //         SUM_of_OTF: 32,
    //         SUM_of_NF_vCPM: '10 Downing Street',

    //         SUM_of_eCPMH: 'Mike',
    //         SUM_of_OTR_vCPM: 32,
    //         SUM_of_qCPM: '10 Downing Street',

    //         SUM_of_VCR: 'Mike',
    //         SUM_of_vCPCV: 32,
    //         SUM_of_OT_vCPCV: '10 Downing Street',

    //         SUM_of_hCPCV: 'Mike',
    //         SUM_of_qCPH: 32,
    //         SUM_of_ROAS: '10 Downing Street',

    //         SUM_of_CPL: 'Mike',
    //         SUM_of_qCPL: 32,
    //         SUM_of_CPS: 32,

    //         SUM_of_qCPS: '10 Downing Street',

    //     },
    //     {
    //         key: '2',
    //         Measurable: 'Mike',
    //         SUM_of_Opportunity_Index: 32,
    //         Net_Spend: '10 Downing Street',

    //         Impressions: 'Mike',
    //         Clicks: 32,
    //         SUM_of_CTR: '10 Downing Street',

    //         SUM_of_eCPM: 'Mike',
    //         SUM_of_CPC: 32,
    //         SUM_of_OTR: '10 Downing Street',

    //         SUM_of_Viewability: 'Mike',
    //         SUM_of_Fraud: 32,
    //         SUM_of_Frequency: '10 Downing Street',

    //         SUM_of_Avg_OT_CTR: 'Mike',
    //         SUM_of_OTF: 32,
    //         SUM_of_NF_vCPM: '10 Downing Street',

    //         SUM_of_eCPMH: 'Mike',
    //         SUM_of_OTR_vCPM: 32,
    //         SUM_of_qCPM: '10 Downing Street',

    //         SUM_of_VCR: 'Mike',
    //         SUM_of_vCPCV: 32,
    //         SUM_of_OT_vCPCV: '10 Downing Street',

    //         SUM_of_hCPCV: 'Mike',
    //         SUM_of_qCPH: 32,
    //         SUM_of_ROAS: '10 Downing Street',

    //         SUM_of_CPL: 'Mike',
    //         SUM_of_qCPL: 32,
    //         SUM_of_CPS: 32,

    //         SUM_of_qCPS: '10 Downing Street',

    //     },
    //     {
    //         key: '1',
    //         Measurable: 'Mike',
    //         SUM_of_Opportunity_Index: 32,
    //         Net_Spend: '10 Downing Street',

    //         Impressions: 'Mike',
    //         Clicks: 32,
    //         SUM_of_CTR: '10 Downing Street',

    //         SUM_of_eCPM: 'Mike',
    //         SUM_of_CPC: 32,
    //         SUM_of_OTR: '10 Downing Street',

    //         SUM_of_Viewability: 'Mike',
    //         SUM_of_Fraud: 32,
    //         SUM_of_Frequency: '10 Downing Street',

    //         SUM_of_Avg_OT_CTR: 'Mike',
    //         SUM_of_OTF: 32,
    //         SUM_of_NF_vCPM: '10 Downing Street',

    //         SUM_of_eCPMH: 'Mike',
    //         SUM_of_OTR_vCPM: 32,
    //         SUM_of_qCPM: '10 Downing Street',

    //         SUM_of_VCR: 'Mike',
    //         SUM_of_vCPCV: 32,
    //         SUM_of_OT_vCPCV: '10 Downing Street',

    //         SUM_of_hCPCV: 'Mike',
    //         SUM_of_qCPH: 32,
    //         SUM_of_ROAS: '10 Downing Street',

    //         SUM_of_CPL: 'Mike',
    //         SUM_of_qCPL: 32,
    //         SUM_of_CPS: 32,

    //         SUM_of_qCPS: '10 Downing Street',

    //     },
    //     {
    //         key: '2',
    //         Measurable: 'Mike',
    //         SUM_of_Opportunity_Index: 32,
    //         Net_Spend: '10 Downing Street',

    //         Impressions: 'Mike',
    //         Clicks: 32,
    //         SUM_of_CTR: '10 Downing Street',

    //         SUM_of_eCPM: 'Mike',
    //         SUM_of_CPC: 32,
    //         SUM_of_OTR: '10 Downing Street',

    //         SUM_of_Viewability: 'Mike',
    //         SUM_of_Fraud: 32,
    //         SUM_of_Frequency: '10 Downing Street',

    //         SUM_of_Avg_OT_CTR: 'Mike',
    //         SUM_of_OTF: 32,
    //         SUM_of_NF_vCPM: '10 Downing Street',

    //         SUM_of_eCPMH: 'Mike',
    //         SUM_of_OTR_vCPM: 32,
    //         SUM_of_qCPM: '10 Downing Street',

    //         SUM_of_VCR: 'Mike',
    //         SUM_of_vCPCV: 32,
    //         SUM_of_OT_vCPCV: '10 Downing Street',

    //         SUM_of_hCPCV: 'Mike',
    //         SUM_of_qCPH: 32,
    //         SUM_of_ROAS: '10 Downing Street',

    //         SUM_of_CPL: 'Mike',
    //         SUM_of_qCPL: 32,
    //         SUM_of_CPS: 32,

    //         SUM_of_qCPS: '10 Downing Street',

    //     }
    // ];
    // useEffect(() => {
    //     setColumns([

    //         // {
    //         //     title: 'SUM of Opportunity Index',
    //         //     dataIndex: 'SUM_of_Opportunity_Index',
    //         //     key: 'SUM of Opportunity Index',
    //         // },
    //         {
    //             title: 'Net Spend',
    //             dataIndex: 'Total Sum of Net Spend',
    //             key: 'Net Spend',
    //             ...getColumnSearchProps('Total Sum of Net Spend'),
    //         },
    //         {
    //             title: 'Platform Impressions',
    //             dataIndex: 'Total Sum of Platform Impressions',
    //             key: 'Impressions',
    //             ...getColumnSearchProps('Total Sum of Impressions'),

    //         },
    //         {
    //             title: 'Clicks',
    //             dataIndex: 'Total Sum of Clicks',
    //             key: 'Clicks',
    //             ...getColumnSearchProps('Total Sum of Clicks'),
    //         },
    //         {
    //             title: 'CTR',
    //             dataIndex: 'CTR',
    //             key: 'CTR',
    //             ...getColumnSearchProps('CTR')
    //         },
    //         {
    //             title: 'eCPM',
    //             dataIndex: 'Net Spend per 1000 Impressions',
    //             key: 'eCPM',
    //             ...getColumnSearchProps('Net Spend per 1000 Impressions')
    //         },
    //         {
    //             title: 'CPC',
    //             dataIndex: 'Net Spend per 1000',
    //             key: 'CPC',
    //             ...getColumnSearchProps('Net Spend per 1000')
    //         },
    //         // {
    //         //     title: 'OTR',
    //         //     dataIndex: 'Reach/Impression',
    //         //     key: 'OTR',
    //         //     ...getColumnSearchProps('Reach/Impression')
    //         // },
    //         {
    //             title: 'Viewability',
    //             dataIndex: 'Viewable/Total Impressions Ratio',
    //             key: 'Viewability',
    //             ...getColumnSearchProps('Viewable/Total Impressions Ratio')
    //         },
    //         {
    //             title: 'Fraud',
    //             dataIndex: 'Fraudulent/Total Impressions Ratio',
    //             key: 'Fraud',
    //             ...getColumnSearchProps('Fraudulent/Total Impressions Ratio')
    //         },
    //         {
    //             title: 'Frequency',
    //             dataIndex: 'Impressions/Unique Reach Ratio',
    //             key: 'Frequency',
    //             ...getColumnSearchProps('Impressions/Unique Reach Ratio')
    //         },
    //         // {
    //         //     title: 'Avg. OT CTR',
    //         //     dataIndex: 'On Target Ratio',
    //         //     key: 'Avg. OT CTR',
    //         //     ...getColumnSearchProps('On Target Ratio')
    //         // },
    //         // {
    //         //     title: 'OTF',
    //         //     dataIndex: 'Targeted Reach/Unique Reach',
    //         //     key: 'OTF',
    //         //     ...getColumnSearchProps('Targeted Reach/Unique Reach')
    //         // },
    //         {
    //             title: 'NF vCPM',
    //             dataIndex: 'Custom Metric A',
    //             key: 'NF vCPM',
    //             ...getColumnSearchProps('Custom Metric A')
    //         },
    //         {
    //             title: 'eCPMH',
    //             dataIndex: 'Adjusted eCPMh',
    //             key: 'eCPMH',
    //             ...getColumnSearchProps('Adjusted eCPMh')
    //         },
    //         // {
    //         //     title: 'OTR vCPM',
    //         //     dataIndex: 'New Metric B',
    //         //     key: 'OTR vCPM',
    //         //     ...getColumnSearchProps('New Metric B')
    //         // },
    //         {
    //             title: 'qCPM',
    //             dataIndex: 'qCPM',
    //             key: 'qCPM',
    //             ...getColumnSearchProps('qCPM')
    //         },
    //         {
    //             title: 'VCR',
    //             dataIndex: 'Video Completion Rate',
    //             key: 'VCR',
    //             ...getColumnSearchProps('Video Completion Rate')
    //         },
    //         {
    //             title: 'vCPCV',
    //             dataIndex: 'vCPCV',
    //             key: 'vCPCV',
    //             ...getColumnSearchProps('vCPCV')
    //         },
    //         {
    //             title: 'OT vCPCV',
    //             dataIndex: 'OT_vCPCV',
    //             key: 'SOT vCPCV',
    //             ...getColumnSearchProps('OT_vCPCV')
    //         },
    //         {
    //             title: 'hCPCV',
    //             dataIndex: 'hCPCV',
    //             key: 'hCPCV',
    //             ...getColumnSearchProps('hCPCV')
    //         },
    //         // {
    //         //     title: 'qCPH',
    //         //     dataIndex: 'vCPM',
    //         //     key: 'qCPH',
    //         //     ...getColumnSearchProps('vCPM')
    //         // },
    //         {
    //             title: 'ROAS',
    //             dataIndex: 'Revenue to Net Spend',
    //             key: 'ROAS',
    //             ...getColumnSearchProps('Revenue to Net Spend')
    //         },
    //         // {
    //         //     title: 'CPL',
    //         //     dataIndex: 'Cost Per Lead',
    //         //     key: 'CPL',
    //         //     ...getColumnSearchProps('Cost Per Lead')
    //         // },
    //         // {
    //         //     title: 'qCPL',
    //         //     dataIndex: 'qCPL',
    //         //     key: 'qCPL',
    //         //     ...getColumnSearchProps('qCPL')
    //         // },
    //         // {
    //         //     title: 'CPS',
    //         //     dataIndex: 'Net Spend to Sales',
    //         //     key: 'CPS',
    //         //     ...getColumnSearchProps('Net Spend to Sales')
    //         // },
    //         // {
    //         //     title: 'qCPS',
    //         //     dataIndex: 'Adjusted CPS',
    //         //     key: 'qCPS',
    //         //     ...getColumnSearchProps('Adjusted CPS')
    //         // },
    //         // {
    //         //     title: 'Average Reach',
    //         //     dataIndex: 'Impressions_per_Unique_Reach',
    //         //     key: 'Average Reach',
    //         //     ...getColumnSearchProps('TImpressions_per_Unique_Reach')
    //         // },
    //         // {
    //         //     title: 'Percentage on Target',
    //         //     dataIndex: 'Targeted Reach Ratio',
    //         //     key: 'Percentage on Target',
    //         //     ...getColumnSearchProps('Targeted Reach Ratio')
    //         // },
    //         {
    //             title: 'CPA',
    //             dataIndex: 'Cost per Conversion',
    //             key: 'CPA',
    //             ...getColumnSearchProps('Cost per Conversion')
    //         },
    //         {
    //             title: 'CPCV',
    //             dataIndex: 'Cost per Completion',
    //             key: 'CPCV',
    //             ...getColumnSearchProps('Cost per Completion')
    //         },
    //         {
    //             title: 'vCPM',
    //             dataIndex: 'vCPM',
    //             key: 'CPCV',
    //             ...getColumnSearchProps('vCPM')
    //         },
    //         // {
    //         //     title: 'OTI',
    //         //     dataIndex: 'Off-Target Impressions',
    //         //     key: 'CPCV',
    //         //     ...getColumnSearchProps('Off-Target Impressions')
    //         // },
    //         // {
    //         //     title: 'CPCV',
    //         //     dataIndex: 'Cost per Completion',
    //         //     key: 'CPCV',
    //         //     ...getColumnSearchProps('Cost per Completion')
    //         // },
    //     ])
    // }, [])

    useEffect(() => {
        setColumns( [

            // {
            //     title: 'SUM of Opportunity Index',
            //     dataIndex: 'SUM_of_Opportunity_Index',
            //     key: 'SUM of Opportunity Index',
            // },
            {
                title: 'Net Spend',
                dataIndex: 'Total Sum of Net Spend',
                key: 'Net Spend',
            },
            {
                title: 'Platform Impressions',
                dataIndex: 'Total Sum of Platform Impressions',
                key: 'Impressions',
            },
            {
                title: 'Clicks',
                dataIndex: 'Total Sum of Clicks',
                key: 'Clicks',
            },
            {
                title: 'CTR',
                dataIndex: 'CTR',
                key: 'CTR',
            },
            {
                title: 'eCPM',
                dataIndex: 'Net Spend per 1000 Impressions',
                key: 'eCPM',
            },
            {
                title: 'CPC',
                dataIndex: 'Net Spend per 1000',
                key: 'CPC',
            },
            {
                title: 'VCR',
                dataIndex: 'Video Completion Rate',
                key: 'VCR',
            },
            {
                title: 'CPCV',
                dataIndex: 'Cost per Completion',
                key: 'CPCV',
            },
            
            {
                title: 'Viewability %',
                dataIndex: 'Viewable/Total Impressions Ratio',
                key: 'Viewability',
            },
            {
                title: 'vCPCV',
                dataIndex: 'vCPCV',
                key: 'vCPCV',
            },
            {
                title: 'hCPCV',
                dataIndex: 'hCPCV',
                key: 'hCPCV',
            },
            {
                title: 'vCPM',
                dataIndex: 'vCPM',
                key: 'CPCV',
            },
            {
                title: 'Fraud %',
                dataIndex: 'Fraudulent/Total Impressions Ratio',
                key: 'Fraud',
            },
            {
                title: 'NF vCPM',
                dataIndex: 'Custom Metric A',
                key: 'NF vCPM',
            },
           
           
           
            
            {
                title: 'eCPMH',
                dataIndex: 'Adjusted eCPMh',
                key: 'eCPMH',
            },
           
            {
                title: 'qCPM',
                dataIndex: 'qCPM',
                key: 'qCPM',
            },
            {
                title: 'Frequency',
                dataIndex: 'Impressions/Unique Reach Ratio',
                key: 'Frequency',
            },
            {
                title: 'OT vCPCV',
                dataIndex: 'OT_vCPCV',
                key: 'SOT vCPCV',
            },
            
           
            {
                title: 'ROAS',
                dataIndex: 'Revenue to Net Spend',
                key: 'ROAS',
            },
           
            {
                title: 'CPA',
                dataIndex: 'Cost per Conversion',
                key: 'CPA',
            },
            
            
            
           
            // {
            //     title: 'CPCV',
            //     dataIndex: 'Cost per Completion',
            //     key: 'CPCV',
            // },
            {
                title: 'Working Media',
                dataIndex: 'Total Sum of Working Media',
                key: 'Working Media',
            },
            {
                title: 'Waste Media',
                dataIndex: 'Total Sum of Waste Media',
                key: 'Non Working Media',
            },
        ])
    }, [])
    const columnss = [

        // {
        //     title: 'SUM of Opportunity Index',
        //     dataIndex: 'SUM_of_Opportunity_Index',
        //     key: 'SUM of Opportunity Index',
        // },
        {
            title: 'Net Spend',
            dataIndex: 'Total Sum of Net Spend',
            key: 'Net Spend',
            ...getColumnSearchProps('Total Sum of Net Spend'),
        },
        {
            title: 'Impressions',
            dataIndex: 'Total Sum of Platform Impressions',
            key: 'Impressions',
            ...getColumnSearchProps('Total Sum of Impressions'),

        },
        {
            title: 'Clicks',
            dataIndex: 'Total Sum of Clicks',
            key: 'Clicks',
            ...getColumnSearchProps('Total Sum of Clicks'),
        },
        {
            title: 'CTR',
            dataIndex: 'CTR',
            key: 'CTR',
            ...getColumnSearchProps('CTR')
        },
        {
            title: 'eCPM',
            dataIndex: 'Net Spend per 1000 Impressions',
            key: 'eCPM',
            ...getColumnSearchProps('Net Spend per 1000 Impressions')
        },
        {
            title: 'CPC',
            dataIndex: 'Net Spend per 1000',
            key: 'CPC',
            ...getColumnSearchProps('Net Spend per 1000')
        },
        {
            title: 'OTR',
            dataIndex: 'Reach/Impression',
            key: 'OTR',
            ...getColumnSearchProps('Reach/Impression')
        },
        {
            title: 'Viewability',
            dataIndex: 'Viewable/Total Impressions Ratio',
            key: 'Viewability',
            ...getColumnSearchProps('Viewable/Total Impressions Ratio')
        },
        {
            title: 'Fraud',
            dataIndex: 'Fraudulent/Total Impressions Ratio',
            key: 'Fraud',
            ...getColumnSearchProps('Fraudulent/Total Impressions Ratio')
        },
        {
            title: 'Frequency',
            dataIndex: 'Impressions/Unique Reach Ratio',
            key: 'Frequency',
            ...getColumnSearchProps('Impressions/Unique Reach Ratio')
        },
        {
            title: 'Avg. OT CTR',
            dataIndex: 'On Target Ratio',
            key: 'Avg. OT CTR',
            ...getColumnSearchProps('On Target Ratio')
        },
        {
            title: 'OTF',
            dataIndex: 'Targeted Reach/Unique Reach',
            key: 'OTF',
            ...getColumnSearchProps('Targeted Reach/Unique Reach')
        },
        {
            title: 'NF vCPM',
            dataIndex: 'Custom Metric A',
            key: 'NF vCPM',
            ...getColumnSearchProps('Custom Metric A')
        },
        {
            title: 'eCPMH',
            dataIndex: 'Adjusted eCPMh',
            key: 'eCPMH',
            ...getColumnSearchProps('Adjusted eCPMh')
        },
        {
            title: 'OTR vCPM',
            dataIndex: 'New Metric B',
            key: 'OTR vCPM',
            ...getColumnSearchProps('New Metric B')
        },
        {
            title: 'qCPM',
            dataIndex: 'qCPM',
            key: 'qCPM',
            ...getColumnSearchProps('qCPM')
        },
        {
            title: 'VCR',
            dataIndex: 'Video Completion Rate',
            key: 'VCR',
            ...getColumnSearchProps('Video Completion Rate')
        },
        {
            title: 'vCPCV',
            dataIndex: 'vCPCV',
            key: 'vCPCV',
            ...getColumnSearchProps('vCPCV')
        },
        {
            title: 'OT vCPCV',
            dataIndex: 'OT_vCPCV',
            key: 'SOT vCPCV',
            ...getColumnSearchProps('OT_vCPCV')
        },
        {
            title: 'hCPCV',
            dataIndex: 'hCPCV',
            key: 'hCPCV',
            ...getColumnSearchProps('hCPCV')
        },
        {
            title: 'qCPH',
            dataIndex: 'vCPM',
            key: 'qCPH',
            ...getColumnSearchProps('vCPM')
        },
        {
            title: 'ROAS',
            dataIndex: 'Revenue to Net Spend',
            key: 'ROAS',
            ...getColumnSearchProps('Revenue to Net Spend')
        },
        {
            title: 'CPL',
            dataIndex: 'Cost Per Lead',
            key: 'CPL',
            ...getColumnSearchProps('Cost Per Lead')
        },
        {
            title: 'qCPL',
            dataIndex: 'qCPL',
            key: 'qCPL',
            ...getColumnSearchProps('qCPL')
        },
        {
            title: 'CPS',
            dataIndex: 'Net Spend to Sales',
            key: 'CPS',
            ...getColumnSearchProps('Net Spend to Sales')
        },
        {
            title: 'qCPS',
            dataIndex: 'Adjusted CPS',
            key: 'qCPS',
            ...getColumnSearchProps('Adjusted CPS')
        },
        {
            title: 'Average Reach',
            dataIndex: 'Impressions_per_Unique_Reach',
            key: 'Average Reach',
            ...getColumnSearchProps('TImpressions_per_Unique_Reach')
        },
        {
            title: 'Percentage on Target',
            dataIndex: 'Targeted Reach Ratio',
            key: 'Percentage on Target',
            ...getColumnSearchProps('Targeted Reach Ratio')
        },
        {
            title: 'CPA',
            dataIndex: 'Cost per Conversion',
            key: 'CPA',
            ...getColumnSearchProps('Cost per Conversion')
        },
        {
            title: 'CPCV',
            dataIndex: 'Cost per Completion',
            key: 'CPCV',
            ...getColumnSearchProps('Cost per Completion')
        },
        {
            title: 'vCPM',
            dataIndex: 'vCPM',
            key: 'CPCV',
            ...getColumnSearchProps('vCPM')
        },
        {
            title: 'OTI',
            dataIndex: 'Off-Target Impressions',
            key: 'CPCV',
            ...getColumnSearchProps('Off-Target Impressions')
        },
        {
            title: 'CPCV',
            dataIndex: 'Cost per Completion',
            key: 'CPCV',
            ...getColumnSearchProps('Cost per Completion')
        },
    ];

    const [tableMeasurable, setTableMeasurable] = useState([])
    const [tableCampaign, setTableCampaign] = useState([])
    const [tableWeek, setTableWeek] = useState([])
    const [tableBrand, setTableBrand] = useState([])
    const [tableInventory, setTableInventory] = useState([])
    const [tablePlatform, setTablePlatform] = useState([])
    const [tableDataTargeting, setTableDataTargeting] = useState([])
    const [tableSize, setTableSize] = useState([])
    const [csvData, setCsvData] = useState("");
    const [oldColumnName, setOldColumnName] = useState("");
    const [newColumnName, setNewColumnName] = useState("");
    const [columns, setColumns] = useState([{}])
    const [filterColumn, setFilterColumn] = useState([{}]);
    const [filterdata, setFilterData] = useState([{}]);
    const [loading, setLoading] = useState(false);
    const [dropDown, setDropDown] = useState([])
    const location = useLocation();
    const justForTest = async () => {
        const blob = new Blob([location.state.csvData], { type: 'text/csv' });


        let data = new FormData();
        data.append('file', blob);


        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://mywork.techrecipes.com/upload-csv-CTR/',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: data
        };
        config.url = "https://mywork.techrecipes.com/upload-csv-OT_CTR/"
        axios.request(config)
            .then((response) => {
                if (response.data.detail) { return; } if (response.data.detail) { return; }
                console.log("Our audit data", response.data);
                console.log("Saad bhai");
                setResultsCount(prev => prev + 1);
                setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




                setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))



            })
            .catch((error) => {
                console.log("ohh", error);
            });
    }
    const metaNew = async () => {
        try {
            setLoading(true);

            const blob = new Blob([location.state.csvData], { type: 'text/csv' });


            let data = new FormData();
            data.append('file', blob);


            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://mywork.techrecipes.com/upload-csv-analysis/',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: data
            };

            let response = await axios.request(config);

            Object.keys(response.data).map((key, index)=>{
                console.log("Here in key map ", key);
                Object.keys(response.data[key]).map((key2, index2)=>{
                    response.data[key][key2].map((data, index3)=>{
                        Object.keys(response.data[key][key2][index3]).map((key4, index4)=>{
                            if (response.data[key][key2][index3][key4] == "nan" || response.data[key][key2][index3][key4] == "nan%" || response.data[key][key2][index3][key4] == "nan$"){
                                response.data[key][key2][index3][key4] = "0"
                            }
                        })
                        
                        
                    })
                })
                // if (response.data[key] == "nan"){
                // response.data[key] = "0"
                // }
            })
            console.log("After filtering", response.data);

            let localTableMeasureable = [];
            Object.keys(response.data).map((key, indexMain) => {
                setTableMeasurable(prev => {
                    if (response.data[key].Measurable) {

                        console.log((response.data[key].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                        return ((response.data[key].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                    }
                    else {
                        console.log("d", prev);
                        return prev
                    }

                }
                )

                setTableCampaign(prev => {
                    if (response.data[key]["Campaign Objective"]) {

                        console.log((response.data[key]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                        return ((response.data[key]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                    }
                    else {
                        console.log("d", prev);

                        return prev;
                    }
                }
                )




                // setTableCampaign(prev => {
                //     return (response.data[key]["Week"]?.map((data, index) => ({ ...data, ...prev[index] })))

                // })


                setTableBrand(prev => {
                    if (response.data[key]["Brand"]) {

                        return (response.data[key]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] })))
                    }
                    else {
                        console.log("d", prev);

                        return prev;
                    }
                })


                setTableInventory(prev => {
                    if (response.data[key]["Inventory Type"]) {

                        return (response.data[key]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] })))
                    }
                    else {
                        console.log("d", prev);

                        return prev;
                    }
                })


                setTablePlatform(prev => {
                    if (response.data[key]["Platform"]) {

                        return (response.data[key]["Platform"]?.map((data, index) => ({ ...data, ...prev[index] })))
                    }
                    else {
                        console.log("d", prev);

                        return prev;
                    }
                })


                setTableDataTargeting(prev => {
                    if (response.data[key]["Data Targeting"]) {

                        return (response.data[key]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] })))
                    }
                    else {
                        console.log("d", prev);

                        return prev;
                    }
                })


                setTableSize(prev => {
                    if (response.data[key]["Size/Length"]) {

                        return (response.data[key]["Size/Length"]?.map((data, index) => ({ ...data, ...prev[index] })))
                    }
                    else {
                        console.log("d", prev);

                        return prev
                    }
                })
                setTableWeek(prev => {
                    if (response.data[key]["Week"]) {

                        // console.log("In week", response.data[key]["Week"]?.map((data, index) => ({ ...data })));
                        return (response.data[key]["Week"]?.map((data, index) => ({ ...data, ...prev[index] })))
                    }
                    else {
                        console.log("d", prev);

                        return prev
                    }
                })

                // console.log("Let's see", tableMeasurable);
                // console.log("Let's see", tableCampaign);
                // console.log("Let's see", tableBrand);
                // console.log("Let's see", tableInventory);
                // console.log("Let's see", tablePlatform);
                // console.log("Let's see", tableDataTargeting);
                // console.log("Let's see", tableSize);
                // console.log("Let's see", tableWeek);

            })
            config.url = "https://mywork.techrecipes.com/upload-csv-total_sum_m_T/"
            const responseMore = await axios.request(config);
            console.log(responseMore.data);
            setTableMeasurable(prev => {
                if (responseMore.data["Total Sums"].Measurable) {

                    console.log((responseMore.data["Total Sums"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                    return ((responseMore.data["Total Sums"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                }
                else {
                    return prev
                }

            }
            )

            setTableCampaign(prev => {

                // console.log((responseMore.data["Total Sums"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                if (responseMore.data["Total Sums"]["Campaign Objective"])
                    return ((responseMore.data["Total Sums"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                else {
                    return prev
                }
            }
            )




            // setTableCampaign(prev => {
            //     return (responseMore.data["Total Sums"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] })))

            // })


            setTableBrand(prev => {
                if (responseMore.data["Total Sums"]["Brand"])
                    return (responseMore.data["Total Sums"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] })))
                else {
                    return prev
                }
            })


            setTableInventory(prev => {
                if (responseMore.data["Total Sums"]["Inventory Type"])
                    return (responseMore.data["Total Sums"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] })))
                else {
                    return prev
                }
            })


            setTablePlatform(prev => {
                if (responseMore.data["Total Sums"]["Platform"])
                    return (responseMore.data["Total Sums"]["Platform"]?.map((data, index) => ({ ...data, ...prev[index] })))
                else {
                    return prev
                }
            })


            setTableDataTargeting(prev => {
                if (responseMore.data["Total Sums"]["Data Targeting"])
                    return (responseMore.data["Total Sums"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] })))
                else {
                    return prev
                }
            })


            setTableSize(prev => {
                if (responseMore.data["Total Sums"]["Size/Length"])
                    return (responseMore.data["Total Sums"]["Size/Length"]?.map((data, index) => ({ ...data, ...prev[index] })))
                else {
                    return prev
                }
            })
            setTableWeek(prev => {
                // console.log("In week", responseMore.data["Total Sums"]["Week"]?.map((data, index) => ({ ...data })));
                if (responseMore.data["Total Sums"]["Week"])
                    return (responseMore.data["Total Sums"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] })))
                else {
                    return prev
                }
            })
            getTotal();
        }
        catch (e) {
            setLoading(false);

        }
    }
    // const metaMapping = () => {
    //     try {
    //         const blob = new Blob([location.state.csvData], { type: 'text/csv' });


    //         let data = new FormData();
    //         data.append('file', blob);


    //         let config = {
    //             method: 'post',
    //             maxBodyLength: Infinity,
    //             url: 'https://mywork.techrecipes.com/upload-csv-CTR/',
    //             headers: {
    //                 'Content-Type': 'multipart/form-data'
    //             },
    //             data: data
    //         };
    //         axios.request(config)
    //             .then((response) => {
    //                 console.log("tests", response.data);
    //                 if (response.data.detail) { return; }
    //                 console.log("Our audit data", response.data);
    //                 console.log("Saad bhai");
    //                 setResultsCount(prev => prev + 1);
    //                 setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




    //                 setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))

    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 setResultsCount(prev => prev + 1);
    //             });
    //         // Other API

    //         config.url = "https://mywork.techrecipes.com/upload-csv-SUM of Impressions,Net Spend,Clicks/"
    //         axios.request(config)
    //             .then((response) => {
    //                 if (response.data.detail) { return; }
    //                 console.log("Our audit data", response.data);
    //                 console.log("Saad bhai");
    //                 setResultsCount(prev => prev + 1);
    //                 setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




    //                 setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))

    //                 setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))

    //                 setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 // setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 // setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))



    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 setResultsCount(prev => prev + 1);
    //             });

    //         config.url = "https://mywork.techrecipes.com/upload-csv-eCPM/"
    //         axios.request(config)
    //             .then((response) => {
    //                 if (response.data.detail) { return; }
    //                 console.log("Our audit data", response.data);
    //                 console.log("Saad bhai");
    //                 setResultsCount(prev => prev + 1);
    //                 setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




    //                 setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 setResultsCount(prev => prev + 1);
    //             });

    //         // Other API

    //         config.url = "https://mywork.techrecipes.com/upload-csv-CPC/"
    //         axios.request(config)
    //             .then((response) => {
    //                 if (response.data.detail) { return; }
    //                 console.log("Our audit data", response.data);
    //                 console.log("Saad bhai");
    //                 setResultsCount(prev => prev + 1);
    //                 setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




    //                 setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 setResultsCount(prev => prev + 1);
    //             });

    //         // Other API

    //         config.url = "https://mywork.techrecipes.com/upload-csv-ORT/"
    //         axios.request(config)
    //             .then((response) => {
    //                 if (response.data.detail) { return; }
    //                 console.log("Our audit data", response.data);
    //                 console.log("Saad bhai");
    //                 setResultsCount(prev => prev + 1);
    //                 setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




    //                 setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))



    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 setResultsCount(prev => prev + 1);
    //             });

    //         // Other API

    //         config.url = "https://mywork.techrecipes.com/upload-csv-Viewability/"
    //         axios.request(config)
    //             .then((response) => {
    //                 if (response.data.detail) { return; }
    //                 console.log("Our audit data", response.data);
    //                 console.log("Saad bhai");
    //                 setResultsCount(prev => prev + 1);
    //                 setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




    //                 setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 setResultsCount(prev => prev + 1);
    //             });

    //         // Other API

    //         config.url = "https://mywork.techrecipes.com/upload-csv-Fraud/"
    //         axios.request(config)
    //             .then((response) => {
    //                 if (response.data.detail) { return; }
    //                 console.log("Our audit data", response.data);
    //                 console.log("Saad bhai");
    //                 setResultsCount(prev => prev + 1);
    //                 setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




    //                 setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 setResultsCount(prev => prev + 1);
    //             });

    //         // Other API

    //         config.url = "https://mywork.techrecipes.com/upload-csv-AverageFrequency/"
    //         axios.request(config)
    //             .then((response) => {
    //                 console.log("broo", response.data);
    //                 if (response.data.detail) {

    //                     return;
    //                 }
    //                 console.log("Our audit data", response.data);
    //                 console.log("Saad bhai");
    //                 setResultsCount(prev => prev + 1);
    //                 setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




    //                 setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 setResultsCount(prev => prev + 1);
    //             });

    //         // Other API

    //         config.url = "https://mywork.techrecipes.com/upload-csv-OT_CTR/"
    //         axios.request(config)
    //             .then((response) => {
    //                 if (response.data.detail) { return; }
    //                 console.log("Our audit data", response.data);
    //                 console.log("Saad bhai");
    //                 setResultsCount(prev => prev + 1);
    //                 setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




    //                 setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))



    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 setResultsCount(prev => prev + 1);
    //             });

    //         // Other API

    //         config.url = "https://mywork.techrecipes.com/upload-csv-OTF/"
    //         axios.request(config)
    //             .then((response) => {
    //                 if (response.data.detail) { return; }
    //                 console.log("Our audit data", response.data);
    //                 console.log("Saad bhai");
    //                 setResultsCount(prev => prev + 1);
    //                 setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




    //                 setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))

    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 setResultsCount(prev => prev + 1);
    //             });

    //         // Other API

    //         config.url = "https://mywork.techrecipes.com/upload-csv-NF_vCPM/"
    //         axios.request(config)
    //             .then((response) => {
    //                 if (response.data.detail) { return; }
    //                 console.log("Our audit data", response.data);
    //                 console.log("Saad bhai");
    //                 setResultsCount(prev => prev + 1);
    //                 setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




    //                 setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 setResultsCount(prev => prev + 1);
    //             });
    //         // Other API

    //         config.url = "https://mywork.techrecipes.com/upload-csv-eCPMh/"
    //         axios.request(config)
    //             .then((response) => {
    //                 if (response.data.detail) { return; }
    //                 console.log("Our audit data", response.data);
    //                 console.log("Saad bhai");
    //                 setResultsCount(prev => prev + 1);
    //                 setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




    //                 setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 setResultsCount(prev => prev + 1);
    //             });
    //         // Other API

    //         config.url = "https://mywork.techrecipes.com/upload-csv-OTvCPM/"
    //         axios.request(config)
    //             .then((response) => {
    //                 if (response.data.detail) { return; }
    //                 console.log("Our audit data", response.data);
    //                 console.log("Saad bhai");
    //                 setResultsCount(prev => prev + 1);
    //                 setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




    //                 setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))



    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 setResultsCount(prev => prev + 1);
    //             });
    //         // Other API

    //         // config.url = "https://mywork.techrecipes.com/upload-csv-eCPMh/"
    //         // axios.request(config)
    //         //     .then((response) => {
    //         //         if (response.data.detail) { return; }
    //         //         console.log("Our audit data", response.data);
    //         //         console.log("Saad bhai");
    //         setResultsCount(prev => prev + 1);
    //         //         setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
    //         //         setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




    //         //         setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //         //         setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //         //         setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //         //         setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //         //         setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //         //         setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
    //         //         setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))



    //         //     })
    //         //     .catch((error) => {
    //         //         console.log(error);
    //         setResultsCount(prev => prev + 1);
    //         //     });
    //         // Other API

    //         config.url = "https://mywork.techrecipes.com/upload-csv-qCPM/"
    //         axios.request(config)
    //             .then((response) => {
    //                 if (response.data.detail) { return; }
    //                 console.log("Our audit data", response.data);
    //                 console.log("Saad bhai");
    //                 setResultsCount(prev => prev + 1);
    //                 setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




    //                 setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 setResultsCount(prev => prev + 1);
    //             });
    //         // Other API

    //         config.url = "https://mywork.techrecipes.com/upload-csv-VCR/"
    //         axios.request(config)
    //             .then((response) => {
    //                 if (response.data.detail) { return; }
    //                 console.log("Our audit data", response.data);
    //                 console.log("Saad bhai");
    //                 setResultsCount(prev => prev + 1);
    //                 setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




    //                 setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 setResultsCount(prev => prev + 1);
    //             });
    //         // Other API

    //         config.url = "https://mywork.techrecipes.com/upload-csv-vCPCV/"
    //         axios.request(config)
    //             .then((response) => {
    //                 if (response.data.detail) { return; }
    //                 console.log("Our audit data", response.data);
    //                 console.log("Saad bhai");
    //                 setResultsCount(prev => prev + 1);
    //                 setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




    //                 setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))



    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 setResultsCount(prev => prev + 1);
    //             });

    //         // Other API

    //         config.url = "https://mywork.techrecipes.com/upload-csv-OT_vCPCV/"
    //         axios.request(config)
    //             .then((response) => {
    //                 if (response.data.detail) { return; }
    //                 console.log("Our audit data", response.data);
    //                 console.log("Saad bhai");
    //                 setResultsCount(prev => prev + 1);
    //                 setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




    //                 setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 setResultsCount(prev => prev + 1);
    //             });

    //         // Other API

    //         config.url = "https://mywork.techrecipes.com/upload-csv-hCPCV/"
    //         axios.request(config)
    //             .then((response) => {
    //                 if (response.data.detail) { return; }
    //                 console.log("Our audit data", response.data);
    //                 console.log("Saad bhai");
    //                 setResultsCount(prev => prev + 1);
    //                 setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




    //                 setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 setResultsCount(prev => prev + 1);
    //             });
    //         // Other API

    //         config.url = "https://mywork.techrecipes.com/upload-csv-qCPH/"
    //         axios.request(config)
    //             .then((response) => {
    //                 if (response.data.detail) { return; }
    //                 console.log("Our audit data", response.data);
    //                 console.log("Saad bhai");
    //                 setResultsCount(prev => prev + 1);
    //                 setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




    //                 setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 setResultsCount(prev => prev + 1);
    //             });
    //         // Other API

    //         config.url = "https://mywork.techrecipes.com/upload-csv-ROAS/"
    //         axios.request(config)
    //             .then((response) => {
    //                 if (response.data.detail) { return; }
    //                 console.log("Our audit data", response.data);
    //                 console.log("Saad bhai");
    //                 setResultsCount(prev => prev + 1);
    //                 setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




    //                 setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 setResultsCount(prev => prev + 1);
    //             });
    //         // Other API

    //         config.url = "https://mywork.techrecipes.com/upload-csv-CPL/"
    //         axios.request(config)
    //             .then((response) => {
    //                 if (response.data.detail) { return; }
    //                 console.log("Our audit data", response.data);
    //                 console.log("Saad bhai");
    //                 setResultsCount(prev => prev + 1);
    //                 setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




    //                 setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))



    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 setResultsCount(prev => prev + 1);
    //             });
    //         // Other API

    //         config.url = "https://mywork.techrecipes.com/upload-csv-qCPL/"
    //         axios.request(config)
    //             .then((response) => {
    //                 if (response.data.detail) { return; }
    //                 console.log("Our audit data", response.data);
    //                 console.log("Saad bhai");
    //                 setResultsCount(prev => prev + 1);
    //                 setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




    //                 setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))



    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 setResultsCount(prev => prev + 1);
    //             });
    //         // Other API

    //         config.url = "https://mywork.techrecipes.com/upload-csv-CPS/"
    //         axios.request(config)
    //             .then((response) => {
    //                 if (response.data.detail) { return; }
    //                 console.log("Our audit data", response.data);
    //                 console.log("Saad bhai");
    //                 setResultsCount(prev => prev + 1);
    //                 setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




    //                 setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 setResultsCount(prev => prev + 1);
    //             });
    //         // Other API

    //         config.url = "https://mywork.techrecipes.com/upload-csv-qCPS/"
    //         axios.request(config)
    //             .then((response) => {
    //                 if (response.data.detail) { return; }
    //                 console.log("Our audit data", response.data);
    //                 console.log("Saad bhai");
    //                 setResultsCount(prev => prev + 1);
    //                 setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




    //                 setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))

    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 setResultsCount(prev => prev + 1);
    //             });
    //         // Other API

    //         config.url = "https://mywork.techrecipes.com/upload-csv-Average_Reach/"
    //         axios.request(config)
    //             .then((response) => {
    //                 if (response.data.detail) { return; }
    //                 console.log("Our audit data", response.data);
    //                 console.log("Saad bhai");
    //                 setResultsCount(prev => prev + 1);
    //                 setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




    //                 setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))



    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 setResultsCount(prev => prev + 1);
    //             });
    //         // Other API

    //         config.url = "https://mywork.techrecipes.com/upload-csv-Percentage_on_target/"
    //         axios.request(config)
    //             .then((response) => {
    //                 if (response.data.detail) { return; }
    //                 console.log("Our audit data", response.data);
    //                 console.log("Saad bhai");
    //                 setResultsCount(prev => prev + 1);
    //                 setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




    //                 setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))

    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 setResultsCount(prev => prev + 1);
    //             });
    //         // Other API

    //         config.url = "https://mywork.techrecipes.com/upload-csv-CPA/"
    //         axios.request(config)
    //             .then((response) => {
    //                 if (response.data.detail) { return; }
    //                 console.log("Our audit data", response.data);
    //                 console.log("Saad bhai");
    //                 setResultsCount(prev => prev + 1);
    //                 setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




    //                 setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))



    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 setResultsCount(prev => prev + 1);
    //             });
    //         // Other API

    //         config.url = "https://mywork.techrecipes.com/upload-csv-CPCV/"
    //         axios.request(config)
    //             .then((response) => {
    //                 if (response.data.detail) { return; }
    //                 console.log("Our audit data", response.data);
    //                 console.log("Saad bhai");
    //                 setResultsCount(prev => prev + 1);
    //                 setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




    //                 setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))



    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 setResultsCount(prev => prev + 1);
    //             });
    //         // Other API

    //         config.url = "https://mywork.techrecipes.com/upload-csv-vCPM/"
    //         axios.request(config)
    //             .then((response) => {
    //                 if (response.data.detail) { return; }
    //                 console.log("Our audit data", response.data);
    //                 console.log("Saad bhai");
    //                 setResultsCount(prev => prev + 1);
    //                 setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




    //                 setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))



    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 setResultsCount(prev => prev + 1);
    //             });
    //         // Other API

    //         config.url = "https://mywork.techrecipes.com/upload-csv-OTI/"
    //         axios.request(config)
    //             .then((response) => {
    //                 if (response.data.detail) { return; }
    //                 console.log("Our audit data", response.data);
    //                 console.log("Saad bhai");
    //                 setResultsCount(prev => prev + 1);
    //                 setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




    //                 setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))



    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 setResultsCount(prev => prev + 1);
    //             });
    //         // Other API

    //         config.url = "https://mywork.techrecipes.com/upload-csv-CPCV/"
    //         axios.request(config)
    //             .then((response) => {
    //                 if (response.data.detail) { return; }
    //                 console.log("Our audit data", response.data);
    //                 console.log("Saad bhai");
    //                 setResultsCount(prev => prev + 1);
    //                 setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




    //                 setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))



    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 setResultsCount(prev => prev + 1);
    //             });
    //         // Other API

    //         config.url = "https://mywork.techrecipes.com/upload-csv-CPCV/"
    //         axios.request(config)
    //             .then((response) => {
    //                 if (response.data.detail) { return; }
    //                 console.log("Our audit data", response.data);
    //                 console.log("Saad bhai");
    //                 setResultsCount(prev => prev + 1);
    //                 setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




    //                 setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))



    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 setResultsCount(prev => prev + 1);
    //             });
    //         // Other API

    //         config.url = "https://mywork.techrecipes.com/upload-csv-CPCV/"
    //         axios.request(config)
    //             .then((response) => {
    //                 if (response.data.detail) { return; }
    //                 console.log("Our audit data", response.data);
    //                 console.log("Saad bhai");
    //                 setResultsCount(prev => prev + 1);
    //                 setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




    //                 setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


    //                 setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
    //                 setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))



    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 setResultsCount(prev => prev + 1);
    //             });





    //     }
    //     catch (e) {
    //         console.log("Error Occured", e);
    //     }
    // }
    const getTotal = async () => {
        const blob = new Blob([location.state.csvData], { type: 'text/csv' });


        let data = new FormData();
        data.append('file', blob);
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://cof.techrecipes.com/upload-csv-analysis/',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: data
        };
        try {
            const response = await axios.request(config);

            Object.keys(response.data).map((key, index)=>{
                console.log("Here in key map ", key);
                if (response.data[key] == "nan" || response.data[key] == "nan$" || response.data[key] == "nan%"){
                response.data[key] = "0"
                }
            })
            console.log("After filtering", response.data);

            config.url = 'https://cof.techrecipes.com/upload-csv-total_sum_m_T/';
            const responseOther = await axios.request(config);

            if (response.data.detail || responseOther.data.detail) { return; }
            Object.keys(responseOther.data["Total total_sum-m-T"]).map((key, index)=>{
                console.log("Here in key map ", key);
                if (responseOther.data["Total total_sum-m-T"][key] == "nan" || responseOther.data["Total total_sum-m-T"][key] == "$nan" || responseOther.data["Total total_sum-m-T"][key] == "%nan"){
                    responseOther.data["Total total_sum-m-T"][key] = "0"
                }
            })
            console.log("After filtering", response.data);
            
            console.log("Our audit data", response.data);
            console.log("Saad bhai");
            setTotalCalculation({...response.data, ...responseOther.data})

            Array.isArray(tableMeasurable) && setTableMeasurable(prev => ([...prev, { ...response.data, Measurable: "Total", ...responseOther.data["Total total_sum-m-T"] }]))
            Array.isArray(tableCampaign) && setTableCampaign(prev => ([...prev, { ...response.data, "Campaign Objective": "Total", ...responseOther.data["Total total_sum-m-T"] }]))
            Array.isArray(tableWeek) && setTableWeek(prev => ([...prev, { ...response.data, Week: "Total", ...responseOther.data["Total total_sum-m-T"] }]))
            Array.isArray(tableBrand) && setTableBrand(prev => ([...prev, { ...response.data, "Brand": "Total", ...responseOther.data["Total total_sum-m-T"] }]))
            Array.isArray(tableInventory) && setTableInventory(prev => ([...prev, { ...response.data, "Inventory Type": "Total", ...responseOther.data["Total total_sum-m-T"] }]))
            Array.isArray(tablePlatform) && setTablePlatform(prev => ([...prev, { ...response.data, "Platform": "Total", ...responseOther.data["Total total_sum-m-T"] }]))
            Array.isArray(tableDataTargeting) && setTableDataTargeting(prev => ([...prev, { ...response.data, "Data Targeting": "Total", ...responseOther.data["Total total_sum-m-T"] }]))
            Array.isArray(tableSize) && setTableSize(prev => ([...prev, { ...response.data, "Size/Length": "Total", ...responseOther.data["Total total_sum-m-T"] }]))
            setLoading(false);

        }
        catch (error) {
            setLoading(false);

            console.log("Error");

        }





    }
    useEffect(() => {
        console.log("Extra Multiple File", location);
        if (location?.state?.csvData) {

            console.log("location", location.state);
            Papa.parse(location.state.csvData, {
                header: true,
                dynamicTyping: true,
                complete: (result) => {
                    // console.log(result.data);
                    // setCsvData(result.data)
                    setDropDown(Object.keys(result.data[0]))
                },
            });
            // setCsvData(location.state.csvData);
            // metaMapping();
            metaNew();
            // setTableColumn(Object.keys(location.state.csvData[0]).map((csvColumnName) => (
            //     { title: csvColumnName, dataIndex: csvColumnName }
            // )))

        }
    }, [])

    // useState(() => {
    //     setColumns(columnss)
    // }, [currentTable])

    ///////////////////////////

    // const columns = [
    //     {
    //         title: 'Net Spend',
    //         dataIndex: 'Total Sum of Net Spend',
    //         key: 'Net Spend',
    //         width: '30%',
    //         ...getColumnSearchProps('Total Sum of Net Spend'),
    //     },
    //     {
    //         title: 'Age',
    //         dataIndex: 'age',
    //         key: 'age',
    //         width: '20%',
    //         ...getColumnSearchProps('age'),
    //     },
    //     {
    //         title: 'Address',
    //         dataIndex: 'address',
    //         key: 'address',
    //         ...getColumnSearchProps('address'),
    //         sorter: (a, b) => a.address.length - b.address.length,
    //         sortDirections: ['descend', 'ascend'],
    //     },
    // ];

    const handleDownload = () => {
        let allTables = [{
            name: "Measurable",
            table: tableMeasurable
        },
        {
            name: "Campaign Objective",
            table: tableCampaign
        },
        {
            name: "Week",
            table: tableWeek
        },
        {
            name: "Brand",
            table: tableBrand
        },
        {
            name: "Inventory Type",
            table: tableInventory
        },
        {
            name: "Platform",
            table: tablePlatform
        },
        {
            name: "Data Targeting",
            table: tableDataTargeting
        },
        {
            name: "Size",
            table: tableSize
        },];

        const desiredTable = allTables.find((table) => {
            return currentTable == table.name
        })
        console.log("hehe", desiredTable);

        // let forTest = [...tableMeasurable, ...tableBrand]
        const csv = Papa.unparse(desiredTable.table);
        console.log("CSV", csv);
        var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        var csvURL = null;
        if (navigator.msSaveBlob) {
            csvURL = navigator.msSaveBlob(csvData, 'download.csv');
        }
        else {
            csvURL = window.URL.createObjectURL(csvData);
        }

        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', desiredTable.name + '.csv');
        tempLink.click();
    }

    const handleFilter = async () => {
        if (oldColumnName == newColumnName) {
            toast.error("Base and Primary Column should not be same!")
        }
        else {


            try {
                const blob = new Blob([location.state.csvData], { type: 'text/csv' });


                let data = new FormData();
                data.append('file', blob);
                // data.append("base_col", oldColumnName)
                // data.append("primary_col", newColumnName)


                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: `https://lastapi.techrecipes.com/upload-csv-analysis?base_col=${oldColumnName}&primary_col=${newColumnName}`,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    data: data
                };
                axios.request(config)
                    .then((response) => {
                        console.log("filter", response.data);
                        console.log("filterSelectedAPI", selectedApi);
                        console.log("filterSelectedAPI", oldColumnName);
                        console.log("filterSelectedAPI", newColumnName);
                        // if (response.data.detail) { return; }
                        // console.log("Our audit data", response.data);
                        // console.log("Saad bhai");
                        // setTableMeasurable(prev => (response.data["CTR Results"].Measurable?.map((data, index) => ({ ...data, ...prev[index] }))))
                        // setTableCampaign(prev => (response.data["CTR Results"]["Campaign Objective"]?.map((data, index) => ({ ...data, ...prev[index] }))))




                        // setTableCampaign(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                        // setTableBrand(prev => (response.data["CTR Results"]["Brand"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                        // setTableInventory(prev => (response.data["CTR Results"]["Inventory Type"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                        // setTablePlatform(prev => (response.data["CTR Results"]["Platform/Vendor"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                        // setTableDataTargeting(prev => (response.data["CTR Results"]["Data Targeting"]?.map((data, index) => ({ ...data, ...prev[index] }))))


                        // setTableSize(prev => (response.data["CTR Results"]["Size"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                        // setTableWeek(prev => (response.data["CTR Results"]["Week"]?.map((data, index) => ({ ...data, ...prev[index] }))))
                        setFilterColumn(Object.keys(JSON.parse(response.data[selectedApi])[0]).map((csvColumnName, index) => (
                            { title: csvColumnName, dataIndex: csvColumnName, key: index, ...getColumnSearchProps(csvColumnName) }
                        )))
                        setFilterData(JSON.parse(response.data[selectedApi]))
                        // Papa.parse(response.data["CPC_M Filter"], {
                        //     header: true,
                        //     dynamicTyping: true,
                        //     complete: (result) => {
                        //         console.log("new data", result.data);
                        //         setFilterColumn(Object.keys(result.data[0]).map((csvColumnName) => (
                        //             { title: csvColumnName, dataIndex: csvColumnName }
                        //         )))
                        //         // let newColumns = columns.map((column) => {
                        //         //     return column.title == oldColumnName ? { ...column, dataIndex: newColumnName, title: newColumnName }
                        //         //         :
                        //         //         column
                        //         // })
                        //         // console.log("New Columns", newColumns);
                        //         // setColumns(newColumns)
                        //         // setCsvData(result.data)
                        //         // setTableColumn(Object.keys(result.data[0]).map((csvColumnName) => (
                        //         //     { title: csvColumnName, dataIndex: csvColumnName }
                        //         // )))
                        //     }
                        // }
                        // )
                        console.log("Helloooo", selectedApi);
                        setCurrentTable("Filtered")

                    })
                    .catch((error) => {
                        console.log(error);
                    });

            }
            catch (e) {

            }

            // let allTables = [{
            //     name: "Measurable",
            //     table: tableMeasurable
            // },
            // {
            //     name: "Campaign Objective",
            //     table: tableCampaign
            // },
            // {
            //     name: "Week",
            //     table: tableWeek
            // },
            // {
            //     name: "Brand",
            //     table: tableBrand
            // },
            // {
            //     name: "Inventory Type",
            //     table: tableInventory
            // },
            // {
            //     name: "Platform/Vendor",
            //     table: tablePlatform
            // },
            // {
            //     name: "Data Targeting",
            //     table: tableDataTargeting
            // },
            // {
            //     name: "Size",
            //     table: tableSize
            // },];

            // const desiredTable = allTables.find((table) => {
            //     return currentTable == table.name
            // })
            // let csv = Papa.unparse(desiredTable.table);
            // console.log("hehe boy old", csv);
            // let columnDataIndex = columns.find((dataIndex) => {
            //     return oldColumnName == dataIndex.title
            // })

            // console.log("Data Index", columnDataIndex);

            // let newCsv = csv.replace(`${columnDataIndex.dataIndex}`, newColumnName)
            // console.log("hehe boy", newCsv);
            // console.log("hehe boy", `/${oldColumnName}/g`);
            // console.log("hehe boy", newColumnName);
            // Papa.parse(newCsv, {
            //     header: true,
            //     dynamicTyping: true,
            //     complete: (result) => {
            //         console.log("new data", result.data);
            //         let newColumns = columns.map((column) => {
            //             return column.title == oldColumnName ? { ...column, dataIndex: newColumnName, title: newColumnName }
            //                 :
            //                 column
            //         })
            //         console.log("New Columns", newColumns);
            //         setColumns(newColumns)
            //         // setCsvData(result.data)
            //         // setTableColumn(Object.keys(result.data[0]).map((csvColumnName) => (
            //         //     { title: csvColumnName, dataIndex: csvColumnName }
            //         // )))

            //         switch (desiredTable.name) {
            //             case "Measurable":
            //                 setTableMeasurable(result.data)
            //                 break;
            //             case "Campaign Objective":
            //                 setTableCampaign(result.data)
            //                 break;

            //             case "Week":
            //                 setTableWeek(result.data)
            //                 break;
            //             case "Brand":
            //                 setTableBrand(result.data)
            //                 break;
            //             case "Inventory Type":
            //                 setTableInventory(result.data)
            //                 break;
            //             case "Platform/Vendor":
            //                 setTablePlatform(result.data)
            //                 break;
            //             case "Data Targeting":
            //                 setTableDataTargeting(result.data)
            //                 break;
            //             case "Size":
            //                 setTableSize(result.data)
            //                 break;

            //         }
            //     },
            // });

        }
    }

    useEffect(() => {
        console.log("C", filterColumn);
        console.log("D", filterdata);
    }, [filterColumn, filterdata])

    const [selectedApi, setSelectedApi] = useState("CPC_M Filter")

    const apiSelection = [
        "CPC_M Filter",
        "eCPM_M Filter",
        "CTR_M Filter",
        "Fraud_filter",
        "ORT_filter",
        "Viewability_",
        "Avg_Frequency",
        "OT_CTR filter",
        "OTF filter",
        "NFvCpm filter",
        " eCPMh filter",
        "OTvCPM filter",
        "qCPM filter",
        "VCR filter",
        "OT_vCPCV filter",
        "hcpcv filter",
        "qCPH filter",
        "ROAS filter",
        "CPL filter",
        "CPS filter",
        "qCPS filter",
        "Average_reach filter",
        "Percentage_on_target filter",
        "CPA_results filter",
        "CPCV filter",
        "vCPMresults filter",
        "OTI filter",
        "Sum filter"
    ]

    useEffect(() => {
        console.log("Bro", resultsCount);
        if (resultsCount == 35) {
            getTotal();
        }
    }, [resultsCount])

    return (
        <div className='audit-sheet-container'>
            <h1 className='audit-h1'><span>AuditSheet:</span></h1>
<div style={{display: "flex", justifyContent: "space-between"}}>
<div style={{width :"100%"}}>
            <form class="max-w-sm mb-3">
                <label for="countries" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select an option</label>
                <select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" value={currentTable} onChange={(e) => setCurrentTable(e.target.value)} >

                    {/* <option selected value="All">All</option> */}
                    <option selected value="Measurable">Measurable</option>
                    <option value="Campaign Objective">Campaign Objective</option>
                    <option value="Week">Week</option>
                    <option value="Brand">Brand</option>
                    <option value="Inventory Type">Inventory Type</option>
                    <option value="Platform/Vendor">Platform</option>
                    <option value="Data Targeting">Data Targeting</option>
                    <option value="Size">Size</option>
                    <option value="Filtered">Filtered Results</option>
                </select>
            </form>
            
            <button onClick={handleDownload} style={{ backgroundColor: "#374151", color: "white", borderRadius: "5px", padding: "4px 10px", marginBottom: "20px" }}>Download</button>
            <Link to="/dashboard/datavisualization/datavisualization1" state={{ csvData: location?.state?.csvData, totalCalculation: totalCalculation }}>
                    <button style={{ backgroundColor: "#374151", color: "white", borderRadius: "5px", padding: "4px 10px", marginBottom: "20px", display: "block" }}>Visualization</button>
                </Link>
            </div>
           <div style={{ display: "flex", flexDirection: "column", gap: "5px", width: "50%" }}>
                {/* <input type='text' placeholder='Enter Base Column' value={oldColumnName} onChange={(e) => { setOldColumnName(e.target.value) }}></input>
                <input type='text' placeholder='Enter Primary Column' value={newColumnName} onChange={(e) => { setNewColumnName(e.target.value) }}></input> */}
                <select className='p-1.5' onChange={(e) => { setOldColumnName(e.target.value) }}>
                    <option value=""> Select Base Comlumn </option>
                    {dropDown && dropDown.sort().map((apiSelect) => (
                        <option value={apiSelect}> {apiSelect} </option>
                    ))}
                </select>
                <select className='p-1.5' onChange={(e) => { setNewColumnName(e.target.value) }}>
                    <option value=""> Select Primary Comlumn </option>
                    {dropDown && dropDown.sort().map((apiSelect) => (
                        <option value={apiSelect}> {apiSelect} </option>
                    ))}
                </select>
                <select className='p-1.5' onChange={(e) => { setSelectedApi(e.target.value) }}>
                    {apiSelection && apiSelection.map((apiSelect) => (
                        <option value={apiSelect}> {apiSelect} </option>
                    ))}
                </select>
                <button style={{ backgroundColor: "#374151", color: "white", borderRadius: "5px", padding: "4px 10px", marginBottom: "20px" }} onClick={handleFilter}>Filter</button>
            </div>
            </div>
            {
                // currentTable == "All" ?
                //     <>
                //         <div className='border'>

                //             <Table dataSource={tableMeasurable} columns={[{
                //                 title: 'Measurable',
                //                 dataIndex: 'Measurable',
                //                 key: 'Measurable',
                //                 ...getColumnSearchProps('Measurable'),
                //             }, , ...columns]} />
                //             {/* <Table columns={columns} dataSource={tableMeasurable} /> */}


                //         </div>
                //         <div className='border'>
                //             <Table dataSource={tableCampaign} columns={[{
                //                 title: 'Campaign Objective',
                //                 dataIndex: 'Campaign Objective',
                //                 key: 'Campaign Objective',
                //                 ...getColumnSearchProps('Campaign Objective'),

                //             }, ...columns]} />
                //         </div>
                //         <div className='border'>
                //             <Table dataSource={tableWeek} columns={[{
                //                 title: 'Week',
                //                 dataIndex: 'Week',
                //                 key: 'Week',
                //                 ...getColumnSearchProps('Week'),

                //             }, ...columns]} />
                //         </div>
                //         <div className='border'>
                //             <Table dataSource={tableBrand} columns={[{
                //                 title: 'Brand',
                //                 dataIndex: 'Brand',
                //                 key: 'Brand',
                //                 ...getColumnSearchProps('Brand'),

                //             }, ...columns]} />
                //         </div>
                //         <div className='border'>
                //             <Table dataSource={tableInventory} columns={[{
                //                 title: 'Inventory Type',
                //                 dataIndex: 'Inventory Type',
                //                 key: 'Inventory Type',
                //                 ...getColumnSearchProps('Inventory Type'),

                //             }, ...columns]} />
                //         </div>
                //         <div className='border'>
                //             <Table dataSource={tablePlatform} columns={[{
                //                 title: 'Platform/Vendo',
                //                 dataIndex: 'Platform/Vendo',
                //                 key: 'Platform/Vendo',
                //                 ...getColumnSearchProps('Platform/Vendo'),

                //             }, ...columns]} />
                //         </div>
                //         <div className='border'>
                //             <Table dataSource={tableDataTargeting} columns={[{
                //                 title: 'Data Targeting',
                //                 dataIndex: 'Data Targeting',
                //                 key: 'Data Targeting',
                //                 ...getColumnSearchProps('Data Targeting'),

                //             }, ...columns]} />
                //         </div>
                //         <div className='border'>
                //             <Table dataSource={tableSize} columns={[{
                //                 title: 'Size',
                //                 dataIndex: 'Size',
                //                 key: 'Size',
                //                 ...getColumnSearchProps('Size'),

                //             }, ...columns]} />
                //         </div>
                //     </>
                //     :
                loading ?
                    <p>Loading...</p>
                    :
                    currentTable == "Measurable" ?
                        <div className='border'>

                            <Table dataSource={tableMeasurable} columns={[{
                                title: 'Measurable',
                                dataIndex: 'Measurable',
                                key: 'Measurable',
                                ...getColumnSearchProps('Measurable'),
                            }, , ...columns]} />
                            {/* <Table columns={columns} dataSource={tableMeasurable} /> */}


                        </div>

                        :


                        currentTable == "Campaign Objective" ?
                            <div className='border'>
                                <Table dataSource={tableCampaign} columns={[{
                                    title: 'Campaign Objective',
                                    dataIndex: 'Campaign Objective',
                                    key: 'Campaign Objective',
                                    ...getColumnSearchProps('Campaign Objective'),

                                }, ...columns]} />
                            </div>

                            :

                            currentTable == "Week" ?
                                <div className='border'>
                                    <Table dataSource={tableWeek} columns={[{
                                        title: 'Week',
                                        dataIndex: 'Week',
                                        key: 'Week',
                                        ...getColumnSearchProps('Week'),

                                    }, ...columns]} />
                                </div>
                                :

                                currentTable == "Brand" ?
                                    <div className='border'>
                                        <Table dataSource={tableBrand} columns={[{
                                            title: 'Brand',
                                            dataIndex: 'Brand',
                                            key: 'Brand',
                                            ...getColumnSearchProps('Brand'),

                                        }, ...columns]} />
                                    </div>

                                    :

                                    currentTable == "Inventory Type" ?
                                        <div className='border'>
                                            <Table dataSource={tableInventory} columns={[{
                                                title: 'Inventory Type',
                                                dataIndex: 'Inventory Type',
                                                key: 'Inventory Type',
                                                ...getColumnSearchProps('Inventory Type'),

                                            }, ...columns]} />
                                        </div>

                                        :

                                        currentTable == "Platform/Vendor" ?

                                            <div className='border'>
                                                <Table dataSource={tablePlatform} columns={[{
                                                    title: 'Platform',
                                                    dataIndex: 'Platform',
                                                    key: 'Platform',
                                                    ...getColumnSearchProps('Platform'),

                                                }, ...columns]} />
                                            </div>
                                            :

                                            currentTable == "Data Targeting" ?

                                                <div className='border'>
                                                    <Table dataSource={tableDataTargeting} columns={[{
                                                        title: 'Data Targeting',
                                                        dataIndex: 'Data Targeting',
                                                        key: 'Data Targeting',
                                                        ...getColumnSearchProps('Data Targeting'),

                                                    }, ...columns]} />
                                                </div>
                                                :

                                                currentTable == "Size" ?

                                                    <div className='border'>
                                                        <Table dataSource={tableSize} columns={[{
                                                            title: 'Size',
                                                            dataIndex: 'Size/Length',
                                                            key: 'Size',
                                                            ...getColumnSearchProps('Size'),

                                                        }, ...columns]} />
                                                    </div>
                                                    :

                                                    currentTable == "Filtered" ?

                                                        <div className='border'>
                                                            <Table dataSource={filterdata} columns={filterColumn} />
                                                        </div>
                                                        :
                                                        ""
            }




        </div >
    )
}

export default AuditSheet