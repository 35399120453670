import React from "react";
import "./corefeature.scss";
import Diverse from "../../assets/images/diverse.png";
import Dataroot from "../../assets/images/dataroot.png";
import Powerintelligent from "../../assets/images/power-intelligent.png";
import Mediacalculation from "../../assets/images/mediassss.png";
import Leftfeature from "../../assets/images/Left Features.png";
import Rightfeature from "../../assets/images/Right Features.png";

const Corefeature = () => {
  return (
    <>
      <div className="corefeature">
        <div className="corefeature-content">
          <div className="title">
            <h6>Our Core Features</h6>
          </div>
          <div className="featureContent-a">
            <div className="featureleft-content">
              <div className="leftcontent">
                <img src={Diverse} alt="..." />
              </div>
            </div>
            <div className="featureright-content">
              <div className="rightcontent">
                <div className="title-right">
                  <h6>
                    Effortlessly Handle Diverse Marketing Data with Adaptable
                    Pipelines
                  </h6>
                </div>
                <div className="subtitle-right">
                  <p>
                    The Root’s adaptable pipelines make it easy to handle
                    diverse marketing data, from campaign metrics to customer
                    interactions. With our intuitive interface, you can
                    seamlessly transiting from raw data to meaningful insights.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="featureContent-a">
            <div className="featureright-content">
              <div className="rightcontent">
                <div className="title-right">
                  <h6>Unlock the Power of Data with The Root</h6>
                </div>
                <div className="subtitle-right">
                  <p>
                    With our intuitive interface, seamlessly transition from war
                    data to valuable insights that drive success.
                  </p>
                </div>
              </div>
            </div>
            <div className="featureleft-content">
              <div className="leftcontent">
                <img src={Dataroot} alt="..." />
              </div>
            </div>
          </div>
          <div className="featureContent-a">
            <div className="featureleft-content">
              <div className="leftcontent">
                <img src={Powerintelligent} alt="..." />
              </div>
            </div>
            <div className="featureright-content">
              <div className="rightcontent">
                <div className="title-right">
                  <h6>
                    Unlock the Power of Intelligent Learning with The Root
                  </h6>
                </div>
                <div className="subtitle-right">
                  <p>
                    At the Root, we understand that data analysis is an ongoing
                    process. That’s why our intelligent learning feature
                    actively refines your analysis over time, ensuring you
                    always have the most accurate insights.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="featureContent-a">
            <div className="featureright-content">
              <div className="rightcontent">
                <div className="title-right">
                  <h6>The Root’s Media Calculations and ML Engine</h6>
                </div>
                <div className="subtitle-right">
                  <p>
                    The Root’s Media Calculations and ML Engie effortlessly
                    incorporates Excel calculations and offers a customizable ML
                    engine, empowering you to make data-driven marketing
                    decisions with ease.
                  </p>
                </div>
              </div>
            </div>
            <div className="featureleft-content">
              <div className="leftcontent">
                <img src={Mediacalculation} alt="..." />
              </div>
            </div>
          </div>
        </div>
        <img
          //   src="/src/assets/images/left hero section.png"
          src={Leftfeature}
          alt="herobtmimg"
          className="herotopimg"
        />
        <img
          src={Rightfeature}
          //   src="/src/assets/images/Rignt hero section.png"
          alt="herobtmimg"
          className="herobottomimg"
        />
      </div>
    </>
  );
};

export default Corefeature;
