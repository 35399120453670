import React, { useState, useEffect, useContext } from 'react';
import upload_icon from "../../../../assets/images/upload_icon.png";
import { Link, Outlet } from 'react-router-dom';
import JSZip from 'jszip';
import axios from 'axios';
import Papa from 'papaparse';
import { SavedFileContext } from '../../../../context/SaveedFileContext';

const ZipFileUpload = ({ uploadTxt }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [checkFileName, setCheckFileName] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [uploadBtn, setUploadBtn] = useState(false);
    const [zipFileNames, setZipFileNames] = useState([]);
    const [csvData, setCsvData] = useState("")
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState("")
    const { currentMapping, setCurrentMapping } = useContext(SavedFileContext)


    // const handleFileUpload = async (event) => {
    //     const fileInput = event.target;
    //     const allowedExtensions = /(\.zip)$/i;
    //     const files = fileInput.files;

    //     if (files.length === 0) {
    //         return; // No file selected
    //     }

    //     for (let i = 0; i < files.length; i++) {
    //         const fileName = files[i].name;

    //         if (!allowedExtensions.exec(fileName)) {
    //             setErrorMessage('Please upload only ZIP files.');
    //             fileInput.value = '';
    //             setTimeout(() => {
    //                 setErrorMessage('');
    //             }, 3000);
    //             return;
    //         }

    //         const zip = new JSZip();
    //         const zipFile = await zip.loadAsync(files[i]);

    //         const fileNames = Object.keys(zipFile.files);
    //         setZipFileNames(fileNames);
    //     }
    //     setUploadBtn(true);
    // };

    // function base64ToBlob(base64, contentType = '', sliceSize = 512) {
    //     const byteCharacters = atob(base64);
    //     const byteArrays = [];

    //     for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    //         const slice = byteCharacters.slice(offset, offset + sliceSize);

    //         const byteNumbers = new Array(slice.length);
    //         for (let i = 0; i < slice.length; i++) {
    //             byteNumbers[i] = slice.charCodeAt(i);
    //         }

    //         const byteArray = new Uint8Array(byteNumbers);
    //         byteArrays.push(byteArray);
    //     }

    //     return new Blob(byteArrays, { type: contentType });
    // }

    function base64ToBlob(base64, contentType = '') {
        const base64WithoutPrefix = base64.split(',')[1]; // Remove any base64 prefix if present
        return fetch(`data:${contentType};base64,${base64WithoutPrefix}`).then(res => res.blob());
    }


    const handleFileUpload = async (event) => {
        setLoading(true);
        const fileInput = event.target;
        const allowedExtensions = /(\.zip)$/i;
        const files = fileInput.files; // Use files property to get the selected files
        console.log("hehe", files[0]);
        setFiles(event.target.files[0])
        if (files.length === 0) {
            setUploadBtn(false)
        setLoading(false);

            return; // No file selected
        }

        // Validate each file
        // for (let i = 0; i < files.length; i++) {
        //     const fileName = files[i].name;

        //     if (!allowedExtensions.exec(fileName)) {
        //         setErrorMessage('Please upload only CSV files.');
        //         fileInput.value = '';
        //         // Clear the error message after 3 seconds
        //         setTimeout(() => {
        //             setErrorMessage('');
        //         }, 3000);
        //         return;
        //     }

        // }


        // try {
        //     const formData = new FormData();
        //     formData.append("file", event.target.files[0]);

        //     const config = {
        //         headers: {
        //             'Content-Type': 'multipart/form-data'
        //         },
        //     };
        //     const response = await axios.post("https://test2.techrecipes.com/upload", formData, config)
        //     console.log("So the response is", response);
        //     // Papa.parse(response.data, {
        //     //     header: true,
        //     //     dynamicTyping: true,
        //     //     complete: (result) => {
        //     //         console.log(result.data);
        //     //         setCsvData(result.data)
        //     //     },
        //     // });
        //     console.log("tests", typeof (response.data));
        //     console.log("Encoded Zip", encodeURIComponent(response.data));
        //     const blob = new Blob([response.data], {
        //         type: 'application/zip',
        //     })
        //     console.log("hh", blob);

        //     // const url = window.URL.createObjectURL(blob);
        //     // const zipDownload = document.createElement("a");

        //     // zipDownload.href = url;
        //     // zipDownload.download = "screenshot-download.zip";
        //     // document.body.appendChild(zipDownload);
        //     // zipDownload.click();

        //     // const zipData = decodeBase64(response.data);
        //     const unicodeString = response.data.replace(/\\u([\d\w]{4})/gi, (match, grp) => String.fromCodePoint(parseInt(grp, 16)));

        //     // Convert Unicode string to base64
        //     // const base64String = btoa(unescape(encodeURIComponent(unicodeString)));
        //     // const zipBlob = base64ToBlob(response.data, 'application/zip');
        //     // const zip = new JSZip();
        //     // zip.loadAsync(zipBlob)
        //     //     .then((contents) => {
        //     //         // Handle the unzipped contents
        //     //         contents.forEach((relativePath, file) => {
        //     //             console.log("File:", relativePath, file);
        //     //             // You can now use file.async('string') or file.async('blob') to work with the content
        //     //         });
        //     //     })
        //     //     .catch((error) => {
        //     //         console.error("Error unzipping:", error);
        //     //     });
        //     // const zip = new JSZip();
        //     // const zipFile = await zip.loadAsync(encodeURIComponent(base64String), { base64: true });

        //     // const fileNames = Object.keys(zipFile.files);
        //     // // setZipFileNames(fileNames);
        //     // // setUnzipedFiles(zipFile)

        //     // console.log("zip data", zipFile);
        //     const zipString = `data:application/zip;base64,${response.data}`;
        //     base64ToBlob(zipString, 'application/zip').then(zipBlob => {
        //         // Using JSZip to read and unzip
        //         const zip = new JSZip();
        //         zip.loadAsync(zipBlob)
        //             .then((contents) => {
        //                 // Handle the unzipped contents
        //                 Object.keys(contents.files).forEach((filename) => {
        //                     console.log("File:", filename);
        //                     const file = contents.files[filename];
        //                     // You can now use file.async('string') or file.async('blob') to work with the content
        //                 });
        //             })
        //             .catch((error) => {
        //                 console.error("Error unzipping:", error);
        //             });
        //     }).catch(error => {
        //         console.error("Error converting base64 to Blob:", error);
        //     });

        //     setCsvData(response.data)


        // }
        // catch (e) {
        //     console.log("Error Occured", e);
        // }


        try {
            const formData = new FormData();
            formData.append('file', files[0]);

            const response = await fetch('https://test2.techrecipes.com/upload', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {

                const modifiedZipFile = await response.blob();
                console.log("Modified Blob", modifiedZipFile);
                // Extract and display file names
                const zip = new JSZip();
                const zipContents = await zip.loadAsync(modifiedZipFile);

                // console.log("From Server", zipContents.file("hello.csv"));

                // const csvFile = zipContents.file('hello.csv');
                // const csvData = await csvFile.async('uint8array');
                // const decoder = new TextDecoder('utf-8');
                // const decoded = decoder.decode(csvData);
                // console.log("csvData", csvData);
                // console.log("csvDataDecoded", decoded);

                // Papa.parse(zipContents.files["hello.csv"], {
                //     header: true,
                //     dynamicTyping: true,
                //     complete: (result) => {
                //         console.log("just to see", result.data);
                //         // setCsvData(result.data)
                //     },
                // });
                let arrayCsv = [];
                Object.keys(zipContents.files).forEach(async (filename) => {
                    console.log('File Name:', filename);
                    const csvFile = zipContents.file(filename);
                    const csvData = await csvFile.async('uint8array');
                    const decoder = new TextDecoder('utf-8');
                    const decoded = decoder.decode(csvData);
                    arrayCsv.push({ fileName: filename, csv_content: decoded })





                });
                console.log(arrayCsv);
        setLoading(false);

                setCsvData(arrayCsv)
            } else {
                // Handle error response
        setLoading(false);

            }
        } catch (error) {
        setLoading(false);

            console.error('Error:', error);
        }
if (currentMapping != "" && event.target.files[0]){

    setUploadBtn(true)
}
else{
    setUploadBtn(false)
}
        // setCheckFileName(true);
    };

    // const decodeBase64 = (base64String) => {
    //     // Handle characters outside of Latin1 range
    //     return decodeURIComponent(
    //         atob(base64String)
    //             .split('')
    //             .map((char) => `%${`00${char.charCodeAt(0).toString(16)}`.slice(-2)}`)
    //             .join('')
    //     );
    // };

    const handleOptionSelect = (event) => {
        const selectedOption = event.target.value;
        setSelectedOption(selectedOption);
    };

    return (
        <div>
            <div className='innerTabs-box'>
                <Link to='/dashboard/uploadFile/singlefileupload'>
                    <button>Single File Upload</button>
                </Link>
                <Link to='/dashboard/uploadFile/multiplefileupload'>
                    <button>Multiple File Upload</button>
                </Link>
                <Link to='/dashboard/uploadFile/zipfileupload'>
                    <button>Zip File</button>
                </Link>
            </div>
            {!checkFileName ? (
                <div className='subtab1-box'>
                    <div className="w-full md:w-1/2 left flex flex-col gap-6 items-center">
                        <label
                            htmlFor="ch"
                            className="Campton-light cursor-pointer  px-6 md:px-8 py-1 md:py-2 rounded-lg text-white"
                        >
                            <div
                                className="uplaod cursor-pointer w-11/12 py-6 md:py-12 md:py-24 text-center flex justify-center flex-col gap-2 items-center border"
                            >
                                <img src={upload_icon} alt="" />
                                <p className="Campton text-xl md:text-2xl text-black ">
                                    {uploadTxt}
                                </p>
                            </div>
                            {/* Use type="file" and multiple attribute */}
                            <input type="file" accept=".zip, .rar" id="ch" onChange={handleFileUpload} className='text-black' multiple />
                            {errorMessage && <div className="toast pb-2 text-black">{errorMessage}</div>}
                        </label>
                    </div>
                    {/* <div className="inline-block relative w-full mt-3 ">
                        <select
                            className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                            onChange={handleOptionSelect}
                        >
                            <option>Select direction</option>
                            <option value="Directi0n 1: Stacks DataFrames vertically and resets the indexes">Direction 1</option>
                            <option value="2">Direction 2</option>
                            <option value="3">Direction 3</option>
                            <option value="4">Direction 4</option>
                            <option value="5">Direction 5</option>
                            <option value="6">Direction 6</option>
                            <option value="7">Direction 7</option>
                            <option value="8">Direction 8</option>
                        </select>
                    </div> */}
                    {selectedOption && (
                        <p className="Campton text-lg text-black font-semibold mt-2">
                            Selected Option: {selectedOption}
                        </p>
                    )}
                    <select style={{ width: "100%", padding: "5px 10px", borderRadius: "4px" }}
                        onChange={(e) =>{ 
                            
                            setCurrentMapping(e.target.value)
                            if (e.target.value != "" && files){
setUploadBtn(true);
                            }
                            else{
                                setUploadBtn(false)
                            }
                        
                        
                        }
                        } value={currentMapping}
                    >
                        <option value={""}>Select Mapping</option>
                        <option value={"meta"}>Meta</option>
                                <option value={"tiktok"}>Tiktok</option>
                                <option value={"ttd"}>TTD</option>
                                {/* <option value={"other"}>Other</option> */}
                                <option value={"fixed"}>Structured Data</option>
                    </select>
                    {loading ? 
                    <p>Uplaoding and procesing files...</p>
                
                :
                
                ""}
                    {uploadBtn && (
                        <div>
                            {/* <p className="Campton text-lg text-black font-semibold mt-2">
                                Files in the ZIP:
                            </p>
                            <ul>
                                {zipFileNames.map((fileName, index) => (
                                    <li key={index} className="Campton text-lg text-black">
                                        {fileName}
                                    </li>
                                ))}
                            </ul> */}
                            <Link to="/dashboard/uploadFile/zipfileselected" state={{ csvData: csvData, direction: selectedOption }}>
                                <button
                                    className='save mt-5 text-white py-[5px] px-5 rounded text-lg'
                                >
                                    Upload
                                </button>
                            </Link>
                        </div>
                    )}
                </div>
            ) : (
                <></>
            )}
            <Outlet />
        </div>
    );
};

export default ZipFileUpload;

